import React from "react";
import { Title } from "./outlet.styles";
import styled from "styled-components";
import {
  microHeading,
  defaultFont,
  regularHeading,
  font,
} from "../../../theme/typography";
import {
  blue300,
  black600,
  curiousBlue300,
  green300,
  white,
} from "../../../theme/colors";
import { xsBold, bold, semiBold } from "../../../theme/typeface";
import DateToDisplay from "../../../helpers/utils/DateToDisplay";
import { FormRow } from "../components/FormRow";
import { Button } from "../../../components/Button";
import SmsIcon from "@material-ui/icons/Sms";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const Wrapper = styled.div`
  width: 50%;
`;

const TitleWrapper = styled(Title)`
    ${microHeading.regular}
    color:${blue300};
    height:auto;
    margin: 48px 0 24px;
    font-weight:${xsBold};
`;


const AnswerText = styled.div`
  padding-top: 12px;
`;

export const ConfirmationCalling = ({ outletDetails = {}, triggerNotification}) => {

  const { client_payout_month, amount_to_pay, utr_number, payment_date, bank } =
    outletDetails.related_workflow_object || {};
  const { project, program, phone_number1, phone_number2 } = outletDetails;

  return (
    <Wrapper>
      <TitleWrapper>
      Details to Inform
      </TitleWrapper>
      <FormRow
        name={"Project Name"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{(project || {}).name}</AnswerText>}
      />
      <FormRow
        name={"Program Name"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{program?.name}</AnswerText>}
      />
      <FormRow
        name={"Client Payout Month"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{client_payout_month}</AnswerText>}
      />
      <FormRow
        name={"Amount"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{amount_to_pay}</AnswerText>}
      />
      <FormRow
        name={"UTR Number"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{utr_number}</AnswerText>}
      />
      <FormRow
        name={"Payment Date"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{payment_date}</AnswerText>}
      />
      <FormRow
        name={"Bank Name"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{bank}</AnswerText>}
      />
    </Wrapper>
  );
};
