/**
 * Color Palette
 */

/**
 * Primary color one - Havelock Blue(5 shades)
 */

export const blue500 = "#1E2E4E";
export const blue400 = "#2E487B";
export const blue300 = "#4770C1"; //main
export const blue200 = "#8EA9F0";
export const blue100 = "#D1DCF9";

/**
 * Primary color two - Swamp(Black - 7 shades)
 */

export const black700 = "#212323"; //main
export const black600 = "#3E3E3E";
export const black500 = "#585A5A";
export const black400 = "#909191";
export const black300 = "#BCBDBD";
export const black200 = "#DEDEDE";
export const black100 = "#EAEAEA";

/**
 * Secondary color one - Green(5 Shades)
 */

export const green500 = "#183E2C";
export const green400 = "#2E7B56";
export const green300 = "#2CAE66"; //main
export const green200 = "#89D7B1";
export const green100 = "#D9F1E5";

/**
 * Secondary color two - Curious Blue(5 Shades)
 */

export const curiousBlue500 = "#153242";
export const curiousBlue400 = "#286080";
export const curiousBlue300 = "#3E96C8"; //main
export const curiousBlue200 = "#84BCDC";
export const curiousBlue100 = "#D7E9F4";

/**
 * Secondary color three - Sandy Brown(5 Shades)
 */

export const brown500 = "#4A3513";
export const brown400 = "#916625";
export const brown300 = "#F2AA4C"; //main
export const brown200 = "#EDC181";
export const brown100 = "#F9EAD5";

/**
 * Secondary color four - Royal Blue(5 Shades)
 */

export const royalBlue500 = "#003135";
export const royalBlue400 = "#00848E";
export const royalBlue300 = "#47C1BF"; //main
export const royalBlue200 = "#B7ECEC";
export const royalBlue100 = "#E0F5F5";

/**
 * Secondary color five - Cinnabar(5 Shades)
 */

export const cinnabar500 = "#4A1313";
export const cinnabar400 = "#912525";
export const cinnabar300 = "#E23A3A"; //main
export const cinnabar200 = "#F19A9A";
export const cinnabar100 = "#FADDDD";

/**
 * status color
 */

export const processing = "#E29F3A";
export const completed = "#3E96C8";
export const accepted = "#2CAE66";
export const cancelled = "#E23A3A";

/**
 * Other Color Codes
 */

export const white = "#FFFFFF";
export const selago = "#F8F9FE";

export const ekycBackground = "#F8F8FF";

export const grey100 = "#F2F2F2";

export const borderLight = "#E6E8F1";
