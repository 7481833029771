import React, { Component } from "react";
import Slider from "react-slick";

import PropTypes from "prop-types";
import { white } from "../../theme/colors";
import { Modal } from "../Modal";
import { GalleryWrapper } from "./ImageGallery.styles";
import SingleImage from './SingleImage'

const firstSliderRef = React.createRef();
const secondSliderRef = React.createRef();

export default class ImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 1,
      navCurrentIndex: 0,
      rotate: 0,
      imageDimension: 430,
      imageZoomState: true,
    };
  }

  componentDidUpdate() {
    if (typeof this.props.onImageChange === "function") {
      this.props.onImageChange(this.state.currentIndex);
    }
  }
  rotateReset = () => {
    this.setState({rotate: 0})
  }
  rotateLeft = () => {
    this.setState((prevState) => {
      return {rotate: prevState.rotate - 90}
    })
  }

  
  rotateRight = () => {
    this.setState((prevState) => {
      return {rotate: prevState.rotate + 90}
    })
  }
   
 
   download = async (imageUrl) => {
    try {
      let originalImage = imageUrl;
  
      if (typeof imageUrl === 'object') {
        originalImage = imageUrl.image;
      } else {
        originalImage = originalImage.substring(0, originalImage.indexOf('?'));
      }
  
      // const response = await fetch(originalImage);
  
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
  
      // const imageBlob = await response.blob();
      // const nameSplit = originalImage.split("/");
      // const duplicateName = nameSplit.pop();
      // const imageURL = URL.createObjectURL(imageBlob);
  
      // const link = document.createElement('a');
      // link.href = imageURL;
      // link.download = duplicateName;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      fetch(originalImage)
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                throw new Error('Something went wrong');
            })
            .then((blob) => {
             
               ;
      const nameSplit = originalImage.split("/");
      const duplicateName = nameSplit.pop();
      const imageURL = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = duplicateName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

               
            })
            .catch((err) => {
                console.log(err)
            });
    } catch (error) {
      console.error('Error:', error);
    }
  };
  onClickImage = () => {
    const { imageDimension, imageZoomState } = this.state
    if(imageDimension > 600){
      this.setState({
        imageZoomState: false
      })
    }
    if(imageDimension < 420){
      this.setState({
        imageZoomState: true
      })
    }

    this.setState((prevState) => ({
      imageDimension: imageZoomState ? prevState.imageDimension + 30 : prevState.imageDimension - 30
    }))

  }
  /**
   * Render menthod called here
   * @rootClassName - class name of the root element
   * @onImageChange - callback method for each image change in slider
   * @prefixContent - prefix value of the content
   * @suffixContent - suffix value of the content
   * @enableContent - whether option to enable the content
   * @contentClassName - class name of the content element
   * @singleSliderClassName - class name of the single slider
   * @navSliderClassName - class name of the nav slider
   * @singleSiderSettings -  single slider settings/ option
   * @navSliderSettings - nav slider settings / option
   * @images - array of images
   */
  render() {
    const {
      prefixContent,
      suffixContent,
      enableContent,
      contentClassName,
      singleSliderClassName,
      navSliderClassName,
      singleSiderSettings,
      navSliderSettings,
      images = [],
      showGallery,
      customClose,
      setShowGallery = () => { },
      noExit,
      setState,
    } = this.props;
    const { navCurrentIndex, currentIndex } = this.state

    const getValue = (obj) => {
      if (typeof(obj) === 'string') {
        return {
          image: obj
        }
      }
      return obj;
    }
    const renderSingleSliderImages = () => {
      return images.map((image, index) => {
        const content = getValue(image)?.image_content;
        return (
          // <div key={index}>
          //   {content ? <p>{content}</p> : null}
          //   <figure style={{ backgroundImage: `url(${getValue(image).image})`, transform:`rotate(${this.state.rotate}deg)`}} />
          //   <RotateLeftIcon />
          //   <RotateRightIcon />
          // </div>
            <SingleImage
                key={index}
                index={index}
                content={content}
                getValue={getValue}
                image={image}
                rotate={this.state.rotate}
                rotateLeft={this.rotateLeft}
                rotateRight={this.rotateRight}
                download={()=>this.download(image)}
                onClickImage={this.onClickImage}
                imageDimension={this.state.imageDimension}
                imageZoomState={this.state.imageZoomState}
            />
        );
      });
    };
    const renderNavSliderImages = () => {
      return images.map((image, index) => {
        const content = getValue(image)?.image_content;
        return (
          <div key={index} className={`${navCurrentIndex === index ? 'nav-active' : ''} item`} onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            firstSliderRef.current.slickGoTo(index);
            this.setState({ navCurrentIndex: index, currentIndex: index + 1 })
          }}>
            {content ? <p>{content}</p> : null}
            <img src={getValue(image)?.image} alt="" />
          </div>
        );
      });
    };

    singleSiderSettings["afterChange"] = (current) => {
      this.setState({ rotate: 0, navCurrentIndex: current, currentIndex: current + 1});
      secondSliderRef.current.slickGoTo(current);
    };
    return (
      <Modal
        withHeader
        background="transparent"
        modalWidth="65%"
        noContainerShadow={true}
        top={"10%"}
        backDropOpacity="1"
        closeIconColor={white}
        closeAction={() => {
          
            setShowGallery(false)
          
          }}
        noExit = {noExit}

      >
        <GalleryWrapper>
          <Slider
            className={singleSliderClassName}
            {...singleSiderSettings}
            ref={firstSliderRef}
          >
            {renderSingleSliderImages()}
          </Slider>
          {enableContent ? (
            <p className={contentClassName}>
              {prefixContent}
              {`${this.state.currentIndex}/${images.length}`}
              {suffixContent}
            </p>
          ) : null}
          <Slider
            ref={secondSliderRef}
            className={navSliderClassName}
            {...navSliderSettings}
          >
            {renderNavSliderImages()}
          </Slider>
        </GalleryWrapper>
      </Modal>
    );
  }
}

/**
 * ImageGallery proptype has been declared
 */
ImageGallery.propTypes = {
  onImageChange: PropTypes.func,
  singleSliderClassName: PropTypes.string,
  navSliderClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  prefixContent: PropTypes.string,
  suffixContent: PropTypes.string,
  enableContent: PropTypes.bool,
  singleSiderSettings: PropTypes.object,
  navSliderSettings: PropTypes.object,
  images: PropTypes.arrayOf(PropTypes.string),
};

/**
 * Default Value of the ImageGallery comoponent assigned
 */
ImageGallery.defaultProps = {
  enableContent: true,
  singleSliderClassName: "slider slider-single",
  navSliderClassName: "slider slider-nav",
  singleSiderSettings: {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    dots: false,
    infinite: false,
  },
  navSliderSettings: {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
  },
  images: [],
};
