// CustomArrow.js
import React from 'react';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export const CustomNextArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '-11%',
      right: '-9px',
      zIndex: 1,
      transform: 'translateY(10px)' 
    }}
  >
    <ArrowForwardIosIcon />
  </IconButton>
);

export const CustomPrevArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '-11%',
      left: '-36px',
      zIndex: 1,
      transform: 'translateY(10px)' 
    }}
  >
    <ArrowBackIosIcon />
  </IconButton>
);
