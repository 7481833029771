import React from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import LeftSideContainer from "../../login/components/LeftSideContainer";
import { RightContainerStyle } from "../../login/components/RightSideContainer";
import ROUTES, { EKYC } from "../../../helpers/constants/routes";
import LoginLogo from "../../../components/LoginLogo";
import Footer from "../../login/components/Footer";
import LoginPage from "./EkycLogin";
import VerifyOTPPage from "./VerifyOtp";
import themeSizes from "../../../theme/themeSizes";
import RetailLogoPage from "../../../components/RetailLogoPage";

export const LoginStyle = styled.div`
  display: flex;
  section {
    width: 100%;
  }
  & > section:first-child {
    background: linear-gradient(0deg, #4770c1, #4770c1);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  height: 100vh;

  @media ${themeSizes.mobile} {
    flex-flow: column;
    &>section:first-child{
        background: ${(props) => props.loginRoute ? (props.setBackGround ? "none" : "white") : "linear-gradient(0deg, #4770C1, #4770C1)"};
}
`;

export const Login = () => {
  const isMobile = window.innerWidth <= 768;


  return (
    <LoginStyle>
        <Switch>
          <Route path={EKYC.LOGIN} exact
          render={() => (
            <>
            {isMobile ? (
                // For mobile view
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '9rem' }}>
                    <RetailLogoPage/>
                    <LoginPage />
                 </div>
            ) : (
                // For non-mobile view
                <>
                    <LeftSideContainer  check="EKYC"/>
                    <RightContainerStyle>
                        <LoginLogo />
                        <LoginPage />
                        <Footer />
                    </RightContainerStyle>
                </>
            )}
        </>
        )}/>
        
          <Route path={EKYC.OTP} exact 
          render={() => (
            <>
            {isMobile ? (
                // For mobile view
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '9rem' }}>
                    <RetailLogoPage/>
                    <VerifyOTPPage />
                 </div>
            ) : (
                // For non-mobile view
                <>
                    <LeftSideContainer  check="EKYC"/>
                    <RightContainerStyle>
                        <LoginLogo />
                        <VerifyOTPPage />
                        <Footer />
                    </RightContainerStyle>
                </>
            )}
        </>
        )} />
        </Switch>
    </LoginStyle>
  );
};

export default Login;
