/**
 * Gets the value at path of object. If the resolved value is undefined, the defaultValue is returned in its place.
 * @param {object} object The object to query.
 * @param {Array|string} path The path of the property to get.
 * @param {*} defaultValue Returns the resolved value.
 */
function callback(object, path, defaultValue) {
    let result;
    for (let element of path) {
        if (object && Object.prototype.hasOwnProperty.call(object, element)) {
            result = object[element];
            object = object[element];
        } else {
            result = defaultValue;
            break;
        }
    }
    return result;
}

export default function get(object, path, defaultValue) {
    //defaultValue-The value returned for undefined resolved values.
    if (object) {
        if (object.constructor === Object) {
            if (path) {
                if (typeof path === 'string') {
                    return callback(
                        object,
                        // eslint-disable-next-line no-useless-escape
                        path.split(/[. | \[ | \]]/).filter(Boolean),
                        defaultValue
                    );
                } else if (Array.isArray(path)) {
                    return callback(object, path, defaultValue);
                }
            }
        }
    }
    return defaultValue;
}  