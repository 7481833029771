const status_data = {
    "pending" : "PENDING",
    "accepted" : "ACCEPTED",
    "rejected" : "REJECTED",
};

const type_data = {
    "store_details": "Store Details",
    "bank_details": "Bank Details",
    "tax_details": "Tax Details",
};

/* Store Data */
const store_fields_data = {
    "name": "Store Name",
    "dob": "Date of Birth",
	"email": "Email Address",
	"address": "Address",
	"state": "State",
	"city": "City",
	"pin_code": "Pincode",
	"phone_number": "Mobile Number",
	"alternate_phone_number": "Alternate Mobile Number",
};

/* Bank Data */
const bank_fields_data = {
    "account_type": "Account Type",
    "account_number": "Account Number",
    "phone_number": "Mobile Number",
    "ifsc_code": "IFSC Code",
    "account_holder_name": "Account Holder Name",
    "branch_name": "Branch Name",
    "branch_location": "Branch Location",
};

/* Tax Data */
const tax_fields_data = {
    "gst_number": "GST Number",
    "gst_trade_name": "GST Trade Name",
    "gst_legal_name": "GST Legal Name",
    "gst_registration_status": "GST Registration Status",
    "pan_number": "PAN Number",
    "pan_holder_name": "PAN Holder Name", 
};


const change_reason_type = {
    "change_in_store_details": "Change in Store Details",
    "change_in_bank_details": "Change in Bank Details",  
    "change_in_tax_details": "Change in Tax Details" 
};

const image_type = {
    "address_proof_images_data": "Address Proof",
    "dealer_board_images_data": "Dealer Board",
    "upload_bank_proof_images_data": "Bank Proof",
    "upload_ownership_proof_images_data": "Ownership Proof",
    "gst_certificate_proof_images_data": "GST Proof",
    "pan_certificate_proof_images_data": "PAN Proof"
};

export { 
    status_data, type_data, store_fields_data, bank_fields_data, tax_fields_data, change_reason_type, image_type
};
