import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../components/Loader';

// Login page
export const EkycLoginLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'login-page' */ '../pages/ekyc/login/index'),
    loading: () => <Loader />
});

// Login page
export const EkycDashboardLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'login-page' */ '../pages/ekyc/dashboard/index'),
    loading: () => <Loader />
});

// Outlet add page
export const OutletAddLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'home-page' */ '../pages/ekyc/outlet/outletAdd/OutletAdd'),
    loading: () => <Loader />
});

// Outlet detail Page
export const OutletDetailLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'dashboard-page' */ '../pages/ekyc/outlet/outletDetail/OutletDetail'),
    loading: () => <Loader />
});

// Outlet survey listing page 
export const OutletSurveyLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'retailers-dashboard-page' */ '../pages/ekyc/outletSurveyListing'),
    loading: () => <Loader />
});

// Pending listing page 
export const PendingLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'retailers-dashboard-page' */ '../pages/ekyc/listing/pending/Pending'),
    loading: () => <Loader />
});

// Rejectedlisting page 
export const RejectedLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'retailers-dashboard-page' */ '../pages/ekyc/listing/rejected/Rejected'),
    loading: () => <Loader />
});


// Completed listing page 
export const CompletedLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'retailers-dashboard-page' */ '../pages/ekyc/listing/completed/Completed'),
    loading: () => <Loader />
});

// Rsa form page 
export const RsaFormLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'retailers-dashboard-page' */ '../pages/ekyc/rsa/RsaForm'),
    loading: () => <Loader />
});

// Rsa detail page 
export const RsaDetailLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'retailers-dashboard-page' */ '../pages/ekyc/rsa/RsaDetails'),
    loading: () => <Loader />
});

// Add on Fly page 
export const AddOnFlyLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'retailers-dashboard-page' */ '../pages/ekyc/addOnFly/AddOnFly'),
    loading: () => <Loader />
});