import { NOTIFICATION, META_DATA, GET_STATES, GET_CITIES, STORE_USERS_DATA, STORE_ORG_DATA, STORE_ORG_LIST, TOGGLE_FILTER, SUB_PROJECT_QUERY_DATA_OUTLET, GET_PAN_LIST, GET_WHATSAPP_DETAILS, GET_WHATSAPP_SUMMARY, SIMPLIFIED_META_DATA } from "../helpers/constants/actions"

const app = (
  state = {
    isFilterOpen: false,
  },
  { payload, type }
) => {
  switch (type) {
    case META_DATA:
      return {
        ...state,
        meta: payload,
      };
      break;
    case TOGGLE_FILTER:
      return {
        ...state,
        ...payload,
      };
    case GET_STATES://SIMPLIFIED_META_DATA
      return {
        ...state,
        states: payload,
      };
      case SIMPLIFIED_META_DATA://SIMPLIFIED_META_DATA
      return {
        ...state,
        states: payload,
      };
    case GET_CITIES:
      return {
        ...state,
        cities: payload,
      };
    case STORE_USERS_DATA: {
      return {
        ...state,
        users: [...payload],
      };
    }
    case STORE_ORG_LIST: {
      return {
        ...state,
        org: payload,
      };
    }
    case STORE_ORG_DATA: {
      return {
        ...state,
        orgInfo: payload,
      };
    }
    case NOTIFICATION:
      return {
        ...state,
        notification: {
          ...payload,
        },
      };
    case SUB_PROJECT_QUERY_DATA_OUTLET:
      return {
        ...state,
        subProjectQueryData: {
          ...payload,
        },
      };
    case GET_PAN_LIST:
        return {
          ...state,
          pan_list :payload
        };
    case GET_WHATSAPP_DETAILS:
      return {
        ...state,
        details: [...payload],
      };

    case GET_WHATSAPP_SUMMARY:
      return {
        ...state,
        summary: payload,
      };

    default:
      return state;
  }
};

export default app;
