import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { HightLightText } from './RightSideContainer';
import { defaultFont, font, lineHeight,defaultFontForgotPassword } from '../../../theme/typography';
import { black600, black500 } from '../../../theme/colors';
import EditIcon from '@material-ui/icons/Edit';

const SectionTitleStyle = styled.div`
    line-height: ${lineHeight.medium};
    color: ${black600};
    margin-bottom: 10%;
`;
const HeadingStyle = styled.p`
    font-weight: 700;
    font-size: ${font.smallmedium};    
    margin:0;
    ${props => props.titleStyle && css`
        ${props.titleStyle}
    `}
`;




const SubTextStyle = styled.p`
    margin-top:12px;
    ${defaultFont}
    color: ${black500};
`;

const SubTextStyleForgot = styled.p`
    margin-top:12px;
    ${defaultFontForgotPassword}
    color: ${black500};
`;

export const SectionTitle = ({ title = '', subText = '', titleStyle, highlightTitle = null,customStyles,isEdit=false ,onClick,forgotPassword}) => {
    return (
        <SectionTitleStyle style={{...customStyles}}>
            {highlightTitle ? <HightLightText>{highlightTitle}</HightLightText> : null}
            {title ? <HeadingStyle titleStyle={titleStyle}>{title}</HeadingStyle> : null}
<div style={{display:'flex',alignItems:'center',justifyContent: isEdit ? 'center' : 'flex-start'}}>

{forgotPassword?(<SubTextStyleForgot>{subText}</SubTextStyleForgot>):(
    <SubTextStyle>{subText}</SubTextStyle>

)}
    {isEdit && <EditIcon style={{width:'18px',height:'18px',marginLeft:'5px',color:'#4770C1',cursor:'pointer'}} onClick={onClick}/>}
    
    </div>
            
        </SectionTitleStyle>
    )
}

SectionTitle.propTypes = {
    title: PropTypes.string,
    subText: PropTypes.string
}