import styled, { css } from 'styled-components';
import { black400, white, green300, black700, blue200, blue300} from '../../../../theme/colors';
import { semiBold } from '../../../../theme/typeface';
import { defaultFont, font, regularHeading } from '../../../../theme/typography';

export const DottedLineStyle = styled.p`
    margin:0;
    width: ${props => props.width || '68%'};
    border-bottom: 1px dashed  rgba(144, 145, 145, 0.5);
`;
export const ViewRoleRow = styled.div`
    display:flex;
    justify-content:space-between;
    padding:32px 0;
    width:100%;
    div:first-child{
        width:60%;
    }
    div:last-child{
        width:30%;
    }
`;
export const ActionButton = styled.button`
        outline:none;
        cursor: pointer;
        border:1px solid ${black400};
        width: 85px;
        height: 39px;
        margin-right : 12px;
        ${defaultFont}  
        font-weight : ${semiBold};
        color : ${black400};
        border-radius: 2px;
        ${props => props.active && css`
            background:${props => props.bgColor || green300};
            border:1px solid transparent;
            color: ${white};
        `};
        svg{
            width: 16px;
            vertical-align: top;
            margin-right:4px;
        }
`;

export const CellStyle = styled.div`
    font-size: ${font.default};
    line-height:19px;
    position: relative;
    p:first-child{  
        color: ${black400};
        display:block;
        margin-bottom:8px;
        margin-top:0;
    }
    p:last-child{
        width: 80%;
        color: ${black700};
        margin:0;
        a{
            color:${blue200};
            text-decoration: underline;
        }
    }
`;
export const UserCellStyle = styled.div`
    width:20%;
    span:first-child{
        border-radius:100%;
        width: 64px;
        height: 64px;
        display:inline-block;
        background: ${blue300};
        color: ${white};
        text-align:center;
        padding-top:5px;
        margin-left:12px;
        margin-right:24px;
        font-weight:600;
        font-size: ${font.largeMedium};
        line-height: 150%;
        vertical-align: bottom;
    }
    div{
        display: inline-block;
        p:first-child{
            font-size: ${font.base};
            line-height:19px;
            color: ${black400};
            display:block;
            margin:0;
        }
        p:last-child{
            color: ${black700};
            ${regularHeading.mini}
            margin:6px 0 0;
            font-weight:600;
            max-width:150px;
        }
    }
`;