import styled, { css } from "styled-components";
import {
  white,
  blue300,
  black700,
  green300,
  borderLight,
} from "../../../theme/colors";
import themeSizes from "../../../theme/themeSizes";
import { semiBold, bold } from "../../../theme/typeface";
import { microHeading, baseCaption } from "../../../theme/typography";

export const DetailsLayoutWrapper = styled.section`
  margin-top: 30px;
  display: flex;
  > ul {
    width: 100px;
    margin: 0;
  }
`;

export const VerticalNav = styled.div`
  width: 100%;
  margin-top: 16px;
  position: relative;

  .slick-arrow.slick-disabled {
    display: none !important;
  } 
  .slick-list{
    border-bottom: 1px solid #e6e8f1;
  }
  .slick-track {
    display: flex !important;
    min-height: 34px;
    margin: 0;
    overflow-x:auto;
  }
  .slick-slide {
    margin-right: 22px;
    min-height: 100%;
    max-height: 100%;
    @media ${themeSizes.tablet} {
      width:200px !important;
    };
    @media ${themeSizes.mobile} {
      width:40px !important;
      flex-direction: column !important;
    };
    div {
      display: inline-flex !important;
      outline: none;
      height: 100%;
      @media ${themeSizes.tablet} {
        width:150px !important;
        justify-content:center;
      };
      @media ${themeSizes.mobile} {
        width:20px !important;
        height: 85px;
        justify-content:center;
        flex-direction: column !important;
      };
      span {
        min-width: auto;
        max-width: 100%;
        /* use when tick mark enable
        max-width: 84%;
         */
        display: inline-block;
        padding-bottom: 13px;
      }
    }
  }
`;
export const VerticalEKYCNav = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;
  background-color:white;
  .slick-arrow.slick-disabled {
    display: none !important;
  } 
  .slick-list{
    border-bottom: 1px solid #e6e8f1;
  }
  .slick-track {
    display: flex !important;
    min-height: 34px;
    margin: 0;
  }
  .slick-slide {
    margin-right: 22px;
    min-height: 100%;
    max-height: 100%;
    @media ${themeSizes.tablet} {
      width:200px !important;
    };
    @media ${themeSizes.mobile} {
      width:100% !important;
      background-color:white;
      // border: 2px solid #dfdfdf;
      // border-radius: 5px;
      margin-left:0px;
      // margin-right:10px;
      border-bottom: 0px !important;
     
    };
    div {
      display: inline-flex !important;
      outline: none;
      height: 100%;
      @media ${themeSizes.tablet} {
        width:150px !important;
        justify-content:center;
      };
      @media ${themeSizes.mobile} {
        width:100% !important;
        height: 45px;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction: row !important;
      
      };
      span {
        min-width: auto;
        max-width: 100%;
        /* use when tick mark enable
        max-width: 84%;
         */
        display: inline-block;
        padding-bottom: 13px;
      }
    }
  }
`;
export const LeftSideNavs = styled.ul`
  padding: 0px;
  li {
    list-style: none;
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 28px;
    font-weight: ${semiBold};
    ${baseCaption}
    cursor:pointer;
    svg {
      vertical-align: middle;
      margin-right: 18px;
    }
    &.active {
      background: ${white};
      color: ${blue300};
      font-weight: ${bold};
    }
  }
`;

export const RightSideContent = styled.div`
  background: ${white};
  width: 100%;
  min-height: 500px;
  padding-left: 32px;
  overflow-x: scroll;
  background-color: ${props => props.bgColor || 'white'};
  & > div {
    ${(props) =>
      props.contentMargin &&
      css`
        margin: ${props.contentMargin};
        padding-right: 24px;
      `}
  }
  ${props => props.splitColumn && css`
    display:flex;
    justify-content:space-between;
    padding-right: 24px;
      > section:first-child{
            width:65%
      }

     
      ${VerticalNav}{
        width:100%;
      }
  `}
`;
export const Title = styled.p`
    margin:0px;
    height: 72px;
    ${microHeading.regular}
    font-weight: ${(props) => props.titleFontWeight || semiBold};
    display: flex;
    align-items: center;
    color:${(props) => props.titleColor || "#000000"};
`;

export const VerticalNavItem = styled.div`
padding: 0,
margin: 0,
width: '100%', // Set the width to 100%
color: ${black700},
${baseCaption}
font-weight: ${semiBold};
cursor: pointer;
height: 31px;
display: inline-flex;
align-items: flex-start;
position: relative;

&.active {
  :after {
    content: '';
    position: absolute;
    display: inline-block;
    height: 2px;
    border-radius: 3px;
    background: ${blue300};
    bottom: 0;
    top: 100%;
    left: 0;
    width: 100%;
  }
}
/* tick mark css */
& > i {
  content: '';
  color: ${white};
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-top: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  vertical-align: top;
  position: relative;
  right: 0px;
  margin-left: 10px;
  background: ${green300};
  border: 1px solid transparent;
  :before {
    content: '';
    position: relative;
    bottom: 1px;
    width: 4px;
    border-bottom: 1px solid ${white};
    height: 10px;
    border-right: 1px solid ${white};
    transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  }  
`;
export const VerticalEkycNavItem = styled.div`
    padding:0;
    margin:0;
    width:auto;
    color: ${black700};
    ${baseCaption}
    font-weight: ${semiBold};
    cursor:pointer;
      height: 31px;
      display: inline-flex;
      align-items: flex-start;
      position: relative;
      @media ${themeSizes.mobile} {
        background-color:white !important;
        width:400px !important;
        display:flex;
        justify-content:center;
        
       
      };
      &.active{
          :after{
          content:'';
          position:absolute;
          display:inline-block;
          height:2px;
          border-radius:3px;
          background: ${blue300};
          bottom:0;
          top:100%;
          left:0;
          width:100%;
      }
      }
      /* tick mark css */
     & > i{
      content:'';
      color: ${white};
      width: 16px;
      height: 16px;
      border-radius: 16px;
      margin-top: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      text-align:center;
      vertical-align: top;
      position: relative;
      right:0px;
      margin-left:10px;
      background: ${green300};
      border:1px solid transparent;
          :before{
              content:''; 
              position: relative;
              bottom:1px;
              width: 4px;
              border-bottom: 1px solid ${white};
             
              height: 10px;
              border-right: 1px solid ${white};
             
              transform: rotate(45deg);
              -o-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
                                  
          }
  }  
`;
const ArrowBackground = styled.span`
  background: #dfdfdf;
  border: 1px solid #dfdfdf;
  border-radius: 1px;
  width: 20px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 10px;
  }
  position: absolute;
  top: 0;
  z-index: 888;
`;

const ArrowEKYCBackground = styled.span`
  background: #000;
  border: 1px solid #000;
  border-radius: 50%; 
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top:10px;
  margin-left: 25px;
  svg {
    font-size: 10px;
  }
  position: absolute;
  top: 0;
 
`;
export const PrevArrow = styled(ArrowBackground)`
  left: -30px;
`;
export const NextArrow = styled(ArrowBackground)`
  right: 0;
`;
export const PreEKYCArrow = styled(ArrowEKYCBackground)`
  left: -30px;
`;
export const NextEKYCArrow = styled(ArrowEKYCBackground)`
  right: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  > div:first-child {
    width: 100%;
    >p {
      margin: 18px;
      text-align: center;
    }
  }
`;

