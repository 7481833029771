import dateUtils from "./dateUtils"
import { getDayOrNumberSuffix } from "../getDayOrNumbersuffix";
const DATE_UTILS = dateUtils();

const DateToDisplay = ({date,daysuffix}) => {
    if(!date) {
        return 'NA';
    }

    const tDate = date;
    try {
        const ttDate = new Date(tDate);

        const date = DATE_UTILS.getGlobalDate(ttDate).getDate();
        const month = DATE_UTILS.getMonthFormat(ttDate,'MMM');
        const year = DATE_UTILS.getYearFormat(ttDate,'YYYY');

        return `${daysuffix ? getDayOrNumberSuffix(date) : date} ${month}, ${year}`
    } catch (error) {
        console.log(error);
        return "NA"
    }
}

export default DateToDisplay;
