import capitializeFirstChar from './capitializeFirstChar';

const DDDD = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const DDD = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const MMMM = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
const MMM = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export default function dateUtils() {
  return {
    getGlobalDate: (date = new Date()) => {
      const month = Math.abs(date.getMonth());
      const year = Math.abs(date.getFullYear());
      const dateValue = Math.abs(date.getDate());
      return new Date(year, month, dateValue);
    },
    addDay: (date = new Date(), day = 0) => {
      return new Date(date.setDate(date.getDate() + day));
    },
    addMonth: (date = new Date(), day = 0) => {
      return new Date(date.setMonth(date.getMonth() + day));
    },
    getBetweenDays: (firstDate = new Date(), secondDate = new Date()) => {
      const days = [];
      let newDate = null;
      while (firstDate < secondDate) {
        newDate = firstDate.setDate(firstDate.getDate() + 1);
        if (firstDate < secondDate) {
          days.push(new Date(newDate));
        }
        firstDate = new Date(newDate);
      }
      return days;
    },
    isBetween: (
      date = new Date(),
      firstDate = new Date(),
      secondDate = new Date()
    ) => {
      firstDate = new Date(
        firstDate.getFullYear(),
        firstDate.getMonth(),
        firstDate.getDate()
      );
      secondDate = new Date(
        secondDate.getFullYear(),
        secondDate.getMonth(),
        secondDate.getDate()
      );
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      return firstDate <= date && date <= secondDate;
    },
    isBefore: (date = new Date(), compareDate = new Date()) => {
      return date < compareDate;
    },
    isAfter: (date = new Date(), compareDate = new Date()) => {
      return date > compareDate;
    },
    isSameDate: (firstDate = new Date(), secondDate = new Date()) => {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return diffDays === 0;
    },
    getDayFormat: (date = new Date(), format = 'DDD') => {
      const index = date.getDay();
      switch (format) {
        case 'DDD':
          return DDD[index];
        case 'DDDD':
          return DDDD[index];
        default:
          return `${index + 1}`;
      }
    },
    getMonthFormat: (date = new Date(), format = 'mm') => {
      const index = date.getMonth();
      switch (format) {
        case 'MMM':
          return MMM[index];
        case 'MMMM':
          return MMMM[index];
        case 'MM':
          return index < 9 ? `${index + 1}` : `${index + 1}`;
        default:
          return `${index + 1}`;
      }
    },
    getTimeValue: (date = new Date()) => {
      const hour = date.getHours();
      const min = (date.getMinutes()<10?'0':'') + date.getMinutes();
      if(hour > 12){
        return `${hour % 12}:${min} PM`
      }
      return `${hour}:${min} AM`
    },
    getYearFormat: (date = new Date(), format = 'YYYY') => {
      switch (format) {
        case 'YYYY':
        case 'Y':
          return `${date.getFullYear()}`;
        default:
          return `${date.getYear() - 1}`;
      }
    },
    monthDiff: (firstDate = new Date(), secondDate = new Date()) => {
      var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());
      return Math.round(timeDiff / (2e3 * 3600 * 365.25));
    },
    daysInMonth: (date = new Date()) => {
      // this function will return total no. of days in a month
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    },
    getDate: (date = new Date()) => {
      const utils = dateUtils();
      const cDate = utils.getGlobalDate(new Date(date));
      return cDate.getDate()
    },
    getFullDate: (date = new Date(), monthFormat = 'MMM', yearFormat = 'YYYY') => {
      const utils = dateUtils();
      const cDate = utils.getGlobalDate(new Date(date));
      return `${utils.getMonthFormat(cDate, monthFormat)} ${cDate.getDate()}, ${utils.getYearFormat(cDate, yearFormat)}`
    },
    getStartOf: (date = new Date(), type = 'MONTH') => {
      switch (type) {
        case 'MONTH':
          return new Date(date.getFullYear(), date.getMonth(), 1);
      }
    },
    getMonthNo: month => {
      if (!month) {
        return 1; // by default it will return 1;
      }
      let lowerCaseMonth = month.toLowerCase();
      let ll = capitializeFirstChar(lowerCaseMonth);
      return MMMM.indexOf(ll) + 1;
    },
    getMonth: (monthId, format = MMM) => {
      return format !== 'MMM' ? MMMM[monthId] : MMM[monthId];
    }
  };
}
