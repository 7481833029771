export const getActionList = ({selectedOutletDetails,actions,checkSpecialAction=true}) => {
    const {
        allowed_user_actions_config = {},
        allowed_user_special_actions_config: userSpecialActions,
      } = actions || {};
      ///
      let actionsList = {};

      selectedOutletDetails.some((outlet,selectOutletIndex) => {
        if (allowed_user_actions_config.hasOwnProperty(outlet.workflow_state)) {
          const availableSubproject = Object.keys(
            allowed_user_actions_config[outlet.workflow_state]
          );
          return availableSubproject.some((type,subProjectindex) => {

            if (type.includes(outlet.subProject)) {
              if (Object.keys(actionsList).length) {
                const availableStates = Object.keys(
                  allowed_user_actions_config[outlet.workflow_state][type]
                );

                const isActionAvailable = availableStates.every((state) => {
                  return actionsList.hasOwnProperty(state);
                });

                if (selectOutletIndex > 0  && !isActionAvailable ) {
                  actionsList = {};
                  return true;
                }else if(isActionAvailable){
                  return false
                }else{
                  actionsList = {
                    ...actionsList,
                    ...allowed_user_actions_config[outlet.workflow_state][type],
                  };
                  return false
                }

              } else {
                actionsList = {
                  ...allowed_user_actions_config[outlet.workflow_state][type],
                };
                return false;
              }
            }
          });
        }
        actionsList = {};
        return true;
      });

      const actionOptions = Object.keys(actionsList).map((action) => {
        // if(!actionsList[action]['other_config']['is_normal_list_updating_allowed']){
        //   return []
        // }
        return ({
          label: actionsList[action]["display_name"],
          name: action,
          value: actionsList[action]["extra_data"],
          individualAction : !actionsList[action]['other_config']['are_multiple_instances_allowed'],
          noActionsAvailable: actionsList[action]['other_config']['is_normal_list_updating_allowed']
        })
      })

    if(checkSpecialAction){
      //Append special action options if any available to Action dropdown
      let specialActionsList = {};
      userSpecialActions &&
        Object.keys(userSpecialActions).some((userActionState) => {
          const subProjectKeys = Object.keys(userSpecialActions[userActionState]);
          return subProjectKeys.some((subKey) => {
            return selectedOutletDetails.some((outlet) => {
              if (subKey.includes(outlet.subProject)) {
                const {
                  allowed_states,
                  display_name,
                  extra_data,
                } = userSpecialActions[userActionState][subKey];
                const specialActionsListKeys = Object.keys(specialActionsList);

                let workflowAvailable = allowed_states.includes(
                  outlet.workflow_state
                );
                if (specialActionsListKeys.length) {
                  if (workflowAvailable) {
                    const isActionAvailable = specialActionsListKeys.every(
                      (action) => specialActionsList[action].label === display_name
                    );
                    if (!isActionAvailable) {
                      specialActionsList = {};
                      return true;
                    }
                    return false;
                  }
                } else if (workflowAvailable) {
                  specialActionsList[userActionState] = {
                      label: display_name,
                      name: userActionState,
                      value: extra_data,
                    }

                  return false;
                }
                // fix for Allocate Reporting Field Manager option not shown(second iteration skipped as return value is true)
                // return true
                return false;
              }
            });
          });
        });
      actionOptions.push(...Object.values(specialActionsList));
    }
      return actionOptions
}
