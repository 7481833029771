import React, { Component, PropTypes } from "react";

class CheckBoxSingle extends Component {
  state = {
    isChecked: false,
  };
  render() {
    const { label, key, name, value, onChange } = this.props;
    return (
      <div className="checkbox">
        <label key={key}>
          <input
            type="checkbox"
            value={value}
            name={name}
            onChange={onChange}
            style={{
              width: "15px",
              display: "inline-block",
              height: "15px",
              margin: "6px 5px",
              fontWeight: "bold",
              borderRadius: "5px",
            }}
          />

          {label}
        </label>
      </div>
    );
  }
}

// CheckBoxSingle.propTypes = {
//   label: PropTypes.string.isRequired,
//   handleCheckBoxSingleChange: PropTypes.func.isRequired,
// };

export default CheckBoxSingle;

