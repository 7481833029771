import { triggerSimpleAjax } from "../../helpers/httpHelper";
import { UPLOAD_FILE } from "../../helpers/constants/apiUrl";

const bulkUploadData = (req, obj_type = 'users', queryParams) => {
    const apiUrl = `${UPLOAD_FILE}?object=${obj_type}${queryParams || ''}`;
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            apiUrl,
            'POST',
            req,
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            },
            {},
            true
        )
    });
}

export default bulkUploadData;