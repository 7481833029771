import styled from "styled-components";
import React, {Component, Fragment} from "react";
import {getUploadTableData} from "../../actions/getOutletData";
import appendToSearch from "../../helpers/utils/appendToSearch";
import get from "../../helpers/lodashUtils/get";
import processTable from "../../actions/processOutlet";
import ReactTable from "../../components/table";
import {Button} from "../../components/Button";
import {
  accepted,
  cancelled,
  cinnabar300,
  completed,
  curiousBlue300,
  green300,
  processing,
  white
} from "../../theme/colors";
import RefreshIcon from "@material-ui/icons/Refresh";
import {AnchorText} from "../dashboard/components/ProjectCard";
import dateUtils from "../../helpers/utils/dateUtils";
import {StatusTagStyle} from "../../components/StatusTagStyle";
import CheckBox from "../../components/ui/CheckBox";
import {ConfirmationModal} from "../dashboard/components/ConfirmationModal";
import {bindActionCreators} from "redux";
import triggerNotification from "../../actions/triggerNotification";
import {withLastLocation} from "react-router-last-location";
import {connect} from "react-redux";
import IciciPaymentConfirmationModal from "./IciciPaymentConfirmationModal";

const Bold = styled.p`
 font-weight: bold;
`;
class BulkTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      open: false,
      confirmationAction: null,
      confirmationText: `Confirming will create  these ${props.keyValue} in the  ${props.keyValue} master.
      This cannot be rolled back.`,
      isLoading: false,
      tickOrCancelClicked: false,
      instance_id: ''
    };
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    this.triggerTableData();
  };

  triggerTableData = () => {
    this.setState({ isLoading: true });
    const { location } = this.props;
    const qs = location.search ? location.search.replace("?", "&") : "";

    getUploadTableData(`${this.props.keyValue}${qs}`)
      .then((response) => {
        this.setState({
          tableData: [...response.results],
          count: response.count,
          previous: response.previous,
          next: response.next,
          current: response.current_page_number,
          pageSize: response.page_size,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error", error);
        this.setState({ isLoading: false });
      });
  };

  triggerCompleted = (id) => {
    this.setState({
      tickOrCancelClicked: true,
      triggeredCancel: false
    });
    this.processTableAction(id, true);
  };

  triggerCancelled = (id) => {
    this.setState({
      tickOrCancelClicked: true,
      triggeredCancel: true,
    });
    this.processTableAction(id, false);
  };

  onPageChange = (pageNumber, b, c, d) => {
    const { history, location } = this.props;
    history.push({
      route: "/",
      state: { ...history.location.state },
      search: appendToSearch(location.search, {
        page: pageNumber,
      }),
    });
  };

  processTableAction = (id, isActivate) => {
    const { location } = this.props;
    let projId = get(location, "state.subProjectId");
    this.setState({
      instance_id: id,
      open: true,
      confirmationText: isActivate
        ? (<div>{`Confirming will create  these  ${this.props.keyValue} in the project  ${this.props.keyValue} master.
      This cannot be rolled back.`} <Bold>{`Any ${this.props.keyValue} upload in this project will be cancelled`} </Bold></div>)
        : `Confirming will cancel the job. This cannot be rolled back.`,
      confirmationAction: () => {
        processTable(id, projId, isActivate, this.props.keyValue, this.props.isSKU, this.props.question_group)
          .then((response) => {
            // this.props.notificationHandler({
            //   messageText: response.detail,
            //   status: "SUCCESS",
            // });
            this.setState(
              {
                payment_success: true,
                confirmationAction: null,
                tickOrCancelClicked: false,
              },
              () => {
                this.triggerTableData();
              }
            );
          })
          .catch((error) => {
            this.setState(
              {
                open: false,
                confirmationAction: null,
                tickOrCancelClicked: false,
              },
              () => {
                this.props.notificationHandler({
                  messageText: error.detail,
                  status: "FAILED",
                });
              }
            );
          });
      },
    });
  };

  componentDidUpdate(prevProps) {
    const { location, refreshData, uploadedId } = this.props;
    if ((prevProps.refreshData === false && refreshData) || location.search !== prevProps.location.search || prevProps.uploadedId !== uploadedId) {
      this.setState({ isLoading: true });
      this.triggerTableData();
    }
  }

  handleStatusValues = (value) => {
    if(value === "active"){
      return "Process Completed"
    } else if (value === "under_activation"){
      return "Processing Payment"
    }
    return value
  }
  render() {
    const {
      previous,
      next,
      pageSize,
      count,
      tableData,
      isLoading,
      tickOrCancelClicked,
    } = this.state;
    return (
      <Fragment>
        <ReactTable
          rowHeight={"auto"}
          title="History"
          rightHeaderElements={
            <Button
              size="medium"
              bgColor={curiousBlue300}
              color={white}
              onClick={() => {
                this.setState({ isLoading: true });
                this.triggerTableData();
              }}
            >
              <RefreshIcon /> Refresh
            </Button>
          }
          loading={isLoading}
          current={previous ? previous + 1 : 1}
          previousPage={previous}
          nextPage={next}
          pageSize={pageSize}
          showTotalRecordText={true}
          total={count}
          onPageChange={this.onPageChange}
          filters={[]}
          columns={[
            {
              name: "Job ID",
              key: "id",
              sortable: false,
              width: "80px",
            },
            {
              name: "Uploaded  XLS",
              key: "file",
              sortable: false,
              width: "190px",
              wordBreak: "break-all",
              render: (data) => {
                const splitValues = data.split("/");
                return (
                  <AnchorText href={data} target={"_blank"}>
                    {splitValues[splitValues.length - 1]}
                  </AnchorText>
                );
              },
            },
            {
              name: "Updated By",
              key: "created_by.name",
              sortable: false,
              width: "120px",
            },
            {
              name: "Updated On",
              key: "created",
              sortable: false,
              width: "160px",
              render: (data) => {
                const updatedDate = new Date(data);
                return `${dateUtils().getFullDate(
                  updatedDate
                )} | ${dateUtils().getTimeValue(updatedDate)}`;
              },
            },
            {
              name: "Status",
              key: "status",
              sortable: false,
              width: "200px",
              render: (value) => {
                let color;
                switch (value) {
                  case "completed":
                    color = completed;
                    break;
                  case "processing":
                    color = processing;
                    break;
                  case "active":
                  case "accepted":
                    color = accepted;
                    break;
                  case "cancelled":
                    color = cancelled;
                    break;
                  case "failed":
                    color = cancelled;
                    break;
                  default:
                    color = completed;
                    break;
                }
                return (
                  <StatusTagStyle bordered borderColor={color} size="small">
                    {this.handleStatusValues(value)}
                  </StatusTagStyle>
                );
              },
            },
            {
              name: `Total Data`,
              key: "total_objects",
              width: "70px",
            },
            {
              name: "Payment successful",
              key: "total_clean_objects",
              width: "120px",
              render: (data, obj) => {
                return (
                  <AnchorText
                    href={obj.clean_objects_media_path}
                    target={"_blank"}
                  >
                    {data || "--"}
                  </AnchorText>
                );
              },
            },
            {
              name: "Payment unsuccessful",
              key: "total_unclean_objects",
              width: "120px",
              render: (data, obj) => {
                return (
                  <AnchorText
                    href={obj.unclean_objects_media_path}
                    target={"_blank"}
                  >
                    {data || "--"}
                  </AnchorText>
                );
              },
            },
            {
              name: `Processed  Data`,
              key: "total_validated_objects",
              width: "80px",
              render: (value, data) => `${value}/${data.total_objects}`,
            },
            {
              name: "Waiting Queue",
              key: "celery_queue_length",
              width: "70px",
              render: (value, data) => {
                return (
                  <AnchorText>
                    {data.status === "processing"
                      ? `${data.celery_queue_index}/${data.celery_queue_length}`
                      : "--"}
                  </AnchorText>
                );
              },
            },
            {
              name: " ",
              key: "status",
              sortable: false,
              width: '250px',
              render: (data, { id, total_clean_objects }) => {
                let tickAction = null;
                let cancelAction = null;
                if (data === "completed") {
                  tickAction = total_clean_objects
                    ? this.triggerCompleted
                    : null;
                  cancelAction = this.triggerCancelled;
                } else if (data === "active") {
                  return (
                    <Button size="small" bgColor={green300} boxHeight="30px"> <span style={{ fontSize: 14}}>Paid</span> </Button>
                  )
                } else if (
                  data === "cancelled" ||
                  data === "accepted" ||
                  data === "failed" ||
                  data === "under_activation" ||
                  data === "processing"
                ) {
                  return null;
                } else {
                  tickAction = null;
                  cancelAction = this.triggerCancelled;
                }
                return (
                  <Fragment>
                    <div
                      onClick={() => {
                        console.log("Instance Id", id)
                        this.setState({
                          instance_id: id
                        }, () => {
                          console.log("Instance Id Set")
                          !tickOrCancelClicked && tickAction && tickAction(id);
                        })
                      }}
                    >
                      {/*<CheckBox*/}
                      {/*  checked={true}*/}
                      {/*  disabled={*/}
                      {/*    tickAction ? tickOrCancelClicked || false : true*/}
                      {/*  }*/}
                      {/*/>*/}
                      <Button size="small" bgColor={green300} boxHeight="30px"> <span style={{ fontSize: 14}}>Pay Now</span> </Button>
                    </div>
                    <div
                      onClick={() => {
                        !tickOrCancelClicked &&
                        cancelAction &&
                        cancelAction(id);
                      }}
                    >
                      {/*<CheckBox*/}
                      {/*  checked={true}*/}
                      {/*  isTick={false}*/}
                      {/*  background={cinnabar300}*/}
                      {/*  disabled={*/}
                      {/*    cancelAction ? tickOrCancelClicked || false : true*/}
                      {/*  }*/}
                      {/*/>*/}
                      <Button size="small" bgColor={cinnabar300} boxHeight="30px"><span style={{ fontSize: 14}}>Cancel</span></Button>
                    </div>
                  </Fragment>
                );
              },
            },
          ]}
          showPagination={true}
          data={tableData || []}
        />
        <IciciPaymentConfirmationModal
          open={this.state.open}
          noAction={() => {
            this.setState({
              open: false,
              confirmationAction: null,
              tickOrCancelClicked: false,
            });
          }}
          yesAction={this.state.confirmationAction}
          confirmationText={this.state.confirmationText}
          proceedText={"Do you want to proceed?"}
          closeAction={() => {
            this.setState({
              open: false,
              confirmationAction: null,
              tickOrCancelClicked: false,
            });
          }}
          triggeredCancel={this.state.triggeredCancel}
          instance_id={this.state.instance_id}
          payment_success={this.state.payment_success}
        />
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    notificationHandler: bindActionCreators(triggerNotification, dispatch),
  };
};

export default withLastLocation(connect(null, mapDispatchToProps)(BulkTable));
