import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { DashboardTextBox } from '../../pages/dashboard/components/DashboardTexBox';

const SearchInput = ({
    value='',
    onChange,
    onClick,
    onEnter
}) => {
    return <DashboardTextBox
        value={value}
        rightIcon={<SearchIcon />}
        isSearchBox={true}
        placeholder={'Type Something Here'}
        onChange={onChange}
        onClick={onClick}
        onEnter={onEnter}
       
    />
}

export default SearchInput;