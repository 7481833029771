import { triggerSimpleAjax } from "../../helpers/httpHelper";
import { UPLOAD_FILE } from "../../helpers/constants/apiUrl";

const activateBulkUploadData = (id, obj_type = 'users',extraParams='') => {
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            `${UPLOAD_FILE}${id}/activate/?object=${obj_type}${extraParams}`,
            'POST',
            {},
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            }
        )
    });
}

export default activateBulkUploadData;