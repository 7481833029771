import { useRef, useEffect } from 'react';

/**
 * Custom react hook to handle outside click events
 * @param {function} callbackEvent - will be called with the status true if clicked outside
 * @return {Ref} targetRef - Ref to be used in the target element
 */
const useOutsideClick = callbackEvent => {
  const targetRef = useRef(false);
  const handleOutsideClick = event => {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      typeof callbackEvent === 'function' && callbackEvent(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return targetRef;
};

export default useOutsideClick;
