import React, { Fragment, Component } from 'react';
import { withLastLocation } from "react-router-last-location";
import BulkUploadWrapper from "../organization-roles-users/BulkUploadWrapper"
import {FormContainer} from "../components/FormContainer";
import FileUpload from "../../../components/FileUpload";
import {FormRow} from "../components/FormRow";
import {triggerSimpleAjax} from "../../../helpers/httpHelper";
import {APP_FIELD_DUMP_TABLE, GET_COMMON_DATA} from "../../../helpers/constants/apiUrl";
import ReactTable from "../../../components/table";
import {Button} from "../../../components/Button";
import {curiousBlue300, white} from "../../../theme/colors";
import RefreshIcon from "@material-ui/icons/Refresh";
import {AnchorText} from "../components/ProjectCard";
import {SelectFilter} from "../components/SelectFilter";


class FieldAppDump extends Component {
  state = {
    isLoading: false,
    dumpData: [],
    formValues: {}
  }
  onSubmitAction = () => {

  }
  componentDidMount() {
    this.getDumpTableData()
    this.getFieldUsers()
  }

  getDumpTableData = (method="GET") => {
    let reqObj = {}
    if (method === "POST"){
      const { formValues } = this.state;
      reqObj = formValues
    }

    this.setState({
      isLoading: true
    })

    triggerSimpleAjax(
      APP_FIELD_DUMP_TABLE,
      method,
      reqObj,
      (response) => {
        this.setState({
          dumpData: response.results,
          count: response.count,
          isLoading: false
        })
      }

    )
  }
  getFieldUsers = () => {
    triggerSimpleAjax(
      GET_COMMON_DATA,
      "POST",
      {
        objects: [
          "workflow_field_auditors",
        ],
      },
      (response) => {
        const value = response.workflow_field_auditors
        const dropdownOptions = value.map(data => ({
          value: data.id || data,
          name: data.name || data,
          label:data.name || data
        }))
          this.setState({
          users: dropdownOptions
        })
      }
    )
  }

  render() {
    const {
      isLoading,
      dumpData,
      count,
      users
    } = this.state;
    return (
      <Fragment>
        <FormContainer
          padding='0px'
          title="Field App Dump Upload"
          footerLocation={'42%'}
          onClickAction={() => this.getDumpTableData("POST")}
          onCancelAction={null}
          actionButtonText="Upload"
        >

          <FormRow
            name="Add Dump File"
            labelAlign="20px"
            leftSideWidth="150px"
            rightElement={
              <FileUpload
                boxPadding="10px 40px"
                boxWidth="540px"
                type="file"
                inputLabel="Upload dump data"
                onFileChosen={(id, fileName) => {
                  this.setState({
                    ...this.state,
                    formValues: {
                      "uploaded_file": id
                    }
                  })
                }
                }
              />

            }
          />

          <FormRow
            name="Select User"
            labelAlign="20px"
            leftSideWidth="150px"
            rightElement={
              <SelectFilter
                props={{
                  name: 'state',
                  placeholder: "Select User",
                  onChange: (data) => {
                    this.setState({
                      ...this.state,
                      formValues: {
                        ...this.state.formValues,
                        field_app_user: data.value
                      }
                    })
                  },
                  options: users,

                }}
                noOptionMessage="No data available"

              />
            }
          />

        </FormContainer>
        <ReactTable
          title="History"

          showPagination={false}
          perPage={15}
          data={dumpData}
          total={count}
          loading={isLoading}
          columns = {[
            {
              name: "ID",
              key: "id",
              sortable: false,
              width: "80px",
            },
            {
              name: "Created By",
              key: "created_by.name"
            },
            {
              name: "Field App User",
              key: "field_app_user.name"
            },
            {
              name: "Status",
              key: "status"
            },
            {
              name: "Uploaded  File",
              key: "uploaded_file",
              sortable: false,
              width: "190px",
              wordBreak: "break-all",
              render: (data) => {
                const splitValues = data.split("/");
                return (
                  <AnchorText href={data} target={"_blank"}>
                    {splitValues[splitValues.length - 1]}
                  </AnchorText>
                );
              },
            },
          ]}

          rightHeaderElements={
            <Button
              size="medium"
              bgColor={curiousBlue300}
              color={white}
              onClick={() => {
                this.setState({ isLoading: true });
                this.getDumpTableData();
              }}

            >
              <RefreshIcon /> Refresh
            </Button>
          }



        />

      </Fragment>
    )
  }
}

export default withLastLocation(FieldAppDump);
