import React from 'react';
import styled, { css } from 'styled-components';
import { selago, cinnabar300 } from '../../theme/colors';

const FilterIconStyle = styled.span`
  cursor: pointer;
  position: relative;

  ${props => props.applied && 
    css`
      &:before,
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        content: ' ';
        border-radius: 100%;
      }

      &:before {
        height: 10.5px;
        width: 10.5px;
        background-color: ${selago};
      }

      &:after {
        height: 7.5px;
        width: 7.5px;
        background-color: ${cinnabar300};
        margin: 2px;
      }
    `
  }
`

const IconWithRedBubble = ({ children, ...rest }) => {
    return <FilterIconStyle {...rest}>
        {children}
    </FilterIconStyle>    
};

export default IconWithRedBubble;