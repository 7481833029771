import styled from 'styled-components'
import { cinnabar100, cinnabar400, green100, green400 } from '../../theme/colors';
import { defaultFont } from '../../theme/typography';

const AlertBox = styled.div`
  width: ${(props) => props.boxWidth || "551px"};
  background: ${(props) => (props.isFailed ? cinnabar100 : green100)};
  margin-top: ${(props) => props.marginTop || "35px"};
  margin-left: ${(props) => props.marginLeft || "15px"};
  border-radius: 2px;
  padding: 20px; 
  padding: ${(props) => props.padding || "20px"};

  p {
    color: ${(props) => (props.isFailed ? cinnabar400 : green400)};
    ${defaultFont};
    margin: 0;
    padding-bottom: 20px;
  }

  div {
    color: ${(props) => (props.isFailed ? cinnabar400 : green400)};
    a {
      border: 1px solid transparent;
    }
  }
`;

export default AlertBox;
