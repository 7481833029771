import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled, { css } from 'styled-components';
import triggerNotification from '../actions/triggerNotification';


const Notify = styled.div`
    position: fixed;
    bottom: -70px;
    visibility: hidden;
    transition: all .3s ease-out;
    z-index: 100;
    width: auto;
    left: 50%;
    transform: translate(-50%);

    &.in {
        visibility: visible;
        bottom: 16px;
    }

    &.failed {
        .notification-content {
            background-color: #E23A3A;
        }  
    }

    .notification-content {
        background: #2CAE66;
        color: #ffffff;
        margin: 0 auto;
        display: inline-block;
        max-width: 100%;
        min-height: 52px;
        padding: 15px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: -.3px;
            font-weight: 500;
            margin-bottom: 0;
            padding: 0px;
            margin: 0px;
            text-align: center;
        }
    }
`


function Notification({
    messageText = '',
    notificationHandler,
    status
}) {
    if (messageText) {
        setTimeout(() => {
            notificationHandler({})
        }, 5000)
    }
    return (
        <Notify className={`${status === 'FAILED' ? 'failed' : ''} ${messageText ? 'in' : ''} `}>
            <div className='notification-content'>
                <p>{messageText}</p>
            </div>
        </Notify>
    )
}

function mapStateToProps({ app = {} }) {
    const { notification = {} } = app;
    const { messageText = '', status = 'SUCCESS' } = notification;
    return {
        messageText,
        status
    };
}

const mapDispatchToProps = dispatch => {
    return {
        notificationHandler: bindActionCreators(triggerNotification, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);