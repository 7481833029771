import { triggerSimpleAjax } from '../../helpers/httpHelper';
import { GET_VIEW_MOBILE_INVOICES } from '../../helpers/constants/apiUrl';



export default function getMobileViewInvoices(req = '') {
    return new Promise((res, rej) => {
        const url = `${GET_VIEW_MOBILE_INVOICES}${req}?no-pagination`;
        triggerSimpleAjax(
            url,
            'GET',
            {},
            (response) => {
                res(response);
            },
            error => {
                rej(error);
            }
        );
    });
}
