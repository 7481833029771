import React, {Component, useEffect, useRef, useState} from "react";
import {triggerSimpleAjax} from "../../../helpers/httpHelper";
import {GET_OUTLET_GROUP, GET_OUTLETS_DETAILS, UPLOAD_API} from "../../../helpers/constants/apiUrl";
import {NextArrow, PrevArrow, RightSideContent, VerticalNav, VerticalNavItem} from "./outlet.styles";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import { ImageBlockWrapper, ImageWrapper, OverallBlockWrapper} from "./OutletTotalImages.styles";
import InnerImageZoom from "react-inner-image-zoom";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import ImageGallery from "../../../components/image_gallery/ImageGallery";
import { useParams } from 'react-router-dom';
import Loader from "../../../components/Loader";
import LoadingWrapper from '../../../components/LoadingWrapper'
import styled from "styled-components";
import { black200, royalBlue300, white } from "../../../theme/colors";
import themeSizes from "../../../theme/themeSizes";
import { bold } from "../../../theme/typeface";
import { baseCaption, defaultFont } from "../../../theme/typography";
import CloseIcon from "@material-ui/icons/Close";

const RowWrapper = styled.div`
  display: flex;
  background: ${white};
  padding: 24px 24px 24px 12px;
  margin-bottom : 25px;
  &:last-child {
    border: none;
  }

  @media ${themeSizes.mobile} {
    flex-flow: column;
    border-block: none;
  }
`;

const ColumnWrapper = styled.div`
  border-right: 1px solid ${black200};
  width: 33%;
  padding-left: 12px;

  &:last-child{
    border-right: none
  }
  
  p:first-child {
    color: ${royalBlue300};
    font-weight: ${bold};
    ${baseCaption};
    margin-top:0;
  }
  p:last-child {
    ${defaultFont};
    margin-bottom:0;
  }
  @media ${themeSizes.mobile} {
    width: 100%;
    border-right: none;
  }
`;

export const ImageHandler = ({ imageData, onImageOpenClick, width=300, height=400,isDeleteShow=false , deleteHandler }) => {
  const [ rotate, setRotate ] = useState(0)
  const imageSrc = imageData.image
  return (
    <ImageBlockWrapper>
      <div style={{
        transform: `rotate(${rotate}deg)`
      }}>

{isDeleteShow &&

<div onClick={deleteHandler}>

    <CloseIcon  style={{background:"black",color:'white',position:'absolute',zIndex:9,right:'0px',borderRadius:'10px'}}/>
    </div>
}
        <InnerImageZoom src={imageSrc} width={width} height={height} zoomPreload />
      </div>
      <div>{imageData?.image_content}</div>

      <div style={{
        zIndex: 1000,
        margin: '20px 0'
      }}
      >
        <RotateLeftIcon onClick={() => {
          setRotate(prevRotate => prevRotate - 90)
        }} />
        <RotateRightIcon onClick={ () => {
          setRotate(prevRotate => prevRotate + 90)
        }} />
        <OpenWithIcon onClick={() => {
          onImageOpenClick(imageData)
        }} />
      </div>

    </ImageBlockWrapper>
  )
}

const OutletTotalImages = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [groupImages, setGroupImages] = useState([])
  const [activeImages, setActiveImages] = useState([])
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const [showGallery, setShowGallery] = useState(false);
  const [galleryImage, setGalleryImage] = useState(null);
  const [outletDetails, setOutletDetails] = useState({});
  const [outletDetailsLoading, setOutletDetailsLoading] = useState(true)
  const sliderRef = useRef()
  const { outletID, outletSlug, viewType } = useParams();

  console.log("View type", viewType)

    const getWorkflowSlugFromViewType = () => {
      const viewTypeArr = viewType.split("-")
      const slug = viewTypeArr[viewTypeArr.length - 1]
      return slug
    }

  // API
  const getTotalImages = () => {
    triggerSimpleAjax(
      `${UPLOAD_API}?related_multiple_images_answer_input__workflow_object__outlet=${outletID}&related_single_image_answer_input__workflow_object__outlet=${outletID}&or_based_filter_keys=related_single_image_answer_input__workflow_object__outlet,related_multiple_images_answer_input__workflow_object__outlet&no-pagination&all_survey_images=True`,
      "GET",
      {},
      (response) => {
          if(response.length === 0){
              setGroupImages([])
          }
        setGroupImages(response)
        setIsLoading(false)
      }
    );
  }

  const getOutletDetails = () => {
    triggerSimpleAjax(
      `${GET_OUTLETS_DETAILS}${outletSlug}/`,
      "GET",
      {},
      (response) => {
        setOutletDetails(response)
        setOutletDetailsLoading(false)
      }
    )
  }

  const getCurrentSurveyImages = () => {
      triggerSimpleAjax(
          `${GET_OUTLET_GROUP}${getWorkflowSlugFromViewType()}/answer-linked-images/?related_multiple_images_answer_input__workflow_object=${outletID}&related_single_image_answer_input__workflow_object=${outletID}&all_survey_images=True`,
          "GET",
          {},
          (response) => {
              // Math.random is added to prevent image load from cache
              setGroupImages(response);
              setIsLoading(false)
          },
          (error) => { }
      );
  }
  useEffect(() => {
      getOutletDetails()

      if(viewType === "total-images"){
          getTotalImages()
      } else if (viewType.includes("current-survey-images")){
          getCurrentSurveyImages()
      } else {
        getTotalImages()
      }
  }, [])

  // Handle Active Index Change
  useEffect(() => {
    if(!isLoading){
      setActiveImages(groupImages[activeGroupIndex]?.images || [])
    }

  }, [activeGroupIndex, isLoading])

  const NextArrowWrapper = () => (
    <NextArrow
      onClick={() => {
        sliderRef.current.slickNext();
        if (activeGroupIndex < groupImages.length - 1) {
          setActiveGroupIndex(activeGroupIndex + 1);
        }
      }}
    >
      <ArrowForwardIosIcon/>
    </NextArrow>
  );

  const PrevArrowWrapper = () => (
    <PrevArrow
      onClick={() => {
        sliderRef.current.slickPrev();
        if (activeGroupIndex !== 0) {
          setActiveGroupIndex(activeGroupIndex - 1);
        }
      }}
    >
      <ArrowBackIosIcon/>
    </PrevArrow>
  );

  const settings = {
    // variableWidth: true,
    //   centerMode: true,
    //   centerPadding: '0',
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrowWrapper/>,
    prevArrow: <PrevArrowWrapper/>
  };



  return (
    <>
     <RowWrapper>
         <ColumnWrapper>
            <p>RSID</p>
            <p>{outletDetails.rsid}</p>
          </ColumnWrapper>
          <ColumnWrapper>
            <p>Outlet Name</p>
            <p>{outletDetails.name}</p>
          </ColumnWrapper>
          <ColumnWrapper>
            <p>Sub Project</p>
            <p>
              {outletDetails?.sub_project?.name}
            </p>
          </ColumnWrapper>
        </RowWrapper>
        <LoadingWrapper isLoading={isLoading}>
            <RightSideContent bgColor={viewType === "total-images" || viewType.includes("current-survey-image") ? "#C7F094" : "#FF9E89"}>
                {groupImages.length > 0 ? (
                    <>
                        <VerticalNav style={{
                            width: '100%'
                        }}>
                            <Slider {...settings} ref={sliderRef}>
                                {groupImages.map((group, index) => (
                                    <VerticalNavItem
                                        className={index === activeGroupIndex ? "active" : ""}
                                        onClick={() => setActiveGroupIndex(index)}
                                    >
                                        <span>{group.question_group_name} ({group?.images && group?.images.length}) </span>
                                    </VerticalNavItem>
                                ))}
                            </Slider>
                        </VerticalNav>

                        <OverallBlockWrapper>
                            {activeImages.map(imageData => (
                                <ImageHandler imageData={imageData} onImageOpenClick={(imageData) => {
                                  setGalleryImage([imageData])
                                  setShowGallery(true)
                                }} />
                            ))}

                        </OverallBlockWrapper></>
                ): (
                    <p style={{
                        display: 'flex',
                        alignItems: 'stretch',
                        justifyContent: 'center',
                        fontWeight: 600,
                        fontSize: 17
                    }}>No Images Present</p>
                )}

            </RightSideContent>
        </LoadingWrapper>

      {showGallery && (
        <ImageGallery
          images={galleryImage}
          setShowGallery={setShowGallery}
          noExit={false}
        />
      )}
    </>

  )
}

export default OutletTotalImages
