import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import { OTP_SENT_API } from "../../../helpers/constants/ekycApiUrl";

const OtpSentAction = (req) => {
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            OTP_SENT_API,
            'POST',
            req,
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            }
        )
    });
}

export default OtpSentAction;