import { AppConfig } from "../../app-config";

export const LOGIN_API = `${AppConfig.api_url}auth/step-helper/?platform=my_track`;

export const OTP_SENT_API = `${AppConfig.api_url}auth/forgot/send-code/`;

export const OTP_VERIFY_API = `${AppConfig.api_url}auth/login/`;

export const DASHBOARD_API = `${AppConfig.api_url}auth/user-dashboard/`;

export const PENDING_LIST_API = `${AppConfig.api_url}v2/e-kyc/bucket-list/pending/`;

export const REJECTED_LIST_API = `${AppConfig.api_url}v2/e-kyc/bucket-list/rejected/`;

export const COMPLETED_LIST_API = `${AppConfig.api_url}v2/e-kyc/bucket-list/completed/`;

export const OUTLET_SURVEY_DETAIL_API = `${AppConfig.api_url}project/outlets/`;
export const OUTLET_SURVEY_DETAIL_EDIT_API =(slug="")=> `${AppConfig.api_url}project/outlets/${slug}/update-outlet-details/`;

export const OUTLET_SURVEY_API = `${AppConfig.api_url}v2/e-kyc/`;

export const FIELD_APP_BULK_SYNC = `${AppConfig.api_url}bulk/field-sync-write/?ekyc=1`;

export const FETCH_FLY_DATA = `${AppConfig.api_url}v2/e-kyc/add-on-fly/init/`;

export const SEND_FLY_DATA = `${AppConfig.api_url}v2/e-kyc/add-on-fly/end/`;

export const RSA_OTP_SENT_API =(slug,phoneNumber)=> `${AppConfig.api_url}project/outlets/${slug}/trigger-rsa-verification-code/?phone_number=${phoneNumber}`

export const FETCHING_FILTER_ID = `${AppConfig.api_url}v2/outlet/filter-data-read/?workflow_based=true`;

export const EKYC_FETCHING_FILTER_ID =(type="")=> `${AppConfig.api_url}v2/outlet/filter-data-read/?type=${type}`;

export const FILTER_DATA = (id="") => `${AppConfig.api_url}v2/common/load-objects/${id}/`;