import React from 'react';
import styled from 'styled-components';
import { black600 } from '../theme/colors';
import { baseCaption } from '../theme/typography';
import CheckBox from './ui/CheckBox';

const ChecKBoxListStyle = styled.ul`
    width:100%;
    padding:0;
    ${baseCaption}
    color: ${black600};
    position: relative;
    overflow-y: auto;
    margin-top:0;
    max-height: 170px;
    transition:all 0.4s; 
    cursor: pointer;   
    li{
        list-style:none;
        margin-top:18px;
        cursor: pointer; 
        &:first-child{
            margin-top:0;
        }
    }
`;

const CheckBoxList = ({filterName='',options=[],checkedList=[],onChangeFilterValue=()=>{}, labelKey = 'name', valueKey = 'id'}) => {
    return(
        <ChecKBoxListStyle key={filterName} id='filter-overflow'>
            {options.map((opt, index) => {
                const isChecked = (checkedList || []).includes(opt[valueKey]);

                return (
                    <li key={`${index}`} className={isChecked ? 'checked' : ''}>
                        <CheckBox
                            name={filterName}
                            checked={isChecked}
                            label={opt[labelKey]}
                            onChange={(event) => {
                                onChangeFilterValue({ event, target: opt[valueKey] })
                            }}
                        />
                    </li>
                )
            })}
        </ChecKBoxListStyle>
    )
}

export default CheckBoxList;