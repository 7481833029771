import React from 'react';
import styled, { css } from 'styled-components';
import { defaultFont, baseCaption } from '../theme/typography';
import { white, black600 } from '../theme/colors';

const IconStyle = styled.div`
        display:inline-block;
        height:28px;
        color:${black600};
        margin:${({parentMargin}) => parentMargin || '0 32px 0 0'};
        ${baseCaption}
        position: relative;
        span:first-child{
            ${defaultFont}
            display:inline-block;
            border-radius:100%;
            width:28px;
            height:28px;
            background: #8F8FC8;
            color:${white};
            text-align:center;
            margin: ${({letterIconStyle}) => letterIconStyle.margin || '0 8px 0 12px'};
            vertical-align: text-bottom;
            padding-top: 2px;
            cursor: default;
            ${({letterIconStyle}) => letterIconStyle && css`
                ${letterIconStyle}
            `};
        }
        span:nth-child(2){
            color: ${black600};
            margin-right:0;
            ${baseCaption}
            font-weight: 600;
        }
        i{
            top: ${({dropDownIconStyle}) => dropDownIconStyle.top || '0'};
            display:inline-block;
            position:relative;
            margin-left:8px;
            border-top: 6px solid black;
            border-bottom: 6px solid transparent;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            ${({dropDownIconStyle}) => dropDownIconStyle.width && css`
                border-top: ${dropDownIconStyle.width} solid black;
                border-bottom: ${dropDownIconStyle.width}  solid transparent;
                border-right: ${dropDownIconStyle.width}  solid transparent;
                border-left: ${dropDownIconStyle.width}  solid transparent;
            `};
        }
`;

export const UserIcon = ({
    letter, 
    userName, 
    letterIconStyle={},
    dropDownIconStyle ={},
    dropDownIcon=true,
    parentMargin
}) => {
    return (
        <IconStyle 
            letterIconStyle = {letterIconStyle} 
            dropDownIconStyle={dropDownIconStyle} 
            parentMargin = {parentMargin}
        >
            <span>{letter ? letter : userName.charAt(0).toUpperCase()}</span>  
            {userName ? <span>{userName}</span>:null}
            {dropDownIcon && <i />}
        </IconStyle>
    )
}