import React, { Fragment,useEffect,useState } from "react";
import { Title } from "./outlet.styles";
import {
  blue300,
  black700,
  borderLight,
  black600,
  green100,
} from "../../../theme/colors";
import { xsBold } from "../../../theme/typeface";
import styled from "styled-components";
import moment from "moment";
import {triggerSimpleAjax} from "../../../helpers/httpHelper";
import {GET_OUTLETS_DETAILS, GET_QC_HISTORY} from "../../../helpers/constants/apiUrl";
import themeSizes from "../../../theme/themeSizes";

const UserInititals = styled.span`
  width: 46px;
  height: 46px;
  font-weight: ${xsBold};
  font-size: 18px;
  line-height: 150%;
  color: ${black700};
  background: ${(props) => props.bgColor || "rgba(226, 58, 58, 0.2)"};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
`;

const RowWrapper = styled.div`
  padding: 18px 24px 12px 0;
  display: flex;
  justify-content: space-between;
  color: ${black600};
  > div {
    display: flex;
    align-items: center;
    width:80%;
    @media ${themeSizes.mobile} {
      width:100% !important;
    }
    p {
      margin-left: 24px;
      font-size: 18px;
      line-height: 22px;
      width:calc(70% - 46px);
    }
  }
  p {
    font-size: 16px;
    line-height: 19px;
    width:20%;
  }
  border-bottom: 1px solid ${borderLight};
`;



const bgColor = ["rgba(226, 58, 58, 0.2)", "rgba(91, 127, 220, 0.2)", green100];

export const QCEditHistory = ({ logs, state,related_workflow_object}) => {
  const [qResponse, setQResponse] = useState({})
console.log("state1",state)
console.log("state2",state?.outletDetails?.related_workflow_object)
  function refresh_qc_data () {
    triggerSimpleAjax(
      `${GET_QC_HISTORY}${state?.outletDetails?.related_workflow_object?.id}`,
      'GET',
      {},
      (response) => {
       setQResponse(response)
        // window.location.reload()
      },
      (err) => {
        console.log(err)
      },
    )
  }
  useEffect(() => {
    refresh_qc_data();
  }, []);
  useEffect(() => {
  }, [qResponse]);
  return (
    <Fragment>
      <Title titleColor={blue300} titleFontWeight={xsBold}>
        Qc Edit History
        {/* <button
          style={{
            padding: '5px',
            border: "none",
            height: '20px',
            background: 'transparent',
          }}
          onClick={refresh_qc_data}
        >
          <img
            src="https://www.freeiconspng.com/uploads/arrow-refresh-reload-icon-30.png"
            height={"20px"}
            alt="Arrow Refresh Reload Icon"
            style={{padding: '5px'}}
          />
        </button> */}
      </Title>
      {(qResponse?.data || []).length ? (
        qResponse?.data.map((log, index) => {
          const letters = log.message.slice(0, 2);
          const upadateDateAndTime = log.created
            ? moment(log.created).format("DD-MMM-YYYY hh:mm").split(" ")
            : "";
          return (
            <RowWrapper key={`${index}-${letters}`}>
              <div>
                <UserInititals bgColor={bgColor[index]}>
                  {letters.toUpperCase()}
                </UserInititals>
                <p>{(log.message||"").replace(/_/g,' ')}</p>
              </div>
              <p>
                {upadateDateAndTime[0]} | {upadateDateAndTime.slice(1)}
              </p>
            </RowWrapper>
          );
        })
      ) : (
        <p>No Data Available</p>
      )}
    </Fragment>
  );
};
