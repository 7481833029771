import React from "react";
import styled from "styled-components";
import { miniCaption } from "../../../theme/typography";
import { blue300, black400 } from "../../../theme/colors";
import { UserIcon } from "../../../components/UserIcon";
import capitializeFirstChar from "../../../helpers/utils/capitializeFirstChar";

const NotificationText = styled.p`
    display:block;
    width:100%;
    margin:0;
    p:first-child{
        ${miniCaption.bold}
    }
    p{
        ${miniCaption.regular}
        margin:0;
    }
    a{
        ${miniCaption.semiBold}
        display:block;
        text-align:center;
        color : ${blue300};
    }
`;
const DateTime = styled.div`
  ${miniCaption.regular}
  color : ${black400};
  margin-top:8px;
  display:flex;
  justify-content:space-between;
`;

const NotificationContent = ({ list, viewAllText, onClickAction }) => {
  if (viewAllText) {
    return (
      <NotificationText>
        <a onClick={(e) => {
          e && e.preventDefault() && e.stopPropagation() && e.stopImmediatePropagation();
          onClickAction && onClickAction(e)
        }}>View All</a>
      </NotificationText>
    )
  }
  return (
    <NotificationText>
      <p>{capitializeFirstChar((list.title || '').split('-').join(' '))}</p>
      <p>{list.subText}</p>
      <DateTime>
        <span>{list.date}</span>
        <span>{list.time}</span>
      </DateTime>
    </NotificationText>
  )
}

export const getNotificationOption = (notificationList, onClickAction) => {
  const list = notificationList.map((list, index) => {
    return {
      label: <NotificationContent list={list} />,
      icon: <UserIcon
        letter={list.title.charAt(0).toUpperCase()}
        dropDownIcon={false}
        parentMargin='0'
        letterIconStyle={{ margin: "0 8px 0 0", background: blue300, paddingTop: '3px' }}
      />,
      key: index
    }
  })
  list.push({
    label: <NotificationContent viewAllText onClickAction={onClickAction} />,
    icon: '',
    key: ''
  })
  return list
}