import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { white, green200 } from "../theme/colors";

export const Switch = styled.label`
  display: inline-block;
  width: 45px;
  height: 24px;
  position: relative;
  margin: ${(props) => props.margin || "0"};

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 100%;
    background-color: #a5adbb;
    transition: all 0.2s;
    border-radius: 22px;

    &:before {
      content: "";
      position: absolute;
      left: 5px;
      top: 4px;
      height: 16px;
      width: 16px;
      background-color: ${white};
      border-radius: 100%;
      transition: all 0.2s;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: #2cae66;
      ${props => props.disabled && css`
      background-color:${green200};
      `}
    }

    &:checked + .slider:before {
      transform: translateX(19px);
      border-color: #2cae66;
    }
  }
  :after{
    ${props => props.disabled && css`
      content:' ';
      cursor:not-allowed;
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
      right:0;
    `}
  }
`;

export const SwitchToggle = React.memo(({ active, onToggle, margin, name ,disabled=false}) => {
  return (
    <Switch margin={margin} disabled={disabled}>
      <input type="checkbox" checked={active} onChange={ !disabled ? onToggle : ()=>{}} name={name} />
      <span className="slider" />
    </Switch>
  );
});

SwitchToggle.propTypes = {
  active: PropTypes.bool,
  onToggle: PropTypes.func,
};
