// CORE
import React from 'react';
import styled from 'styled-components';

// COMPONENTS
// import { ToolTip } from '../../../components/ToolTip';

// STYLES
import { black400 } from '../../../theme/colors';
import { defaultFont } from '../../../theme/typography';

// ICONS
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';

export const FooterStyle = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 32px;
    margin: 0 64px;
    width: calc(100% - 128px);

    p {
        ${defaultFont}
        width: 100%;
        color: ${black400};
        a {
            color: #5ba4e5;
        }
    }

    svg {
        width: 43px;
        height: 43px;
        align-content: flex-end;
    }
`;

const Footer = () => {
    return <FooterStyle>
        <p>
            Copyright. Retail Scan Management Services Pvt Ltd 2020{' '}<br />
            <a>Terms and conditions</a> & <a>Privacy Policy</a>
        </p>
        {/* <ToolTip icon={<ContactSupportIcon />} content={"FAQ"} align={"top center"} /> */}
    </FooterStyle>
}

export default Footer;