import React, { useState, useEffect, Fragment } from "react";
import ImageZoom from "../../../components/ImageZoom";
import getOutletGroupDetails from "../../../actions/outlet/getOutletGroupDetails";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FormRow } from "../components/FormRow";
import { DashboardTextBox } from "../components/DashboardTexBox";
import {
  VerticalNavItem,
  VerticalNav,
  PrevArrow,
  NextArrow,
  ContentWrapper,
  Title,
} from "./outlet.styles";
import Loader from "../../../components/Loader";
import {
  black100,
  black300,
  green300,
  black400,
  cinnabar300,
} from "../../../theme/colors";
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import {
  GET_OUTLET_GROUP,
  GET_OUTLET_GROUP_SAVE,
} from "../../../helpers/constants/apiUrl";
import CheckBox from "../../../components/ui/CheckBox";
import styled, { css } from "styled-components";
import { Button } from "../../../components/Button";
import _cloneDeep from "lodash/cloneDeep";
import { outletQuestionGroupSaveAction } from "./helper/outletQuestionGroupSaveAction";

const sliderRef = React.createRef();
const NextArrowWrapper = () => (
  <NextArrow
    onClick={() => {
      sliderRef.current.slickNext();
    }}
  >
    <ArrowForwardIosIcon />
  </NextArrow>
);
const PrevArrowWrapper = () => (
  <PrevArrow
    onClick={() => {
      sliderRef.current.slickPrev();
    }}
  >
    <ArrowBackIosIcon />
  </PrevArrow>
);

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  nextArrow: <NextArrowWrapper />,
  prevArrow:<PrevArrowWrapper />
};

const RightSideWrapper = styled.div`
  width: 60%;
  display: flex;
  > span:first-child {
    display: inline-block;
    padding-top: 12px;
    width: 40%;
    ${(props) =>
      props.textColorRed &&
      css`
        color: ${cinnabar300};
      `}
  }
  > div {
    display: flex;
    label {
      align-self: flex-start;
      margin-top: 16px;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82%;
`;

const QuestionRow = ({
  answerDetails,
  answerText,
  is_calling_necessary,
  name,
  setOutletGroupDetails,
  questionId,
  outletGroupDetails,
  questionIndex,
  setErrorDetails,
  errorDetails,
}) => {
  const { has_called_and_verified, remarks, id: answerId } = answerDetails;
  const [isVerified, setVerified] = useState(has_called_and_verified);
  const [reason, setReason] = useState({ selected: remarks, text: remarks });

  const onChangeAction = ({ key, value }) => {
    const otherKey = key === "remarks" ? "has_called_and_verified" : "remarks";
    //assign opposite value
    const otherKeyValue =
      value === true || value === false
        ? value
          ? null
          : ""
        : value === null
        ? true
        : false;

    outletGroupDetails[questionIndex]["related_answers"][0][key] = value;
    outletGroupDetails[questionIndex]["related_answers"][0][
      otherKey
    ] = otherKeyValue;
    outletGroupDetails[questionIndex]["related_answers"][0].is_edited = true
    setOutletGroupDetails(Object.assign([], outletGroupDetails));
  };

  return (
    <FormRow
      name={name}
      leftSideWidth={"20%"}
      rightElement={
        <RightSideWrapper textColorRed={(remarks || "").length}>
          <span>{answerText}</span>
          {is_calling_necessary ? (
            <div>
              <CheckBox
                name={""}
                checked={has_called_and_verified}
                label={""}
                onChange={(event) => {
                  // outletGroupDetails[questionIndex]["related_answers"][0][has_called_and_verified] = event.target.checked;
                  onChangeAction({
                    key: "has_called_and_verified",
                    value: event.target.checked,
                  });
                }}
              />
              <CheckBox
                name={""}
                checked={true}
                label={""}
                isTick={false}
                background={remarks !== null ? "#D30D0D" : black300}
                onChange={(event) => {
                  onChangeAction({
                    key: "remarks",
                    value: remarks !== null ? null : "",
                  });
                }}
              />
              {remarks !== null ? (
                <DashboardTextBox
                  boxWidth={"240px"}
                  boxPadding="10px 16px"
                  type="text"
                  value={remarks}
                  onChange={(event) =>
                    onChangeAction({
                      key: "remarks",
                      value: event.target.value,
                    })
                  }
                  placeholder="Reason"
                  warnMessage={
                    errorDetails[answerId]
                      ? {
                          text: errorDetails[answerId]["remarks"][0],
                        }
                      : {}
                  }
                />
              ) : null}
            </div>
          ) : null}
        </RightSideWrapper>
      }
    />
  );
};

export const OutletCallingScreen = ({
  question_groups,
  outletDetails,
  sub_project,
  setSaveClickedData,
  saveClickedData,
}) => {
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const [outletGroupDetails, setOutletGroupDetails] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [outletGroupDetailsCopy, setOutletGroupDetailsCopy] = useState([]);
  const [errorDetails, setErrorDetails] = useState({});

  const getGroupDetails = (index) => {
    const data =
      outletDetails.question_groups && outletDetails.question_groups[index];
    if (data) {
      const req = {
        workflow_object: outletDetails.workflow_object,
        question_group: data.id,
      };

      getOutletGroupDetails(req).then((res) => {
        setOutletGroupDetails(res.results);
        setOutletGroupDetailsCopy(_cloneDeep(res.results));
        setLoader(false);

        if (
          !(res.results || []).length ||
          !(res.results || []).filter(
            (question) => !(question.type || "").includes("image")
          ).length
        ) {
          setSaveClickedData({
            ...saveClickedData,
            [activeGroupIndex]: true,
          });
        }
      });
    } else {
      setLoader(false);
      setOutletGroupDetails([]);
    }
  };

  const onSaveAction = () => {
    outletQuestionGroupSaveAction({
      outletGroupDetails,
      setRequestInProcess,
      setSaveClickedData,
      activeGroupIndex,
      saveClickedData,
      question_groups,
      setOutletGroupDetails,
      setOutletGroupDetailsCopy,
      setActiveGroupIndex,
      setErrorDetails,
    });
  };

  useEffect(() => {
    setLoader(true);
    getGroupDetails(activeGroupIndex);
  }, [activeGroupIndex]);

  const isAllQuestionAnswered = outletGroupDetails.filter((question) => {
    if (question.is_calling_necessary) {
      const [answerDetails] = question["related_answers"];
      return answerDetails.has_called_and_verified || answerDetails.remarks;
    }
    return true;
  }).length
    ? true
    : false;

  const questionRowsWithoutImage = (outletGroupDetails || []).reduce(
    (questionArray, question, index) => {
      const {
        name,
        reference_images,
        related_answers,
        type,
        is_calling_necessary,
        id,
        answer_type,
      } = question || {};
      const answerDetails = (related_answers || [])[0];

      if (!["multiple_image", "single_image"].includes(type)) {
        let answerText;
        if ((type || answer_type).includes("location")) {
          answerText = `${answerDetails.latitude_input},${answerDetails.longitude_input}`;
        } else {
          answerText = answerDetails.single_input;
        }

        let element = (
          <QuestionRow
            answerText={answerText}
            answerDetails={answerDetails}
            is_calling_necessary={is_calling_necessary}
            name={name}
            questionId={id}
            setOutletGroupDetails={setOutletGroupDetails}
            outletGroupDetails={outletGroupDetails}
            questionIndex={index}
            setErrorDetails={setErrorDetails}
            errorDetails={errorDetails}
          />
        );
        questionArray.push(element);

        //  Additional Elements in Account Number verification and so
        const additionalElement = (name, key) =>  (
          <QuestionRow
            answerText={answerDetails[key]}
            answerDetails={answerDetails}
            is_calling_necessary={is_calling_necessary}
            name={name}
            questionId={id}
            setOutletGroupDetails={setOutletGroupDetails}
            outletGroupDetails={outletGroupDetails}
            questionIndex={index}
            setErrorDetails={setErrorDetails}
            errorDetails={errorDetails}
          />
        )

        if(type === "account_number"){
          const additionalDetails = [
            {
              name: "IFSC Code",
              key: "other_ifsc_code"
            },
            {
              name: "Bank Name",
              key: "other_bank_name"
            },
            {
              name: "Branch Name",
              key: "other_branch_name"
            },
            {
              name: "Phone Number",
              key: "other_phone_number"
            },
            {
              name: "Account Holder Name",
              key: "other_account_holder_name"
            }
          ]
          additionalDetails.map(ele => {
            questionArray.push(additionalElement(ele.name, ele.key))
          })
        }
      }
      return questionArray;
    },
    []
  );
  return (
    <Fragment>
      <Title>{sub_project.name}</Title>
      {question_groups || [].length ? (
        <VerticalNav>
          <Slider {...settings} ref={sliderRef}>
            {question_groups.map((group, index) => (
              <VerticalNavItem
                className={index === activeGroupIndex ? "active" : ""}
                onClick={() => setActiveGroupIndex(index)}
              >
                <span>{group.name}</span>
                {(saveClickedData || {}).hasOwnProperty(index) ? <i /> : null}
              </VerticalNavItem>
            ))}
          </Slider>
        </VerticalNav>
      ) : null}
      {isLoading ? (
        <Loader />
      ) : (
        <ContentWrapper>
          <div>
            {questionRowsWithoutImage}
            {(questionRowsWithoutImage || []).length ? (
              <ButtonWrapper>
                <Button
                  size="small"
                  noBorder={true}
                  margin="12px"
                  bgColor="transparent"
                  color={black400}
                  onClick={() => setOutletGroupDetails(outletGroupDetailsCopy)}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  type='submit'
                  bgColor={green300}
                  isLoading={requestInProcess}
                  onClick={requestInProcess ? null : onSaveAction}
                  isDisabled={!isAllQuestionAnswered}
                >
                  {activeGroupIndex === question_groups.length - 1
                    ? "Save"
                    : "Save & Next"}
                </Button>
              </ButtonWrapper>
            ) : null}
            <p>
              {!(questionRowsWithoutImage || []).length
                ? "No Data available"
                : ""}
            </p>
          </div>
        </ContentWrapper>
      )}
    </Fragment>
  );
};
