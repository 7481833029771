// CORE
import React from 'react';
import styled from 'styled-components';

// COMPONENTS
// import { ToolTip } from '../../../components/ToolTip';

// STYLES
import { black400 } from '../../../theme/colors';
import { defaultFont, smallFont } from '../../../theme/typography';
import LoginLogo from "../../dashboard/retail-hub-logo.png"
// ICONS
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';

export const sectionSpace = styled.div`
   
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    margin: 0 64px; 
    width: calc(100% - 128px);

    p {
        ${(props) => (props.textStyle!==null ? smallFont : defaultFont)};
        width: 100%;
        color: ${black400};
    a {
            color: #5ba4e5;
            ${(props) => (props.textStyle !==null ? smallFont : defaultFont)};
        }
    }

    svg {
        width: 43px;
        height: 43px;
        align-content: flex-end;
    }
    
`;
export const FooterStyle = styled.div`
display: flex;
flex-direction: row;
margin-bottom: 2%;


`;

const FooterMobile = () => {
    return  <FooterStyle>
        <sectionSpace>
         <img src={LoginLogo} alt='logo' height="45px" width="160px" style={{marginBottom:"85px",display:"block",marginLeft:"auto",marginRight:"auto"}}/>             
        <p>
            Copyright. Retail Scan Management Services Pvt Ltd 2020{' '}<br />
            <a style={{color: "#5ba4e5"}}>Terms and conditions</a> & <a style={{color: "#5ba4e5"}}>Privacy Policy</a>
        </p>
        </sectionSpace>
        {/* <ToolTip icon={<ContactSupportIcon />} content={"FAQ"} align={"top center"} /> */}
    </FooterStyle>
}

export default FooterMobile;