import { NOTIFICATION } from "../helpers/constants/actions";

export default function triggerNotification({
    messageText = '',
    status = 'SUCCESS'
}) {
    return dispatch =>
        new Promise((res, rej) => {
            dispatch({
                type: NOTIFICATION,
                payload: {
                    messageText,
                    status
                }
            })
        });
}
