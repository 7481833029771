export const sizes = {
  mobile: 500,
  tablet: 995,
}

const themeSizes = {
  mobile: `(max-width: ${sizes.mobile}px)`,
  tablet: `(max-width: ${sizes.tablet}px)`
}

export default themeSizes
