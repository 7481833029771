import React, {Component} from "react";
import {StyledTable, TBODY, TD, THD, THEAD, TR} from "../../../components/table/table";
import InfoIcon from "@material-ui/icons/Info";
import ReactTooltip from "react-tooltip";
import styled from "styled-components"
import {blue300, borderLight, curiousBlue300, grey100, white} from "../../../theme/colors";

const InnerBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
    //background: ${grey100};

  & > div {
    flex: 50%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 20px 0;
  }
`

export default class ScoreDetailV2 extends Component {

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    const {scoring_parameter_groups_data} = this.props;
    const infoIconStyles = {
      marginLeft: "1rem",
      fontSize: "18px"
    }

    const InfoText = (prefix, title, index, help_text) => {
      return (
        <>
          <InfoIcon style={infoIconStyles} data-tip
                    data-for={`${prefix}-${title}-${index}`}/>
          <ReactTooltip id={`${prefix}-${title}-${index}`}>
            {help_text || "No Info"}
          </ReactTooltip>
        </>

      )
    }

    return (
      <div style={{
        paddingBottom: "100px",
        display: "flex",
        flexWrap: "wrap"
      }}>
        {scoring_parameter_groups_data.map((scoring_data, scoring_index) => (
          <div style={{
            flexBasis: "50%"
          }}>
            <h3>{scoring_data.title} ({scoring_data.score}/{scoring_data.max_score})</h3>
            <StyledTable style={{
              flexBasis: "50%",
              padding: "0"
            }}>
              <THEAD style={{
                borderBottom: "none"
              }}>
                <THD
                  width="400px"
                  justify="space-between"
                  style={{
                    background: blue300,
                    color: white
                  }}
                >
                  {scoring_data.title}
                  {scoring_data.client_help_text && (
                    <InfoText
                      prefix="heading-tooltip"
                      title={scoring_data.title}
                      index={scoring_index}
                      help_text={scoring_data.client_help_text}
                    />
                  )}

                </THD>
                <THD
                  width="50px"
                  justify="center"
                  style={{
                    background: blue300,
                    color: white,
                  }}>
                  Score
                </THD>
                <THD
                  width="50px"
                  justify="center"
                  style={{
                    background: blue300,
                    color: white
                  }}>
                  %age
                </THD>
              </THEAD>
              <TBODY>
                {scoring_data.calculated_parameter_groups_data.map((calculated_param_group_data, calculated_param_group_index) => (
                  <TR
                    height="auto"
                    style={{
                      borderBottom: `1px solid ${borderLight}`,
                      borderLeft: `1px solid ${borderLight}`,
                      width: "150px"
                    }}
                  >
                    <TD
                      width="150px"
                      justify="space-between"
                      style={{
                        border: "none"
                      }}
                    >
                      {calculated_param_group_data.title}
                      {calculated_param_group_data.client_help_text && (
                        <InfoText
                          prefix="calculated-param-group-tooltip"
                          title={calculated_param_group_data.title}
                          index={calculated_param_group_index}
                          help_text={calculated_param_group_data.client_help_text}
                        />
                      )}
                    </TD>
                    <TD width="350px" noPadding height="auto" flexFlow="column">
                      {calculated_param_group_data.calculated_parameter_sub_groups_data.map((calculated_param_data, calculated_param_index) => (
                        <TR height="auto">
                          <TD
                            width="250px"
                            hasBorder
                          >
                            {calculated_param_data.title}
                            {calculated_param_data.client_help_text && (
                              <InfoText
                                prefix="calculated-param-group-tooltip"
                                title={calculated_param_data.title}
                                index={calculated_param_index}
                                help_text={calculated_param_data.client_help_text}
                              />
                            )}
                          </TD>
                          <TD width="50px" hasBorder>
                            {calculated_param_data.score}
                          </TD>
                          <TD width="50px" hasBorder>
                            {calculated_param_data.percentage}
                          </TD>
                        </TR>
                      ))}
                    </TD>
                  </TR>
                ))}
              </TBODY>
            </StyledTable>
          </div>

        ))}
      </div>
    )
  }
}

// Prev Code
// {scoring_parameter_groups_data.map(data => {
//   return (
//
//     <CollapsibleBar
//       titleProps={{
//         value: data.title,
//         placeholder: "Enter a name",
//         disabled: true
//       }}
//       displayTypeField={false}
//       outerCollapse={true}
//       slug={data.title}
//       editOnNameClick={false}
//       infoToolTip={data.client_help_text}
//       showDelete={false}
//       headerText=""
//     >
//       <InnerBlock>
//         {data.calculated_parameter_groups_data.length !== 0 ?
//           data.calculated_parameter_groups_data.map((calculated_param, index) => (
//             <>
//               <StyledTable width="auto">
//                 <THEAD>
//                   <THD width="250px" noBorder  justify="space-between" >
//                     {calculated_param.title}
//                     {calculated_param.client_help_text && (
//                       <>
//                         <InfoIcon style={infoIconStyles} data-tip data-for={`heading-tooltip-${calculated_param.title}-${index}`} />
//                         <ReactTooltip id={`heading-tooltip-${calculated_param.title}-${index}`}>
//                           {calculated_param.client_help_text || "No Info"}
//                         </ReactTooltip>
//                       </>
//                     )}
//
//                   </THD>
//                   <THD width="100px" noBorder>
//                     {calculated_param.score}
//                   </THD>
//                 </THEAD>
//                 <TBODY>
//                   {calculated_param.calculated_parameter_sub_groups_data.map((sub_group,index) => (
//                     <>
//                       <TR>
//                         <TD  width="250px" hasBorder justify="space-between">
//                           {sub_group.title}
//                           {sub_group.client_help_text && (
//                             <>
//                               <InfoIcon style={infoIconStyles} data-tip data-for={`tooltip-${sub_group.title}-${index}`} />
//                               <ReactTooltip id={`tooltip-${sub_group.title}-${index}`}>
//                                 {sub_group.client_help_text}
//                               </ReactTooltip>
//                             </>
//                           )}
//
//                         </TD>
//                         <TD width="100px" hasBorder >
//                           {sub_group.score}
//                         </TD>
//                       </TR>
//                     </>
//                   ))}
//
//                 </TBODY>
//               </StyledTable>
//             </>
//           )): <p style={{
//             paddingLeft: "1.5rem",
//             textAlign: "center"
//           }}> No Data Available </p>}
//       </InnerBlock>
//
//
//     </CollapsibleBar>
//   )
// })}
