import { triggerSimpleAjax } from '../../helpers/httpHelper';
import {  GET_OUTLETS } from '../../helpers/constants/apiUrl';

export default function getOutlets(req = '',workflowBased) {
    
    if(req.includes('?show_only_special_action_states=false'))
    {
        req = req.replace('?show_only_special_action_states=false','')
    }
    else if(req.includes('&show_only_special_action_states=false'))
    {
        req = req.replace('&show_only_special_action_states=false','')
    }
    const reqString = workflowBased ? `?workflow_based=true${req.replace('?','&')}` : req  
  
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            `${GET_OUTLETS}${reqString}&include_temp=yes`,
            'GET',
            {},
            (response) => {
                res(response);
            },
            error => {
                rej(error);
            }
        );
    });
}