const testWhite = (x) => {
    var white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
};
  
const trimString = (str, maxLen) => {
    if (str.length <= maxLen) {
      return str;
    }
    var trimmed = str.substr(0, maxLen);
    return trimmed.substr(0, trimmed.lastIndexOf(' ')) + '…';
}

const wordWrap = (str, maxWidth) => {
    var newLineStr = "\n"; let done = false; let res = [];
    while (str.length > maxWidth) {
      let found = false;
      // Inserts new line at first whitespace of the line
      for (let i = maxWidth - 1; i >= 0; i--) {
        if (testWhite(str.charAt(i))) {
          res.push([str.slice(0, i), newLineStr].join(''));
          str = str.slice(i + 1);
          found = true;
          break;
        }
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
        res.push([str.slice(0, maxWidth), newLineStr].join(''));
        str = str.slice(maxWidth);
      }
    }
    res.push(str);
    if (res.length <= 3) {
      return res
    } else {
      let trimStr = res.splice(0, 2);
      trimStr.push(trimString(res.join('').replace('\n', ''), 5));
      return trimStr;
    }
}

export default wordWrap;
