import React from 'react'
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App'
import rootReducer from './reducers';
import { LastLocationProvider } from 'react-router-last-location';
import styled from "styled-components";

const store = createStore(rootReducer, {}, applyMiddleware(thunk));

const UnsecuredPageWarning = styled.h1`
      color: red;
    `;

const Link = styled.a`
  text-decoration: none;
  color: red;
`;

const UnsecuredPage = () => (
  <div>
    <UnsecuredPageWarning>If you see this page, Webb App link you have clicked on is under Clickjacking security attack.</UnsecuredPageWarning>
    <h2>Please inform team with the reference of the application from where you clicked this link.</h2>
    <h2>Click <Link href={window.self.location.href} title='Web Application' target='blank'>here</Link> to access WebApp safely.</h2>
  </div>
);

export function getStore() {
  return store;
}

const AppBundle = (
  <Provider store={store}>
    <BrowserRouter>
      <LastLocationProvider>
        <App />
      </LastLocationProvider>
    </BrowserRouter>
  </Provider>
);
if(window.self === window.top){
  ReactDOM.render(AppBundle, document.getElementById('root'));
} else {
  ReactDOM.render(<UnsecuredPage />, document.getElementById('root'));
}

