import React from "react";
import styled from "styled-components";
import { font, defaultFont } from "../../../theme/typography";
import { black300 } from "../../../theme/colors";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { Modal } from "../../../components/Modal";
import { ActionButton } from "../organization-roles-users/roles/role-styles";

const ContentStyle = styled.div`
  margin-top: 30px;
  b {
    font-size: ${font.smallmedium};
    line-height: 125%;
  }
  p {
    width: 263px;
    margin: 8px auto 32px;
  }
  ${defaultFont}
  text-align:center;
`;

export const ConfirmationModal = ({
  open,
  noAction,
  yesAction,
  confirmationText = `You have unsaved changes. 
    Are you sure you want to leave this page?`,
  proceedText = '',
  closeAction=()=>{},
  actionText = 'Yes',
  cancelText =  'No',
  headerText = 'Confirmation',
  customElement = null
}) => {
  if (!open) {
    return null;
  }
  return (
    <Modal withHeader modalWidth="30%" closeIconColor={black300} closeAction={closeAction}>
      <ContentStyle>
        <b>{headerText}</b>
        <p>{confirmationText}</p>
        <p>{proceedText}</p>
        {customElement}
        {noAction ? <ActionButton onClick={noAction}>
          <CloseIcon />
          {cancelText}
        </ActionButton> : null}
        {yesAction ? <ActionButton active={true} onClick={yesAction}>
          <DoneIcon />
          {actionText}
        </ActionButton> : null}
      </ContentStyle>
    </Modal>
  );
};
