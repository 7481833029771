import React from "react";
import { Title } from "./outlet.styles";
import styled from "styled-components";
import {
  microHeading,
  defaultFont,
  regularHeading,
  font,
} from "../../../theme/typography";
import {
  blue300,
  black600,
  curiousBlue300,
  green300,
  white,
} from "../../../theme/colors";
import { xsBold, bold, semiBold } from "../../../theme/typeface";
import DateToDisplay from "../../../helpers/utils/DateToDisplay";
import { FormRow } from "../components/FormRow";
import { Button } from "../../../components/Button";
import SmsIcon from "@material-ui/icons/Sms";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const Wrapper = styled.div`
  width: 50%;
`;

const TitleWrapper = styled(Title)`
    ${microHeading.regular}
    color:${blue300};
    height:auto;
    margin: 48px 0 24px;
    font-weight:${xsBold};
`;

const Description = styled.p`
    ${defaultFont}
    color:${black600};
    font-weight:${semiBold};
    margin-bottom:20px;
    
`;

const DetailsTitle = styled(Title)`
    ${regularHeading.mini}
    font-weight:${xsBold};
    height:auto;
    color:${black600};
`;

// const LisWrapper = styled.ul`
//     list-style: none;
//     position:relative;
//     padding-left:0;
//     margin:20px 10px 43px;
//     li{
//         ${defaultFont}
//         font-weight:${semiBold};
//         margin-bottom:12px;
//         padding-left:30px;
//         position:relative;
//     }
//     li::before {
//         content: "•";
//         color: ${curiousBlue300};
//         position: absolute;
//         left: 0;
//         top:-3px;
//         font-size:${font.medium};
//     }
// `;

const DateRangeContainer = styled.div`
    box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.16);
    border:1px solid ${curiousBlue300};
    border-radius:4px;
    padding: 12px;
    ${defaultFont}
    font-weight:${semiBold};
    color:${blue300};
    text-align:center;
`;

const AnswerText = styled.div`
  padding-top: 12px;
`;

export const KYCUpdate = ({ outletDetails = {},triggerNotification }) => {
  const { payment_date, bank, payment_status, account_holder_name, account_number, ifsc_code, branch} =
    outletDetails.related_workflow_object || {};
 
    const onActionButtonClick = ( ) => {
        triggerNotification({
            messageText: `Sent Successfully !!`,
          status: 'SUCCESS'
        })
    }
  return (
    <Wrapper>
      <TitleWrapper>
        Please guide the Retailer to Generate Invoice.
      </TitleWrapper>
      <DetailsTitle>
        Their Payment {payment_status.includes('success') ? 'success' : 'failed' } on {<DateToDisplay date={payment_date} />}
      </DetailsTitle>
      <FormRow
        name={"Account No"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{account_number}</AnswerText>}
      />
      <FormRow
        name={"Bank Name"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{bank}</AnswerText>}
      />
      <FormRow
        name={"Branch"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{branch}</AnswerText>}
      />
      <FormRow
        name={"IFSC"}
        leftSideWidth={"30%"}
        rightElement={<AnswerText>{ifsc_code}</AnswerText>}
      />
      <Description>Trigger SMS/Whatsapp message with link to the retailer app</Description>
      <FormRow
        name={""}
        leftSideWidth={"0"}
        rightElement={
          <div>
            <Button
              size="medium"
              bgColor={blue300}
              color={white}
              onClick={() =>
                onActionButtonClick()
              }
            >
              <SmsIcon />
              SMS
            </Button>
            <Button
              size="medium"
              bgColor={green300}
              color={white}
              onClick={() =>
                onActionButtonClick()
              }
            >
              <WhatsAppIcon />
              Whatsapp
            </Button>
          </div>
        }
      />
    </Wrapper>
  );
};
