import React, { useState, useEffect, Fragment } from "react";
import {
  black300,
  green300,
  black500,
  black600,
  black700,
  cinnabar300,
  white,
  cinnabar100,
  cinnabar400,
  curiousBlue300,
  completed,
  processing,
  accepted,
  cancelled,
} from "../../../../theme/colors";
import { Modal } from "../../../../components/Modal";
import styled, { keyframes, css } from "styled-components";
import { font, baseCaption } from "../../../../theme/typography";
import { Button } from "../../../../components/Button";
import { FormRow } from "../../components/FormRow";
import DatePicker from "../../../../components/DatePicker";
import { DashboardTextBox } from "../../components/DashboardTexBox";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { SelectFilter } from "../../components/SelectFilter";
import {
  GET_OUTLETS_ACTIONS_SAVE,
} from "../../../../helpers/constants/apiUrl";
import { triggerSimpleAjax } from "../../../../helpers/httpHelper";
import ReactTable from "../../../../components/table";
import { StatusTagStyle } from "../../../../components/StatusTagStyle";
import { AnchorText } from "../../../../pages/dashboard/components/ProjectCard";
import CheckBox from "../../../../components/ui/CheckBox";

const ContentStyle = styled.div`
  margin: 12px 20px;
  ${baseCaption}
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  ${(props) =>
    props.flexCenter &&
    css`
      align-items: center;
    `}

  b {
    font-size: ${font.smallmedium};
    line-height: 125%;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: ${black500};
    margin: 8px 0 30px;
  }

  button {
    align-self: ${(props) => (props.buttonAlignCenter ? "center" : "flex-end")};
  }
`;

const AgreeStatementWrapper = styled.b`
  width: 263px;
  margin: auto;
  text-align: center;
`;

const STATUS_STYLE = `
display:flex;
flex-direction:column;
align-items: center;
b{
    margin-top:16px;
    color:${black700};
}
`;
const SuccessWrapper = styled.div`
  ${STATUS_STYLE}
  button {
    margin-top: 32px;
  }
`;
const ErrorWrapper = styled.div`
  ${STATUS_STYLE}
  button {
    margin-top: 20px;
  }
`;

const ErrorTextWrapper = styled.div`
  background: ${cinnabar100};
  border-radius: 2px;
  padding: 12px 16px;
  color: ${cinnabar400};
  margin: 20px 0;

  p {
    margin: 2px 0;
    ${baseCaption}
    color:${cinnabar400};
  }
`;

const StatusWrapper =styled.div`
display: flex; flex-direction: 'row'; justify-content: 'center'; align-items: 'center'
`
const Spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const ProgressLoader = styled.div`
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 50px;
  height: 50px;
  position: relative;
  /* top: 20%; */
  animation: ${Spin} 2s linear infinite;
`;

const RowWrapper = styled.div`
  display: flex;
  > div,
  svg {
    align-self: center;
  }
  svg {
    margin-left: 16px;
  }
`;



const selectFieldProps = {
  noOptionMessage: "No data available",
  customStyles: {
    // height: "48px",
    border: "1px solid #DEDEDE",
    padding: "0 8px",
    borderRadius: "2px",
  },
  containerStyles: {
    width: "424px",
    margin: "8px 16px 22px 0",
  },
  dropdownColor: black600,
};


export const SimilarOutletModal = ({
  title,
  modalAction,
  selectedAction,
  getOutletDetails,
  history,
  modalHeading = "",
  similarOutletData
}) => {
  const [isLoading, setLoading] = useState(null);
  const [processCompleted, setProcessCompleted] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedReason, setSelectedReason] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [selectedDateFields, setSelectedDateField] = useState({});
  const [textData, setTextData] = useState({});
  const [rejected, setRejected] = useState({
    status: false,
    reason: ''
  })
 

  useEffect(() => {
   if(similarOutletData.length){
    setLoading(null)
   }else{
    setLoading(null)
   }
  }, [similarOutletData]);
 
  
  const openRSIDTab=(slug)=>{
 const { pathname } = history.location;
   var stripped = pathname.slice(0,pathname.lastIndexOf('/'))+"/"+slug;
    window.open(`${stripped}`);
  }
 

 
  return (
    <Modal
      displayFlex={modalHeading}
      top={modalHeading ? "0" : '16%'}
      withHeader
      modalWidth="80%"
      modalHeight="60%"
      closeIconColor={black300}
      closeAction={() => {
        if (processCompleted) {
          const { location } = history;
          getOutletDetails();
          modalAction();
        } else {
          modalAction(selectedAction);
        }
      }}
    >
      <ContentStyle
        // buttonAlignCenter={!isExtraDataAvailable}
        flexCenter={isLoading || processCompleted}
      >
        {/* {selectedStatus.noActionsAvailable || !statusOptions.length ? (
          <AgreeStatementWrapper>No Actions Available</AgreeStatementWrapper>
        ) : ( */}
          <Fragment>
            {isLoading === null ? (
              <Fragment>
                <b>{modalHeading || "Similar Outlet Within Sub Project"}</b>
               
              

                <ReactTable
          title=""
          rowHeight={"auto"}
          showPagination={false}
          data={similarOutletData|| []}
          loading={isLoading}
          showTotalRecordText={true}
     
        
          //   loading={isLoading}
          columns={[
            {
              name: "RSID",
              key: "rsid",
              sortable: false,
              width: "30vh",
              render: (value, data) => {
                return (
                 
                  <div>
                    <AnchorText
                      onClick={() => openRSIDTab(data.slug)}
                      target={"_blank"}
                    >
                     {data.rsid}
                    </AnchorText>
                    
                  </div>
                );
              },
            },
            {
              name: "Outlet ID",
              key: "id",
              width: "15vh"
            },
            {
              name: "Outlet Name",
              key: "name",
              width: "35vh"
            },
            {
              name: "Account No.",
              key: "account_number",
              width: "25vh",
              render: (value, data) => {
                if(value){
                  return (
                 
                    <StatusWrapper>
                       <CheckBox
                          checked={true}
                        />
                      <h4>
                        {value}
                      </h4>
                     
                    </StatusWrapper>
                  );
                }else{
                  return(
                    <StatusWrapper>
                   <h4>
                     N/A
                   </h4>
                  
                 </StatusWrapper>
                  )
                }
              },
              
            },
            {
              name: "GST",
              key: "gst_number",
              width: "25vh",
              sortable: false,
              render: (value, data) => {
                if(value){
                  return (
                 
                    <StatusWrapper>
                       <CheckBox
                          checked={true}
                        />
                      <h4>
                        {value}
                      </h4>
                     
                    </StatusWrapper>
                  );
                }else{
                  return(
                    <StatusWrapper>
                   <h4>
                     N/A
                   </h4>
                  
                 </StatusWrapper>
                  )
                }
                
              },
            },  

            {
              name: "PAN",
              key: "pan_number",
              width: "25vh",
              sortable: false,
              render: (value, data) => {
                if(value){
                  return (
                 
                    <StatusWrapper>
                       <CheckBox
                          checked={true}
                        />
                      <h4>
                        {value}
                      </h4>
                     
                    </StatusWrapper>
                  );
                }else{
                  return(
                    <StatusWrapper>
                   <h4>
                     N/A
                   </h4>
                  
                 </StatusWrapper>
                  )
                }
              },
            },  
            {
              name: "Phone Number",
              key: "phone_number",
              width: "30vh",
              render: (value, data) => {
                if(value){
                  return (
                 
                    <StatusWrapper>
                       
                      <h4>
                        {value}
                      </h4>
                     
                    </StatusWrapper>
                  );
                }else{
                  return(
                    <StatusWrapper>
                   <h4>
                     N/A
                   </h4>
                  
                 </StatusWrapper>
                  )
                }
              },
            },
            {
              name: "Status",
              key: "status",
              width: "15vh",
              render: (value, data) => {
                if(value){
                  return (
                 
                    <StatusWrapper>
                       
                      <h4>
                        {value}
                      </h4>
                     
                    </StatusWrapper>
                  );
                }else{
                  return(
                    <StatusWrapper>
                   <h4>
                     N/A
                   </h4>
                  
                 </StatusWrapper>
                  )
                }
              },
            },
          ]}
        />
                
              
              </Fragment>
            ) : null}

            {isLoading  ? (
              <SuccessWrapper>
                <ProgressLoader />
                <b>Processing...</b>
                <p>Fetching Similar Outlet.</p>
              </SuccessWrapper>
            ) : null}
          
          </Fragment>
        {/* )} */}
      </ContentStyle>
    </Modal>
  );
};




