import { triggerSimpleAjax } from '../helpers/httpHelper';
import { GET_USER_INFO } from '../helpers/constants/apiUrl';
import { USER_DETAILS } from '../helpers/constants/actions';

export default function getUserInfo() {
    return dispatch =>
        new Promise((res, rej) => {
            triggerSimpleAjax(
                GET_USER_INFO ,
                'GET',
                {},
                (response) => {
                    const payLoad = {
                        loggedIn: true,
                        user_details: {
                            ...response
                        }
                    };

                    dispatch({
                        type: USER_DETAILS,
                        payload: payLoad
                    });
                    
                    res(payLoad);
                },
                error => {
                    rej(error);

                    localStorage.removeItem('userName');

                    dispatch({
                        type: USER_DETAILS,
                        payload: {
                            loggedIn: false,
                            user_details: {}
                        }
                    });
                }
            );
        });
}