import { createGlobalStyle } from "styled-components";
import themeSizes from "./theme/themeSizes";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  *,
  :before,
  *:after {
    box-sizing: border-box;
  }

  body {
    font-family: 'Barlow', sans-serif;
    font-size: 13px;
    line-height: 1.33333;
    color: #333;
    #launcher-frame{
      bottom:-12px !important;
    }
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
    color: #333;
  }

  .iTowfl{
    @media ${themeSizes.mobile} {
      flex-direction:column;
    }
    h3{
      width:100%;
    }
  }

  .kFCxPO{
    @media ${themeSizes.mobile} {
      flex-direction:column;
    }
    h3{
      width:100%;
    }
  }

  .cIpPCH{
    @media ${themeSizes.mobile} {
      width:100%;
    }
  }

  .koSGlg{
    @media ${themeSizes.mobile} {
      justify-content:flex-start;
      width:100%
    }
  }

  .sc-fznKkj{
    @media ${themeSizes.tablet} {
      width:100% !important;
    }
  }

`;

export default GlobalStyle;
