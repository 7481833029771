import React, { Component } from "react";
import styled from "styled-components";
import { font, lineHeight } from "../../../theme/typography";
import { ImageMobileStyle } from "../../../theme/commonStyles";
import LeftSide from "./left-side.png"

const LeftContainerStyle = styled.section`
  text-align: center;
  img {
    width: 500px;
    height: 400px;
  }
  p {
    color: #ffffff;
    font-weight: bold;
    font-size: ${font.medium};
    line-height: ${lineHeight.medium};
    b {
      border-right: 4px solid #ffffff;
      padding-right: 6px;
      margin-right: 2px;
    }
  }
`;
export default class LeftSideContainer extends Component {
  textType = (el, toRotate, period) => {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = "So";
    this.tick();
    this.isDeleting = false;
  };

  tick = () => {
    const fullTxt = this.toRotate[this.loopNum % this.toRotate.length];
    let delta = 200 - Math.random() * 100;
    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }
    this.el.innerHTML = "<span>" + this.txt + "</span>";
    if (this.isDeleting) {
      delta /= 2;
    }
    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }
    setTimeout(() => {
      this.tick();
    }, delta);
  };
  componentDidMount() {
    const elements = document.getElementsByClassName("typewrite");
    const count = elements.length;
    for (let i = 0; i < count; i++) {
      const toRotate = elements[i].getAttribute("data-type");
      if (toRotate) {
        this.textType(
          elements[i],
          JSON.parse(toRotate),
          elements[i].getAttribute("data-period")
        );
      }
    }
  }

  render() {
    let header;
    let footer;

    if (this.props.check === "EKYC") {
      header = "India’s first Customised";
      footer = "In-Store Solutions Company";
    } else {
      header = "India’s first Customised In-Store";
      footer = "Company";
    }

    return (
      <LeftContainerStyle>
        <img
          src={LeftSide}
          alt="Retail Hub"
          style={ImageMobileStyle}
        />
        <p>
          {header}
          <br />
          {this.props.check !== "EKYC" && (
            <b
              className="typewrite"
              data-type='[ "Solutions", "Retail Audit", "Direct Payout", "Retail Census", "Mystery Audit" ]'
            >
              Solutions
            </b>
          )}
          <span style={{ display: "block" }}>{footer}</span>
        </p>
      </LeftContainerStyle>
    );
  }
}
