import { triggerSimpleAjax } from "../helpers/httpHelper";
import { GET_OUTLET_DATA, GET_BULK_TABLE_DATA, GET_OUTLET_RECOVERY_TABLE_DATA,GET_OUTLET_RECOVERY_ADDED_TABLE_DATA, GET_OUTLET_RECOVERY_ACCEPT_STATUS, GET_OUTLET_RECOVERY_REJECT_STATUS } from "../helpers/constants/apiUrl";

const getOutletData = (req="",id = '') => {
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            GET_OUTLET_DATA + `${id}` + req,
            'GET',
            {},
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            }
        )
    });
}

export const getUploadTableData = (keyValue,subProjectId='') => {
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            GET_BULK_TABLE_DATA + `?objects_type=${keyValue}${subProjectId}`,
            'GET',
            {},
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            }
        )
    });
}



export const getOutletRecoveryTableData = (page_size,current) => {
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            GET_OUTLET_RECOVERY_TABLE_DATA + `page-size=${page_size}&paginated_view=${current}`,
            'GET',
            {},
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            }
        )
    });
}
export const getOutletRecoveryAddedTableData = (request) => {
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            GET_OUTLET_RECOVERY_ADDED_TABLE_DATA,
            'POST',
            request,
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            }
        )
    });
}

export const getOutletRecoveryRejectStatus = (oultlet_id) => {
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            GET_OUTLET_RECOVERY_REJECT_STATUS+oultlet_id+'/',
            'POST',
            {},
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            }
        )
    });
}
export const getOutletRecoveryAcceptStatus = (oultlet_id) => {
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            GET_OUTLET_RECOVERY_ACCEPT_STATUS+oultlet_id+'/',
            'POST',
            {},
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            }
        )
    });
}
export default getOutletData;