const loadError = oError => {
    throw new URIError(
        'The script ' + oError.target.src + " didn't load correctly."
    );
};

export default function affixScript(url, t = 'head', onloadFunction = () => { }) {
    var newScript = document.createElement('script');
    newScript.defer = 1;
    newScript.src = url;

    // newScript.onerror = loadError;
    if (onloadFunction) {
        newScript.onload = onloadFunction;
    }

    var f = document.getElementsByTagName(t)[0];
    f.appendChild(newScript);
}
