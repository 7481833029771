import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BackArrow from "./arrow_back_ios.png";
import LocalWhite from "./local_activity_white.svg";
import { useHistory } from 'react-router-dom';
import DateRangeIcon from "./date_range.svg";
import InfoICon from "./info.svg";
import EventAvailableIcon from "./event_available.svg";
import getMobileEarnings from '../../../actions/creation/getEarnings';
import ProgramLogo from "./programLogo.svg"

export const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

export const Program = styled.div`
  background-color: #004080;
  width: 100%;
  height: 40px;
  left: 15px;
  top: 137px;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

export const ProgramText = styled.span`
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
`;

export const Card = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 5px 5px #2E487B;
`;

export const Amount = styled.div`
  font-size: 21px;
  color: #004080;
  font-weight: bold;
`;

export const Details = styled.div`
  p {
    margin: 5px 0;
    font-size: 14px;
  }
`;

export const DesktopMessage = styled.div`
  color: red;
  font-size: 1.2em;
  margin-bottom: 20px;
`;

const Earnings = () => {
  const history = useHistory();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [earningsDetails, setEarningsDetails] = useState([]);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const url = window.location.pathname
    const req = url.split('slug')[1];
    // const req = "test4-LPri1BZni24S5cZm7GES/B54dWSMv4z9XVWjrsj87";
    getMobileEarnings(req).then((response) => {
      const {
          results
      } = response;
      setEarningsDetails(results);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e);
      setIsLoading(false);
      setError("Failed to fetch earnings data.");
    });
  }, []);

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <Container>
      {isDesktop ? (
        <DesktopMessage>Please switch to mobile view for a better experience</DesktopMessage>
      ) : (
        <>
          <h3 style={{ color: "#2E487B", marginLeft: "5px", display: "flex", alignItems: "center" }}>
            <img src={BackArrow} alt="Back" style={{ marginRight: "15px", cursor: "pointer" }} onClick={handleBackClick} />
            Earnings
          </h3>
          {isLoading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>{error}</div>
          ) : earningsDetails.length === 0 ? (
            <div style={{ textAlign: 'center', marginTop: '20px', color: '#2E487B', fontSize: '16px' }}>No Earnings found</div>
          ) : (
            earningsDetails.map((detail, index) => (
              <React.Fragment key={index}>
                {/* <Program>
                  <ProgramText>
                    <img src={LocalWhite} alt="Local" style={{ marginRight: "15px" }} />
                    Program: {detail?.outlet?.program_name}
                  </ProgramText>
                </Program> */}
                <Card>
                  <Amount>₹ {detail?.amount}</Amount>
                  <Details>
                    <p style={{ fontWeight: "400", fontSize: "10px", lineHeight: "20px", color: "#2E487B" }}>
                      <img src={ProgramLogo} style={{ marginRight: "15px" }} alt="Info" />
                      Program: {detail?.outlet?.program_name}
                    </p>
                    <p style={{ fontWeight: "400", fontSize: "10px", lineHeight: "20px", color: "#2E487B" }}>
                      <img src={InfoICon} style={{ marginRight: "15px" }} alt="Info" />
                      UTR: {detail?.workflow_object?.utr_number}
                    </p>
                    <p style={{ fontWeight: "400", fontSize: "10px", lineHeight: "20px", color: "#2E487B" }}>
                      <img src={EventAvailableIcon} style={{ marginRight: "15px" }} alt="Payment Date" />
                      Payment Date: {detail?.workflow_object?.payment_date}
                    </p>
                    <p style={{ fontWeight: "400", fontSize: "10px", lineHeight: "20px", color: "#2E487B" }}>
                      <img src={DateRangeIcon} style={{ marginRight: "15px" }} alt="Payment Duration" />
                      Payment Duration: {detail?.workflow_object?.invoice_duration_start_date} to {detail?.workflow_object?.invoice_duration_end_date}
                    </p>
                  </Details>
                </Card>
              </React.Fragment>
            ))
          )}
        </>
      )}
    </Container>
  );
};

export default Earnings;
