export const WORKFLOW_SUFIX_KEY = 'workflow-permission'

const workflowList = [
        "client-manager-workflow-permission",
        "calling-executive-workflow-permission",
        "calling-manager-workflow-permission",
        "qc-executive-workflow-permission",
        "qc-manager-workflow-permission",
        "field-auditor-workflow-permission",
        "field-manager-workflow-permission",
        "field-coordinator-workflow-permission",
        "project-manager-workflow-permission"
]

export const WORKFLOW_KEYS = {
  QC_EXECUTIVE:'qc-executive-workflow-permission',
  QC_MANAGER:'qc-manager-workflow-permission',
  CALLING_EXECUTIVE:'calling-executive-workflow-permission',
  CLIENT_MANAGER:'client-manager-workflow-permission',
  FCD:'field-coordinator-workflow-permission',
  PROJECT_MANAGER:"project-manager-workflow-permission",
  IFTU_MANAGER:"iftu-manager-workflow-permission",
  IFTU_EXECUTIVE:"iftu-executive-workflow-permission",
  FIELD_MANAGER:"field-manager-workflow-permission"
}

export const DASHBOARD_KEYS = {
  QC_EXECUTIVE:'qc-executive-dashboard-permission',
  QC_MANAGER:'qc-manager-dashboard-permission',
  CALLING_MANAGER:"calling-manager-dashboard-permission",
  CALLING_EXECUTIVE:'calling-executive-dashboard-permission',
  CLIENT_MANAGER:'client-manager-dashboard-permission',
  FCD:'field-coordinator-dashboard-permission',
  PROJECT_MANAGER:"project-manager-dashboard-permission",
  IFTU_MANAGER:"iftu-manager-dashboard-permission",
  IFTU_EXECUTIVE:"iftu-executive-dashboard-permission",
  FIELD_MANAGER: "field-manager-dashboard-permission"
}
export const ACTION_STATE_KEY = {
  ROUTE_PLAN_CLIENT_CONFIRM:"route_plan_client_confirmed",
  ROUTE_PLAN_CLIENT_REJECT:"client_confirmation_rejected",
  PAYOUT_PLOTTED:"payouts_plotted",
  PAYMENT_SUCCESSFUL: "payment_successful"
}
