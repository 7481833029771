import React, {Component} from "react";
import {triggerSimpleAjax} from "../../../helpers/httpHelper";
import {LOGIC_SCORE_CLIENT_DATA} from "../../../helpers/constants/apiUrl";
import LoadingWrapper from "../../../components/LoadingWrapper";
import ScoreDetailV2 from "./ScoreDetailV2";
import styled from "styled-components"
import { microHeading } from "../../../theme/typography";
import ImageZoom from "../../../components/ImageZoom";

const Title = styled.div`
  ${microHeading.bold}
  margin-top: 1rem;
  margin-right: 1rem;
`
export default class LogicScoreWrapper extends Component{
  state={
    isLoading: true,
    max_score: 0,
    score: 0,
    scoring_parameter_groups_data : []
  }
  componentDidMount() {
    this.getLogicDetails()
  }

  getLogicDetails = () => {
    const { slug } = this.props;

    triggerSimpleAjax(
        LOGIC_SCORE_CLIENT_DATA(slug),
        "GET",
        {},
        (response) => {
          const { max_score, score, scoring_parameter_groups_data } = response
          this.setState({
            max_score,
            score,
            scoring_parameter_groups_data,
            isLoading: false
          })
        },
        (error) => {
          this.props.triggerNotification({
            messageText: JSON.stringify(error),
            status: "FAILED"
          })
        }
    )
  }
  render() {
    const {
      isLoading,
      max_score,
      score,
      scoring_parameter_groups_data
    } = this.state;

    const {
      totalImages,
      outletDetails
    } = this.props;

    console.log("outletDetailsid",outletDetails.id);
    console.log("outletDetailsslug",outletDetails.slug);
    return(
        <LoadingWrapper isLoading={isLoading}>
          <div style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <ImageZoom
              showCurrentSurveyImageTiles={false}
              showPrevSurveyImageTiles={true}
              totalImageCount={totalImages.length}
              totalImages={totalImages}
              newTab={true}
              noMinHeight={true}
              imageSectionwidth="16%"
              clientScreen
              outlet_id={outletDetails.id}
              outlet_slug={outletDetails.slug}
            />
            <Title>Total Score {score}/{max_score}</Title>
          </div>

          <ScoreDetailV2
              scoring_parameter_groups_data={scoring_parameter_groups_data}
          />
        </LoadingWrapper>
    )
  }
}

