export const getDayOrNumberSuffix = (day = 0) => {
    const dayNum = parseInt(day, 10);
    if (dayNum > 3 && dayNum < 21) {
      return `${dayNum}th`;
    }
    switch (dayNum % 10) {
      case 1:
        return `${dayNum}st`;
      case 2:
        return `${dayNum}nd`;
      case 3:
        return `${dayNum}rd`;
      default:
        return `${dayNum}th`;
    }
  };