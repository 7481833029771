import { triggerSimpleAjax } from '../../helpers/httpHelper';
import { GET_COLLECTED_BILLS_EARNINGS } from '../../helpers/constants/apiUrl';



export default function getCollectedBills(req = '') {
    return new Promise((res, rej) => {
        const url = `${GET_COLLECTED_BILLS_EARNINGS}?no-pagination&workflow_object=${req}`;
        triggerSimpleAjax(
            url,
            'GET',
            {},
            (response) => {
                res(response);
            },
            error => {
                rej(error);
            }
        );
    });
}

