import { triggerSimpleAjax } from '../../helpers/httpHelper';
import { GET_NEW_SKU_DATA } from '../../helpers/constants/apiUrl';



export default function getAddNewSkuData(req = '') {
    return new Promise((res, rej) => {
        const url = `${GET_NEW_SKU_DATA}?no-pagination&workflow_object=${req}`;
        triggerSimpleAjax(
            url,
            'GET',
            {},
            (response) => {
                res(response);
            },
            error => {
                rej(error);
            }
        );
    });
}

