/*eslint no-unused-expressions: "off"*/
import affixScript from "./affixScript";

function initFWHelp() {
    affixScript('https://ind-widget.freshworks.com/widgets/81000000678.js', 'body');
    window.fwSettings={
        'widget_id':81000000678
    };
    !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
}

export default initFWHelp;