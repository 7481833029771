import styled from "styled-components";
import { white } from "../../theme/colors";

export const GalleryWrapper = styled.div`
  .slider-nav .slick-slide div {
    outline: 0 none;
  }

  color: white;
  text-align: center;

  margin: 0 auto;

  width: 70%;

  .slider-single,
  .slider-nav {
    .slick-slide {
      outline: 0 none;
    }
  }

  .slider-single {
    padding: 0 32px;

    .slick-prev,
    .slick-next {
      padding: 20px;
      width: 64px;
      height: 64px;
      background-clip: padding-box;
      box-shadow: inset 0 0 0 3px #ffffff;
      z-index: 1;
      background-color: transparent;
      border-radius: 100%;

      &:before {
        font-size: 24px;
      }

      &.slick-disabled {
        opacity: 0.25;
        cursor: not-allowed;
        &:before {
          color: ${white};
        }
      }
    }

    .slick-prev {
      left: -140px;

      &::before {
        content: "";
        border-left: 2px solid;
        border-bottom: 2px solid;
        height: 14px;
        width: 14px;
        transform: rotate(45deg);
        display: inline-block;
        margin-left: 4px;
      }
    }

    .slick-next {
      right: -140px;

      &::before {
        content: "";
        border-right: 2px solid;
        border-bottom: 2px solid;
        height: 14px;
        width: 14px;
        transform: rotate(-45deg);
        margin-right: 4px;
        display: inline-block;
      }
    }

    figure {
      position: relative;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 430px;
      width: 430px;
      margin: auto;
    }
  }

  .slider-nav {
    .slick-slide {
      width: auto !important;
      margin: 10px;

      img {
        box-shadow: transparent;
        width: 100px;
        height: 70px;
        object-fit: cover;

        width: 128px;
        height: 88px;
      }

      .nav-active {
        box-shadow: 0 0 0 4px #ffffff;
      }
    }
  }
`;
