import { triggerSimpleAjax } from '../../helpers/httpHelper';
import {  GET_OUTLETS_ACTIONS } from '../../helpers/constants/apiUrl';

export default function getOutletActions() {
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            `${GET_OUTLETS_ACTIONS}`,
            'GET',
            {},
            (response) => {
                res(response);
            },
            error => {
                rej(error);
            }
        );
    });
}