import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const ToolTipStyle = styled.div`
    position: relative;
    display: ${props => props.toolDisplay || 'inline-flex'};
    width: ${props => props.width || 'auto'};
    align-items: center;
    border: 0px !important;
    svg{
        width: 18px;
        height: 18px;
    }

   > span{
        right: -14px;
        min-width: 200px;
        max-width: 242px;
        min-height: 50px;
        padding: ${props => props.contentPadding  || '16px'};
        position: absolute;
        background: #212323;
        box-shadow: 0px 6px 16px rgba(91, 127, 220, 0.16);
        border-radius: 4px;
        top: 42px;
        z-index:10;
        color:white;
        white-space: pre-wrap;
        word-wrap: break-word;

        &:before{
            content:' ';
            position: absolute;
            top: -6px;
            right: 7%;
            pointer-events: none;
            height: 12px;
            width: 12px;
            background-color: inherit;
            transform: rotate(135deg);
            border-radius: 0 0 0 2px;
        }
        ${props => props.align === 'top left' &&
            css`
                right:0;
                left:4px;
                top:auto;
                min-width:${props => props.contentWidth || 'auto'};
                min-height: auto;
                text-align:center;
                bottom:100%;
                &:before {
                    top: 88%;

                    left: 7%;
                    margin-right: -6px;
                }
            `
        }

        ${props => props.align === 'bottom center' &&
            css`
                top: 100%;
                right: auto;
                width:${props => props.contentWidth || 'auto'};
                min-height: auto;
                padding: 8px;
                left: 50%;
                transform: translate(-50%, 0);
                text-align: center;
                &:before {
                    right: 50%;
                    margin-right: -6px;
                }
            `
        }

        ${props => props.align === 'top center' &&
            css`
                top: auto;
                bottom: 100%;
                right: auto;
                min-width:${props => props.contentWidth || '200px'};
                min-height: auto;
                padding: 8px;
                left: 50%;
                transform: translate(-50%, 0);

                &:before {
                    top: auto;
                    bottom: -6px;
                    right: 50%;
                    margin-right: -6px;
                }
            `
        }
    }
`;

export const ToolTip = ({icon,content, align,toolDisplay,contentPadding,contentWidth='', removeTooltip = false, initTooltip = false, styleProps}) => {
    const [showToolTip, setToolTip] = useState(initTooltip);

    if(removeTooltip) {
        return null
    }

    return <ToolTipStyle align={align}
            onMouseEnter={() => setToolTip(true)} 
            onMouseLeave={() => setToolTip(false)}
            toolDisplay={toolDisplay}
            contentPadding={contentPadding}
            contentWidth={contentWidth}
            {...styleProps}
            >
                {icon}
                { showToolTip && content ? <span>{content}</span> : null}
        </ToolTipStyle>
}