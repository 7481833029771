/**
 * Type face - Barlow
 */

export const regular = "normal";
export const semiBold = 500;
export const xsBold = 600;
export const bold = "bold";
export const light = 400;
export const h7 = 700;
