import { triggerSimpleAjax } from "../helpers/httpHelper";
import { ACTIVATE_OUTLET, CANCEL_OUTLET, ACTIVATE_OUTLET_SKU, CANCEL_OUTLET_SKU } from "../helpers/constants/apiUrl";

const processOutlet = (uploadId, projId, isActivate = true, key= 'project_outlet_master', isSKU = false, question_group='') => {
    const apiUrl = isSKU ? (isActivate ? ACTIVATE_OUTLET_SKU(uploadId, projId, key, question_group) : CANCEL_OUTLET_SKU(uploadId, projId, key, question_group))  : (isActivate ? ACTIVATE_OUTLET(uploadId, projId, key) : CANCEL_OUTLET(uploadId, projId, key));
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            apiUrl,
            'POST',
            {},
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            }
        )
    });
}

export default processOutlet;