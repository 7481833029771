import React, { Component } from "react";
import {CLIENT_KYC_REPORT_IMAGES} from "../../../../helpers/constants/apiUrl";
import AppModalImages from "../../../../components/AppModalImages";

class KycModalImages extends Component {
    render() {
        return (
            <AppModalImages reqUrl={CLIENT_KYC_REPORT_IMAGES} />
        )
    }
}

export default KycModalImages
