import { triggerSimpleAjax } from "../helpers/httpHelper";
import { ACTIVATE_OUTLET, CANCEL_OUTLET, ACTIVATE_OUTLET_SKU, CANCEL_OUTLET_SKU, CANCEL_SUBPROJECT_OUTLET, ACTIVATE_SUBPROJECT_OUTLET } from "../helpers/constants/apiUrl";

const processOutletSubProject = (uploadId, projId, isActivate = true, key= 'project_outlet_master', isSKU = false, question_group='',subProjectId) => {
    const apiUrl = isSKU ? (isActivate ? ACTIVATE_OUTLET_SKU(uploadId, projId, key, question_group) : CANCEL_OUTLET_SKU(uploadId, projId, key, question_group))  : (isActivate ? ACTIVATE_SUBPROJECT_OUTLET(uploadId, projId, key,subProjectId) : CANCEL_SUBPROJECT_OUTLET(uploadId, projId, key,subProjectId));
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            apiUrl,
            'POST',
            {},
            (response) => {
                res(response)
            },
            (err) => {
                rej(err)
            }
        )
    });
}

export default processOutletSubProject;