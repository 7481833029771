import React from "react";
import styled, { css } from "styled-components";
import capitializeFirstChar from "../../helpers/utils/capitializeFirstChar";
import { white, black300, green300, black200 } from "../../theme/colors";

const CheckBoxStyle = styled.label`
  position: relative;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  input {
    display: none;
  }
  
  & > i {
    content: "";
    color: ${white};
    min-width: ${(props) => props.width || "16px"};
    height: ${(props) => props.height || "16px"};
    border: 1px solid ${black300};
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    margin-right: 12px;
    vertical-align: text-top;
    position: relative;
    ${props => props.disabled && css`
    cursor:not-allowed;
    background:${black200};
  `}
    ${(props) =>
      props.checked && props.isTick &&
      css`
        background: ${props => props.background};
        border: 1px solid transparent;
        :before {
          content: "";
          position: relative;
          bottom: 1px;
          width: 4px;
          border-bottom: 1px solid ${white};
          /* long arm */
          height: 10px;
          border-right: 1px solid ${white};
          /* rotate the mirrored L to make it a checkbox */
          transform: rotate(45deg);
          -o-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      `}
      ${(props) =>
        props.checked && !props.isTick &&
        css`
          background: ${props => props.background};
          border: 1px solid transparent;
          :before {
            content: "";
            position: relative;
            bottom: 1px;
            width: 4px;
            height: 10px;
            border-right: 1px solid ${white};
            transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }
          :after {
            content: "";
            position: absolute;
            bottom: 1px;
            width: 4px;
            left: 5px;
            height: 10px;
            border-right: 1px solid ${white};
            transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }
        `}
  }
`;

const CheckBox = ({
  name = "",
  label = "",
  checked = false,
  onChange = () => {},
  width,
  height,
  isTick = true,
  background = green300,
  disabled= false,
  keyProp,
  customStyles
}) => {
  return (
    <CheckBoxStyle checked={checked} width={width} height={height} isTick={isTick} background={disabled ? black200 : background} key={keyProp || `${name}-${checked}`} disabled={disabled} style={{...customStyles}}>
      <i></i>
      <input
        disabled={disabled}
        type={"checkbox"}
        name={name}
        checked={checked}
        onChange={disabled ? () => {} : onChange}
      />
      {capitializeFirstChar(label)}
    </CheckBoxStyle>
  );
};

export default CheckBox;
