const ROUTES = {
  HOME_PAGE_URL: `/`,
  LOGIN: `/login`,
  FORGOT_PASSWORD: `/forgot-password`,
  VERIFY_OTP: `/verify-otp`,
  RESET_PASSWORD: `/reset-password`,
  RETAILERS: `/retailers`,
  RETAILERS_LOGIN: `/retailers/login`,
  RETAILERS_CHOOSE_MOBILE_NUMBER: `/retailers/choose-mobile-number`,
  RETAILERS_ENTER_MOBILE_NUMBER: `/retailers/enter-mobile-number`,
  RETAILERS_OTP: `/retailers/otp`,
  RETAILERS_SUCCESS: `/retailers/success`,
  DASHBOARD: `/dashboard`,
  ORGANIZATION_USERS_ROLE: `/organization-users-roles`,
  ORGANIZATION_INVOICE_SKU: `/organization-inovice-sku`,
  PROJECTS: "/projects",
  SUB_PROJECTS: "/sub-projects",
  QUESTION_MANAGEMENT: "/question-management",
  DATA: "/data",
  OUTLET_MANAGEMENT: "/outlet-management",
  MOBILE_LINK_DOWNLOAD: "/mobile-link-download",
  FORM16: "/form16",
  EKYC: '/eMyTrack',
  GAME:'/gamification'
};

export const DASHBOARDROUTES = {
  META: `${ROUTES.DASHBOARD}/meta`,
  ACCOUNT_VERIFICATION: `${ROUTES.DASHBOARD}/account-verification`,
  ICICI_ACCOUNT_VERIFICATION: `${ROUTES.DASHBOARD}/icici-upi-payments`,
  PAN_VERIFICATION: `${ROUTES.DASHBOARD}/pan-verification`,
  IFSC_VERIFICATION: `${ROUTES.DASHBOARD}/ifsc-verification`,
  MY_DOWNLOADS: `${ROUTES.DASHBOARD}/my-downloads`,
  TDS_CALCULATION: `${ROUTES.DASHBOARD}/tds`,
  TDS_REPORTS: `${ROUTES.DASHBOARD}/tds/tds-reports`,
  PROJECTS: `${ROUTES.DASHBOARD}/projects`,
  // DATA: `${ROUTES.DASHBOARD}/${ROUTES.DATA}`,
  DAHSBOARD_VIEW_ALL: `${ROUTES.DASHBOARD}/view-all`,
  DAHSBOARD_INDIVIDUAL_OUTLET_VIEW: `${ROUTES.DASHBOARD}/view/:outlet`,
  DASHBOARD_INDIVIDUAL_OUTLET_VIEW_TOTAL_IMAGES: `${ROUTES.DASHBOARD}/view/:outletSlug/:outletID/:viewType`,
  GET_DASHBOARD_INDIVIDUAL_OUTLET_VIEW_TOTAL_IMAGES: (
    outletSlug,
    outletID,
    viewType
  ) => `${ROUTES.DASHBOARD}/view/${outletSlug}/${outletID}/${viewType}`,
  DASHBOARD_INDIVIDUAL_OUTLET_VIEW_TOTAL_IMAGES_NEW: `${ROUTES.DASHBOARD}/view/:outletSlug/:outletID/:viewType`,
  GET_DASHBOARD_INDIVIDUAL_OUTLET_VIEW_TOTAL_IMAGES_NEW: (
    outletSlug,
    outletID,
    viewType
  ) => `${ROUTES.DASHBOARD}/view/${outletSlug}/${outletID}/${viewType}`,
  
  
  // DASHBOARD_IMAGE_VIEWER: `${ROUTES.DASHBOARD}/view/:outletSlug/:outletID/:viewType`,
  // GET_DASHBOARD_IMAGE_VIEWER: (
  //   outletSlug,
  //   outletID,
  //   viewType
  // ) => `${ROUTES.DASHBOARD}/view/${outletSlug}/${outletID}/${viewType}`,
  DASHBOARD_IMAGE_VIEWER: `${ROUTES.DASHBOARD}/collected-bill-image-viewer/:workflow_object_id`,

  DAHSBOARD_CALLING_MANAGE_ALLOCATION: `${ROUTES.DASHBOARD}/calling-allocation-management`,
  DAHSBOARD_CALLING_MANAGE_ALLOCATION_EDIT: `${ROUTES.DASHBOARD}/calling-allocation-management/edit`,
  DAHSBOARD_QC_MANAGE_ALLOCATION: `${ROUTES.DASHBOARD}/qc-allocation-management`,
  DAHSBOARD_QC_MANAGE_ALLOCATION_EDIT: `${ROUTES.DASHBOARD}/qc-allocation-management/edit`,
  DAHSBOARD_IFTU_MANAGE_ALLOCATION: `${ROUTES.DASHBOARD}/iftu-allocation-management`,
  DAHSBOARD_IFTU_MANAGE_ALLOCATION_EDIT: `${ROUTES.DASHBOARD}/iftu-allocation-management/edit`,
  ORGANIZATION_USERS_ROLE: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}`,
  ORGANIZATION_INVOICE_SKU: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}`,
  ORGANIZATION_ORG_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-list`,
  ORGANIZATION_ORG_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-creation`,
  ORGANIZATION_ORG_UPDATE_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-update`,
  ORGANIZATION_ORG_UPDATE: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-update/:slug`,
  ORGANIZATION_ORG_VIEW_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/`,
  ORGANIZATION_ORG_VIEW: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug`,

  ORGANIZATION_ORG_OFFER: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/list-of-offers`,
  ORGANIZATION_ORG_ADD_OFFER: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/list-of-offers/add-offer`,
  ORGANIZATION_ORG_EDIT_OFFER: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/list-of-offers/edit-offer/:offerSlug`,

  ORGANIZATION_REGIONS_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/list-of-regions`,
  ORGANIZATION_REGIONS_ADD: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/add-region`,
  ORGANIZATION_REGIONS_EDIT_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/edit-region/`,
  ORGANIZATION_REGIONS_EDIT: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/edit-region/:id`,

  ORGANIZATION_ZONES_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/list-of-zones`,
  ORGANIZATION_ZONES_ADD: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/add-zone`,
  ORGANIZATION_ZONES_EDIT_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/edit-zone/`,
  ORGANIZATION_ZONES_EDIT: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/edit-zone/:id`,

  ORGANIZATION_TERRITORIES_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/list-of-territories`,
  ORGANIZATION_TERRITORIES_ADD: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/add-territory`,
  ORGANIZATION_TERRITORIES_EDIT_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/edit-territory/`,
  ORGANIZATION_TERRITORIES_EDIT: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/edit-territory/:id`,

  ORGANIZATION_USER_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/user-list`,
  ORGANIZATION_PRODUCT_FAMILIES_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/product-families-list`,
  ORGANIZATION_CATEGORIES_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/categories-list`,
  ORGANIZATION_DROPDOWN_LIST: `${ROUTES.DASHBOARD}/collected-bills-list`,
  ORGANIZATION_ASSIGN_INVOICE_SKU: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/categories-list`,
  ORGANIZATION_SUBCATEGORIES_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/sub-categories-list`,
  ORGANIZATION_BRANDS_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/brands-list`,
  ORGANIZATION_VARIANTS_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/variants-list`,
  ORGANIZATION_MANUFACTURES_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/manufactures-list`,
  ORGANIZATION_INVOICE_HEADERS_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/invoice-headers-list`,
  ORGANIZATION_INVOICE_DETAILS_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/invoice-details-list`,
  ORGANIZATION_SKU_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/sku-list`,
  ORGANIZATION_SKU_CREATE: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/sku-list/sku-create`,
  ORGANIZATION_PRODUCT_FAMILIES_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/product-families-list/product-families-details-creation`,
  ORGANIZATION_MANUFACTURES_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/manufactures-list/manufactures-details-creation`,
  ORGANIZATION_INVOICE_HEADERS_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/invoice-headers-list/invoice-headers-details-creation`,
  ORGANIZATION_INVOICE_DETAILS_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/invoice-details-list/invoice-details-creation`,
  ORGANIZATION_INVOICE_DETAILS_TEMPLATE: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/invoice-details-creation/invoice-details-template`,
  ORGANIZATION_CATEGORIES_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/categories-list/categories-creation`,
  ASSIGN_INOVICE_HEADERS: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/assign-invoice-sku/invoice-header/invoice-assign-header-creation`,
  ORGANIZATION_ASSIGN_SKU_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/categories-list/categories-creation`,
  ORGANIZATION_BRANDS_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/brands-list/brands-creation`,
  ORGANIZATION_VARIANTS_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/variants-list/variants-creation`,
  ORGANIZATION_SUB_CATEGORIES_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_INVOICE_SKU}/sub-categories-list/sub-categories-creation`,

  ORGANIZATION_GST_DETAILS: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-gst-details`,
  ORGANIZATION_GSTDETAILS_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-gst-details/gst-details-creation`,
  ORGANIZATION_ROLES_LIST: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/roles-list`,
  ORGANIZATION_ROLE_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/role-creation`,
  ORGANIZATION_USER_CREATION: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/user-creation`,
  ORGANIZATION_USER_UPDATE_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/user-update/`,
  ORGANIZATION_USER_UPDATE: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/user-update/:user_name`,
  ORGANIZATION_ROLE_UPDATE: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/role-update/:slug_name`,
  ORGANIZATION_ROLE_UPDATE_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/role-update`,
  ORGANIZATION_USER_VIEW_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/view-user/`,
  ORGANIZATION_ROLE_VIEW_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/view-role/`,
  ORGANIZATION_USER_VIEW: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/view-user/:user_name`,
  ORGANIZATION_USER_PROFILE: `${ROUTES.DASHBOARD}/profile/:user_name`,
  ORGANIZATION_ROLE_VIEW: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/view-role/:slug_name`,

  ORGANIZATION_BULK_UPLOAD_USERS: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/user-list/bulk-upload`,
  ORGANIZATION_BULK_UPLOAD_SKU: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/sku-list/bulk-upload`,
  ORGANIZATION_BULK_UPLOAD_REGIONS: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/list-of-regions/bulk-upload`,
  ORGANIZATION_BULK_UPLOAD_ZONES: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/list-of-zones/bulk-upload`,
  ORGANIZATION_BULK_UPLOAD_TERRITORIES: `${ROUTES.DASHBOARD}${ROUTES.ORGANIZATION_USERS_ROLE}/organization-view/:slug/list-of-territories/bulk-upload`,

  META_STATE_LIST: `${ROUTES.DASHBOARD}/meta/states`,
  META_STATE_ADD: `${ROUTES.DASHBOARD}/meta/add-state`,
  META_STATE_EDIT_WITHOUT_KEY: `${ROUTES.DASHBOARD}/meta/edit-state/`,
  META_STATE_EDIT: `${ROUTES.DASHBOARD}/meta/edit-state/:slug`,
  META_CITY_LIST: `${ROUTES.DASHBOARD}/meta/cities`,
  META_CITY_ADD: `${ROUTES.DASHBOARD}/meta/add-city`,
  META_CITY_EDIT_WITHOUT_KEY: `${ROUTES.DASHBOARD}/meta/edit-city/`,
  META_CITY_EDIT: `${ROUTES.DASHBOARD}/meta/edit-city/:slug`,
  META_CITY_MERGE: `${ROUTES.DASHBOARD}/meta/cities/merge-cities`,

  PROJECT_NOTIFICATIONS: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/notifications`,
  PROJECTS_CREATION: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/project-creation`,
  PROJECTS_UPDATE: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/project-update/:slug`,
  PROJECTS_UPDATE_WITUOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/project-update`,
  SUB_PROJECTS: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug`,
  SUB_PROJECTS_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list`,
  SUB_PROJECTS_CREATION: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/creation`,
  SUB_PROJECTS_UPDATE: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/update`,
  SUB_PROJECTS_VIEW_WITHOUT_KEY: ({ projectSlug }) =>
    `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/${projectSlug}/view`,
 
  
  SUB_PROJECTS_VIEW: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug`,
  SUB_PROJECTS_VIEW_BULK_UPLOAD: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/bulk-upload`,

  SUB_PROJECTS_OUTLET_VIEW: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/list-of-outlets`,
  SUB_PROJECTS_OUTLET_VIEW_DETAILS: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/list-of-outlets/view/:outlet`,
  SUB_PROJECTS_OUTLET_VIEW_DETAILS_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/list-of-outlets/view`,

  PROGRAM_MANAGEMENT: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/program-management`,
  PROGRAM_MANAGEMENT_ADD: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/program-management/add-form`,
  PROGRAM_MANAGEMENT_EDIT: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/program-management/edit-form/:programSlug`,

  PANEL_MANAGEMENT: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/panel-management`,
  PANEL_MANAGEMENT_ADD: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/panel-management/add-form`,
  PANEL_MANAGEMENT_EDIT: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/panel-management/edit-form/:panelSlug`,
  PANEL_MANAGEMENT_UPLOAD: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/panel-management/bulk-upload`,
  PANEL_MANAGEMENT_OUTLET_LIST: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/panel-management/list-of-outlets`,
  PANEL_MANAGEMENT_OUTLET_LIST_DETALS_VIEW: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/panel-management/list-of-outlets/view/:outlet`,

  LOGIC_SCORE: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/logic-score`,
  LOGIC_SCORE_SCORING_PARAMETERS: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/logic-score/scoring-parameters`,
  LOGIC_SCORE_CALCULATED_PARAMETERS: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/logic-score/calculated-parameters`,
  LOGIC_SCORE_BENCHMARKS: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/logic-score/benchmarks`,
  LOGIC_SCORE_URL_MAKER: ({ projectSlug, slug, item }) =>
    `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/${projectSlug}/view/${slug}/logic-score/${item}`,
  LOGIC_SCORE_BENCHMARKS_BULK_UPLOAD: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/logic-score/benchmarks/bulk-upload`,

  QUESTION_MANAGEMENT_LANDING: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug${ROUTES.QUESTION_MANAGEMENT}`,
  QUESTION_MANAGEMENT_LANDING_UPLOAD: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug${ROUTES.QUESTION_MANAGEMENT}/bulk-upload`,
  SKU_QUESTION_MANAGEMENT: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug${ROUTES.QUESTION_MANAGEMENT}/sku-questions`,
  SKU_QUESTION_MANAGEMENT_LANDING_UPLOAD: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug${ROUTES.QUESTION_MANAGEMENT}/sku-bulk-upload`,

  OUTLET_LIST: `${ROUTES.DASHBOARD}/list-of-outlets`,
  OUTLET_DETAILS: `${ROUTES.DASHBOARD}/list-of-outlets/view/:outlet`,
  OUTLET_DETAILS_WITHOUT_KEY: `${ROUTES.DASHBOARD}/list-of-outlets/view`,
  OUTLET_MANAGEMENT_LANDING: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug${ROUTES.OUTLET_MANAGEMENT}`,
  MOBILE_DOWNLOAD_LINK: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug${ROUTES.MOBILE_LINK_DOWNLOAD}`,

  OUTLET_MANAGEMENT_OUTLET_LIST: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug${ROUTES.OUTLET_MANAGEMENT}/outlets/:objectType`,

  CLIENT_DASHBOARD: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:projectSlug`,
  CLIENT_DASHBOARD_WITHOUT_KEY: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view`,
  CLIENT_DASHBOARD_SUB_PROJECTS_PAYOUT_TABLE: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:projectSlug/reports`,
  CLIENT_DASHBOARD_SUB_PROJECTS_INDIVIDUAL_PAYOUT: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:projectSlug/reports/:outletId`,
  CLIENT_DASHBOARD_SUB_PROJECTS_PAYOUT_TABLE_IMAGES: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:projectSlug/reports/:id/images`,
  CLIENT_DASHBOARD_SUB_PROJECTS_PAYOUT_CYCLE_VIEW: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:projectSlug/payout`,

  CLIENT_DASHBOARD_SUB_PROJECTS_CYCLE_VIEW: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:projectSlug/payout/cycle`,
  CLIENT_DASHBOARD_OUTLET_LIST: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:projectSlug/list-of-outlets`,
  CLIENT_DASHBOARD_OUTLET_LIST_DETAILS_VIEW: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:projectSlug/list-of-outlets/view/:outlet`,

  SUB_PROJECTS_CYCLE_VIEW: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/cycle`,
  SUB_PROJECTS_CYCLE_VIEW_OUTLET_LIST: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/cycle/list-of-outlets`,
  SUB_PROJECTS_CYCLE_VIEW_OUTLET_VIEW_DETAILS: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/cycle/list-of-outlets/view/:outlet`,

  SUB_PROJECTS_KYC_VIEW: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:slug/manage-kyc`,
  SUB_PROJECTS_CYCLE_OUTLET_VIEW_DETAILS: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:slug/manage-kyc/view/:outlet`,
  SUB_PROJECTS_KYC_VIEW_OUTLET_LIST: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/client-portal-view/:slug/manage-kyc/list-of-outlets`,

  UPLOAD_SPOC: `${ROUTES.DASHBOARD}/manage-spoc`,
  UPLOAD_PAYOUT: `${ROUTES.DASHBOARD}/manage-payout`,
  UPLOAD_CLIENT_PLOT_PAYOUT: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/cycle/plot-payout`,
  FORM16_UPLOAD: `${ROUTES.DASHBOARD}${ROUTES.FORM16}`,
  FORM16_PAN_LIST: `${ROUTES.DASHBOARD}${ROUTES.FORM16}/pan-list`,
  FORM16_STORE_DETAIL: `${ROUTES.DASHBOARD}${ROUTES.FORM16}/pan-list/store-detail`,

  MESSAGING: `${ROUTES.DASHBOARD}/message`,
  DETAILS_VIEW: `${ROUTES.DASHBOARD}/message/details`,
  SUMMARY_VIEW: `${ROUTES.DASHBOARD}/message/summary`,

  /* Reports */
  REPORTS: `${ROUTES.DASHBOARD}/reports`,
  MOBILE_VIEW:`${ROUTES.DASHBOARD}/pp-outlet-details/:slug`,
  MOBILE_VIEW_TWO_PATH:`${ROUTES.DASHBOARD}/pp-outlet-details/:slug/:master_id`,
  EARNING_MOBILE_VIEW:`${ROUTES.DASHBOARD}/earnings/pp-outlet-earning/:slug`,
  EARNINGS_MOBILE_VIEW_TWO_PATH:`${ROUTES.DASHBOARD}/earnings/pp-outlet-earning/:slug/:master_id`,
  INVOICE_MOBILE_VIEW:`${ROUTES.DASHBOARD}/pp-outlet-invoice/:slug`,
  INVOICE_MOBILE_VIEW_TWO_PATH:`${ROUTES.DASHBOARD}/pp-outlet-invoice/:slug/:master_id`,
  TARGET_MOBILE_VIEW:`${ROUTES.DASHBOARD}/pp-performance-statement-project-view/:slug`,
  TARGET_MOBILE_VIEW_TWO_PATH:`${ROUTES.DASHBOARD}/pp-performance-statement-project-view/:slug/:master_id`,

  CLIENT_REPORTS: `${ROUTES.DASHBOARD}/client-reports/`,
  CLIENT_REPORTS_OUTLET: `${ROUTES.DASHBOARD}/client-reports/:outletID/:outletSlug`,
  CLIENT_REPORTS_KYC_IMAGES: `${ROUTES.DASHBOARD}/client-reports/:outletID/images`,
  /* Retail Audit report */
  RETAIL_AUDIT_REPORT: `${ROUTES.DASHBOARD}/retail-audit-report/`,
  RETAIL_AUDIT_REPORT_IMAGES: `${ROUTES.DASHBOARD}/retail-audit-report/:outletID/images`,
  RETAIL_AUDIT_REPORT_OUTLET_LIST: `${ROUTES.DASHBOARD}/retail-audit-report/view/:outlet`,
  /* Unicorn */
  NEW_PAGE_MODAL_IMAGES: `${ROUTES.DASHBOARD}/:reportTitle/:outletID/images`,
  UNICORN_LIST: `${ROUTES.DASHBOARD}/list-of-unicorns`,
  UNICORN_VIEW_WITHOUT_SLUG: `${ROUTES.DASHBOARD}/list-of-unicorns/view`,
  UNICORN_VIEW: `${ROUTES.DASHBOARD}/list-of-unicorns/view/:slug`,

  /* Retailers Invoice */
  RETAILERS_INVOICE_LIST: `${ROUTES.DASHBOARD}/invoices`,

  /*Field App Dump */

  FIELD_APP_DUMP_BULK_UPLOAD: `${ROUTES.DASHBOARD}/field-app-dump`,

   /*Outlet Recovery */

   FIELD_OUTLET_RECOVERY: `${ROUTES.DASHBOARD}/field-outlet-recovery`,

    /*Form 16 */

    FORM_16_PDF: `${ROUTES.DASHBOARD}/form-16-pdf`,
    FORM_16_CSV: `${ROUTES.DASHBOARD}/form-16-csv`,

     /*Retailer Report */
     RETAILER_REPORT_ALL: `${ROUTES.DASHBOARD}/performance-report`,
     RETAILER_REPORT_PDF: `${ROUTES.DASHBOARD}/performance-report-pdf`,
     RETAILER_REPORT_CSV: `${ROUTES.DASHBOARD}/performance-report-csv`,

      /*Quick App Embed */
      QUICK_APP_INTEGRATE: `${ROUTES.DASHBOARD}/quick-app-embed`,

       /*Bulk Kyc Upload */
       BULK_KYC_ALL: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/bulk-kyc`,
       INVOICE_SKU_ALL: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/assign-invoice-sku`, 
       INVOICE_HEADER: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/assign-invoice-sku/invoice-header`, 
       ASSIGN_SKU: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/assign-invoice-sku/invoice-sku`, 
       ASSIGN_SKU_LIST: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/assign-invoice-sku/invoice-sku-list`, 
       ASSIGN_INOVICE_TEMPLATE_LIST: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/assign-invoice-template/invoice-template-list`, 
       ASSIGN_SKU_CREATION: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/assign-invoice-sku/invoice-sku-create`, 
    //  BULK_KYC_ALL: `${ROUTES.DASHBOARD}/bulk-kyc`,
    BULK_KYC_PDF: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/bulk-kyc/bulk-kyc-images`,
    BULK_KYC_CSV: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/bulk-kyc/bulk-kyc-csv`,

/*Bulk Field Assign */
  BULK_FIELD_ASSIGN: `${ROUTES.DASHBOARD}${ROUTES.PROJECTS}/view-list/:projectSlug/view/:slug/bulk-field-assign`,
 
};
  
export const RETAILERS = {
  RETAILERS_DASHBOARD: `${ROUTES.RETAILERS}`,
  RETAILERS_DASHBOARD_PDF: `${ROUTES.RETAILERS}/pdf-view`,
  RETAILERS_OUTLETS: `${ROUTES.RETAILERS}/outlets-list`,
  RETAILERS_OUTLETS_INFO: `${ROUTES.RETAILERS}/outlets-list/outlet-information`,
  RETAILERS_EARNINGS: `${ROUTES.RETAILERS}/earnings`,
  RETAILERS_OFFERS: `${ROUTES.RETAILERS}/offers`,
  RETAILERS_OFFERS_INFO: `${ROUTES.RETAILERS}/offers/view/:offerSlug`,
  RETAILERS_GET_NOTIFICATIONS: `${ROUTES.RETAILERS}/notifications`,
  RETAILERS_PARTICULAR_ORG_EARNINGS: `${ROUTES.RETAILERS}/earnings/earnings-details`,
  RETAILERS_PARTICULAR_ORG_EARNINGS_PDF: `${ROUTES.RETAILERS}/earnings/earnings-details/pdf-view`,
  RETAILERS_ALL_INVOICES: `${ROUTES.RETAILERS}/list-of-invoices`,
  RETAILERS_ALL_INVOICES_PDF: `${ROUTES.RETAILERS}/list-of-invoices/pdf-view`,
  RETAILERS_PROFILE: `${ROUTES.RETAILERS}/profile/:user_name`,
  RETAILERS_OUTLETS_EDIT: `${ROUTES.RETAILERS}/outlets-list/edit`,
  RETAILERS_FORM16_LIST: `${ROUTES.RETAILERS}/form16`,
};

//ekyc routes

export const EKYC = {
  LOGIN: `${ROUTES.EKYC}/login`,
  OTP: `${ROUTES.EKYC}/verify-otp`,
  DASHBOARD:`${ROUTES.EKYC}/dashboard`,
  OUTLET_Add: `${ROUTES.EKYC}/outlet-add`,
  OUTLET_DETAIL: `${ROUTES.EKYC}/outlet-detail/:slug`,
  OUTLET_QUESTION_LISTING: `${ROUTES.EKYC}/outlet-survey-listing/:slug`,
  PENDING_LISTING: `${ROUTES.EKYC}/pending-list`,
  REJECTED_LISTING: `${ROUTES.EKYC}/rejected-list`,
  COMPLETED_LISTING: `${ROUTES.EKYC}/completed-list`,
  RSA_FORM: `${ROUTES.EKYC}/rsa-form`,
  RSA_DETAILS: `${ROUTES.EKYC}/rsa-detail`,
  ADD_ON_FLY: `${ROUTES.EKYC}/add-new`,
};

export const GAME={
  DASHBOARD:`${ROUTES.GAME}/dashboard`,
  LISTING:(slug="")=>`${ROUTES.GAME}/view-list/${slug ? slug : ":slug"}`,
  LISTING_ALL:(slug="")=>`${ROUTES.GAME}/view-list-all/${slug ? slug : ":slug"}`,
  REPORT:(slug="")=>`${ROUTES.GAME}/report/${slug ? slug : ":slug"}`,
}

export default ROUTES;
