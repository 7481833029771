import { triggerSimpleAjax } from '../../helpers/httpHelper';
import { CREATE_RETAILER_EDIT } from '../../helpers/constants/apiUrl';

export const createAcceptRequest = (reqId = '') => {
    return new Promise((res, rej) => {
        const url = `${CREATE_RETAILER_EDIT}`; 

        const bodyData = {
            request_id: reqId, 
            status: "accepted",
        };

        triggerSimpleAjax(
            url,
            'POST',
            bodyData, 
            (response) => {
                res(response);
            },
            (error) => {
                rej(error);
            }
        );
    });
}

export const createRejectRequest = (reqId = '', rejectionReason = '') => {
    return new Promise((res, rej) => {
        const url = `${CREATE_RETAILER_EDIT}`; 

        const bodyData = {
            request_id: reqId, 
            status: "rejected",
            rejection_reason: rejectionReason, 
        };

        triggerSimpleAjax(
            url,
            'POST',
            bodyData, 
            (response) => {
                res(response);
            },
            (error) => {
                rej(error);
            }
        );
    });
}

