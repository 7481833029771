import React, { Fragment, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { white } from '../theme/colors';
import CloseIcon from '@material-ui/icons/Close';
import themeSizes from '../theme/themeSizes';

const ModalBackDrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${props => props.backdropzIndex || '1040'};
  background-color: #2c2f42;
  opacity:${props => props.backDropOpacity || '0.9'};
  display:none;
 
  ${props =>
    props.showModal &&
    css`
      display: block;
    `};
`;
const ModalContainer = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${props => props.modalzIndex || '1050'};
  -webkit-overflow-scrolling: touch;
  outline: 0;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  
  ${props =>
    props.showModal &&
    css`
      display: block;
      top: 0;
      ${props => props.displayFlex && css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
  `}
     
      animation: blowUpModal 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    `};

  ${props =>
    !props.showModal &&
    css`
      animation: blowDownModal 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    `};

  @keyframes blowUpModal {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes blowDownModal {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(100px);
    }
  }
`;
const ContentContainer = styled.div`
    margin:auto;
    padding:0 30px;
    ${props => props.displayFlex && css`
      min-width:${props => props.modalWidth ? props.modalWidth : '40%'};
    `}
    max-width:${props => props.modalWidth ? props.modalWidth : '40%'};
    max-height:${props => props.maxHeight ? props.maxHeight : 'auto'};
    background: ${props => props.background || white};
    box-shadow: ${props => props.noContainerShadow ? 'none' : '0px 10px 24px rgba(91, 127, 220, 0.16)'};
    min-height: ${props => props.modalHeight || '185px'};
    padding:28px;
    position:relative;
    top:${props => props.top ? props.top : '16%'};
    ${props => props.withHeader && css`
        padding-top:0;
    `};
    display:flex;
    flex-direction:column;
    overflow-y:${({ overflow }) => overflow || 'auto'};
    @media ${themeSizes.mobile} {
     max-width:70%
      }
`;
const HeaderStyle = styled.div`
    display:flex;
    padding-top:28px;
    box-sizing:border-box;
    position:sticky;
    top:0;
    left:0;
    right:0;
    span{
        flex:1;
        text-align:center;
    }
    span + svg{
        align-content:right;
        ${props => props.closeIconColor && css`
          color: ${props.closeIconColor};
        `};
    }
`;

export const Modal = ({
  withHeader = false,
  title = '',
  children,
  modalWidth,
  modalHeight,
  top,
  showModal,
  maxHeight,
  closeAction = () => { },
  closeIconColor,
  background,
  noContainerShadow,
  backDropOpacity,
  displayFlex,
  overflow,
  noExit = false
}) => {
  const [modalState, setModalState] = useState(true);
  const [showBackDrop, setBackDrop] = useState(true)

  let timer;
  const onCloseModal = () => {
    setModalState(false);
    timer = setTimeout(() => {
      closeAction && closeAction();
      setBackDrop(false);
    }, 400);
  };
  // const myEventHandler = (e) => {
  //   if (e.which == 27) {
  //     onCloseModal()
  //   }
  // }
  // useEffect(() => {
  //   if(noExit){
  //     return
  //   } else{
  //     window.addEventListener("keypress", myEventHandler, false);
  //     return () => {
  //       window.removeEventListener("keypress", myEventHandler, false);
  //       clearTimeout(timer)
  //     };
  //   }
  //
  // }, []);
  // "Esc" key modal close
  useEffect(() => {
    if(noExit){
      return
    } else{
      const close = (e) => {
        if(e.key === 'Escape'){
          onCloseModal()
        }
      }
      window.addEventListener('keydown',close)
      return () => window.removeEventListener('keydown',close)
    }
  })
  return (
    <Fragment>
      <ModalContainer showModal={modalState} displayFlex={displayFlex}>
        <ContentContainer
          withHeader={withHeader}
          maxHeight={maxHeight}
          modalWidth={modalWidth}
          modalHeight={modalHeight}
          top={top}
          background={background}
          noContainerShadow={noContainerShadow}
          displayFlex={displayFlex}
          overflow={overflow}
        >
          {withHeader ?
            <HeaderStyle closeIconColor={closeIconColor}>
              <span>{title ? title : ''}</span>
              {
                noExit ? "" :  <CloseIcon onClick={onCloseModal} style={{ cursor: 'pointer' }} />
              }

            </HeaderStyle>
            : null}
          {children}
        </ContentContainer>
      </ModalContainer>
      <ModalBackDrop showModal={showBackDrop} backDropOpacity={backDropOpacity} />
    </Fragment>
  )
}
