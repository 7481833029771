import styled, { keyframes, css } from 'styled-components';

const Spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
    border: 6px solid #f3f3f3;
    position: ${props => props.position || 'fixed'};
    top: ${props => props.top || '50%'};
    border-radius: 50%;
    left: calc(50% - 40px);
    border-top: 6px solid #3498db;
    width: 80px;
    height: 80px;
    ${props =>
    css`
        animation: ${Spin} 2s linear infinite;
    `};
`;

export default Loader;
