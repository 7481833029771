import React, { useState } from 'react';
import './ImageMagnifier.css';

const ImageMagnifier = ({ imageUrl }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseHover = (e) => {
        const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100;
        const y = ((e.clientY - top) / height) * 100;
        setPosition({ x, y });
    };

    return (
        <div 
            className="image-magnifier-container"
            onMouseMove={handleMouseHover}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="image-container">
                <img 
                    className="magnifier-img" 
                    src={imageUrl} 
                    alt="" 
                    style={{
                        transition: 'transform 0.2s ease',
                        transformOrigin: isHovered ? `${position.x}% ${position.y}%` : '50% 50%',
                        transform: `scale(${isHovered ? 3 : 1})`, 
                        width: '100%',    
                        height: 'auto',   
                        objectFit: 'contain' 
                    }} 
                />
            </div>
        </div>
    );
};

export default ImageMagnifier;
