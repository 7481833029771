import { triggerSimpleAjax } from '../../../helpers/httpHelper'
import { GET_CHECK_PENDING_REQUEST_RETAILER_EDIT } from '../../../../src/helpers/constants/apiUrl'

export function getCheckPendingRequestRetailerEdit(workflow_object_id='') {
    return new Promise((res, rej) => {
        const url = `${GET_CHECK_PENDING_REQUEST_RETAILER_EDIT}?workflow_object=${workflow_object_id}`;
        triggerSimpleAjax(
            url,
            'GET',
            {},
            (response) => {
                res(response);
            },
            (error) => {
                rej(error);
            }
        );
    });
}