import styled from 'styled-components';
import themeSizes from "../../theme/themeSizes";

const ContentWrapper = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || 'center'};
  margin: ${props => props.margin || '24px'};
  
  justify-content: ${props => props.justifyContent || 'flex-end'};
  
  @media ${themeSizes.mobile} {
    flex-flow: ${props => props.flexFlow || "" };
  }
`;

export default ContentWrapper;
