import React, { useEffect, useRef } from 'react';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

const PhotoViewer = ({ images, onClose, zoomLevel, indexImage }) => {
  const viewerRef = useRef(null);
  const viewerInstance = useRef(null);

  useEffect(() => {
    if (viewerRef.current && images.length > 0) {
      // Initialize Viewer.js
      viewerInstance.current = new Viewer(viewerRef.current, {
        inline: false, // Display in modal
        viewed() {
          viewerInstance.current.zoomTo(zoomLevel);
        },
        hidden() {
          // Trigger the onClose callback when modal is closed
          if (onClose) onClose();
        },
      });

      // Automatically open the first image in the modal
      viewerInstance.current.view(indexImage);
    }

    // Cleanup the viewer instance when component unmounts
    return () => {
      if (viewerInstance.current) {
        viewerInstance.current.destroy();
      }
    };
  }, [images, onClose]);

  // Programmatically close the viewer
  const closeViewer = () => {
    if (viewerInstance.current) {
      viewerInstance.current.hide();
    }
  };

  return (
    <div>
      {/* Hidden image elements */}
      <div ref={viewerRef}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`img-${index}`}
            style={{ display: 'none' }} // Hide the images from view
          />
        ))}
      </div>
      <button onClick={closeViewer}>Close</button> {/* Button to close the viewer */}
    </div>
  );
};

export default PhotoViewer;

