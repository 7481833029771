import create from "zustand"

const useStore = create(set => ({
    mobileNo: {},
    allQuestionData:[],
    outlet:{},
    userDetailsData:{},
    fetchMobileNo: data => {
      set({ mobileNo: data })
    },
    setOutletDataInStore: ({ outletId, storeData}) => set({
      [outletId]: storeData
    }),
    getOutletData: ({ outletId }) => set(),
    fetchAllQuestionData:(data)=>{
      set({allQuestionData:data})
    },
    fetchOutlet: data => {
      set({ outlet: data })
    },
    fetchUserDetails:data => {
      set({userDetailsData:data})
    }
  }))

  export default useStore
