import React, {useState, useEffect} from 'react';
import { triggerSimpleAjax } from '../helpers/httpHelper';
import { UPLOAD_API, FILE_UPLOAD_API } from '../helpers/constants/apiUrl';
import { DashboardTextBox } from '../pages/dashboard/components/DashboardTexBox';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { green300, black300 } from '../theme/colors';

const FileUpload = ({ fileAcceptType,boxWidth, labelText,logo, warnMessage, type='image', onFileChosen, imagePath, hasError, withPreview = false, inputLabel = 'Upload Image',errorMessage, previewImagePath,length,isCheckIcon = true, ...rest}) => {
    // Declare a new state variable, which we'll call "previewImage" to keep preview image URL once uploaded
    const [previewImage, setFilePath] = useState(previewImagePath);

    // For handling Error
    const [error, setError] = useState({});

    // FOR Storing File name
    const [fileName, setFileName] = useState("");

    // Declare a new state variable, which we'll call "isUploading" to indicate the uploading state
    const [isUploading, setUploading] = useState(false);

    // Declare a new state variable, which we'll call "isUploading" to indicate the uploaded state
    const [isUploaded, setUploaded] = useState(true);

    useEffect(() => {
        // setFilePath(imagePath);
        setUploaded(!!previewImage);
    },[imagePath]);

    useEffect(() => {
        setFilePath(previewImagePath);
        setUploaded(!!previewImagePath);
    },[previewImagePath]);

    const onChooseFile = e => {
        const file = e.target.files[0];

        let formData = new FormData();
        if(type.toLowerCase() == 'file'){
            formData.append('file', file);
            if(rest.customFunction){
                setFileName(file.name);
                rest.customFunction(formData,file.name)
                return
            }
            formData.append('name', file.name);
        }else {
            formData.append('image', file);
            formData.append('name', file?.name);
        }

        setFileName(file?.name);

        // Set as uploading
        setUploading(true);

        // Re-Setting uploaded option as to re-upload
        setUploaded(false);
        const api = type.toLowerCase() == 'file' ? FILE_UPLOAD_API :  UPLOAD_API
        triggerSimpleAjax(
             api,
            'POST',
            formData,
            response => {
                // Resetting setUploading state
                setUploading(false);

                // Setting setUploaded state
                setUploaded(true);

                // to Reset Error
                setError({});

                // updating preview image path
                setFilePath(response.image);

                // Sending the image ID and file name to the parent component
                onFileChosen && onFileChosen(response.id,file.name, response.image);
            },
            error => {
                // Error block
                setError(error);
            },
            {},
            true
        )
    };

    return <DashboardTextBox
            type='file'
            boxWidth={boxWidth}
            value={ rest.customValue ? rest.customValue : logo|| fileName}
            placeholder = {inputLabel}
            outsideIcon = { !rest.hideTickMark ? (isUploading || isUploaded) && !Object.keys(error).length && length > 0 && isCheckIcon ? <DoneAllIcon style={{color:`${isUploaded ? green300 : black300}`}}/> :null : null}
            id="customFile"
            onChange={onChooseFile}
            warnMessage={{
                text: error.image || error.detail || (warnMessage ? warnMessage.text || " ": "") || (errorMessage ? "Max 3 images" : ""),
                aligh: 'right'
            }}
            fileAcceptType={fileAcceptType}
            {...rest}
        />
}

export default FileUpload;
