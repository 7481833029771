import React from 'react'
import styled, {css} from 'styled-components'
import {black100, black700, blue300, borderLight, cinnabar300} from '../../theme/colors'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from "@material-ui/icons/Edit";
import capitializeFirstChar from '../../helpers/utils/capitializeFirstChar';

export const StyledTable = styled.div`
  display: block;
  background: white;
  ${props => (!props.position ? 'overflow: auto;' : '')}
  ${props =>
          props.position === 'left'
                  ? `
      min-width: ${props.length * 175}px;
    margin-right: 1px;
  `
                  : ''}
  ${props =>
          props.position === 'right'
                  ? `
      min-width: ${props.length * 175}px;
    margin-left: 1px;
  `
                  : ''};
  width: ${props => props.width || '100%'};
  min-height: ${props => props.minHeight || 'auto'};
  padding: 8px 0 8px 24px;
  max-height:600px;
`

export const THEAD = styled.div`
  height: 43px;
  display: flex;
  display: inline-flex;
  align-items: center;
  z-index: 1;
  border-bottom: ${props => props.isBorder && `1px solid ${borderLight}`};
  background: white;
  ${props =>
          props.hasFixedElements
                  ? ''
                  : `
      position: sticky;
      top: -8px;
      z-index: 3;
  `}

  ${props => props.fluidTable &&
          css`
            border-bottom: 1px solid ${borderLight};
          `
  }
`

export const TD = styled.div`
  padding: ${props => props.noPadding ? 0 : '0 16px'};
  height: ${props => (props.height ? props.height : '55px')};
  min-width: ${props => (props.width ? props.width : '175px')};
  max-width: ${props => (props.width ? props.width : '175px')};
  display: flex;
  flex-flow: ${props => (props.flexFlow || "row")};
  align-items: ${props => (props.alignItem ? props.alignItem : 'center')};
  justify-content: ${props => props.justify ? props.justify : "flex-start"};
  background: ${props => props.isSelected && `${black100} !important` };
  
  &:hover {
    ${props =>
            props.hoverable
                    ? `background-color: ${black700};
    border: 0.5px solid ${black700};
    cursor: pointer`
                    : ''}
  }

  // white-space: ${props => props.whitespace || 'nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    // overflow: visible;
    // white-space: normal;
    ${props => props.wordBreak && css`
      word-break: ${props.wordBreak || 'break-all'};
    `
    }
     
  }

  ${props => props.hasBorder && css`
    border: 1px solid ${borderLight};
    border-bottom: none;
  `}

  ${props => !props.fluidTable && !props.lastRecord &&
          css`
          border-bottom: ${props => props.isBorder && `1px solid ${borderLight}`};
          `
  }
`

export const THD = styled(TD)`
  font-weight: bold;
  background: white;
  height:45px;
  justify-content: ${props => props.justify || "flex-start"};
  border-bottom: ${props => !props.isBorder && `1px solid ${borderLight}`};
  ${props => props.sortable &&
          css`
            cursor: pointer;
          `
  }
  ${props => props.noBorder && css`
    border: none;
  `}
`

export const TBODY = styled.div`
  text-align: left;
`

const RowAction = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 15px;
  opacity: 0;
  z-index: 3;

  width: ${props => props.rowActionElementWidth || '43px'};
  height: 41px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  svg {
    width: 20px;
  }
`


export const TR = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.height || '55px'};
  position: ${props => props.isPosition &&  "relative"};
  background: ${props => {
    if (props.isSelected) return `${black100} !important`;
    if (props.useStatusColor && props.status === 'temp') return `${black100}`; 
    return 'white';
  }};
  border-top: ${props => !props.isBorder && `1px solid ${borderLight}`};
  ${props => props.fluidTable && css`
    :not(:last-child) {
      border-bottom: 1px solid ${borderLight};
    }
  `}

  ${props => (props.clickable || props.isCursor) && css`
    &:hover {
      background-color: #eaeaea29;
      z-index: 1;
      cursor: ${props.isCursor && "pointer"};

      ${RowAction} {
        display: flex;
        right: 0px;
        opacity: 1;
      }
    }
  `}

  ${props => props.disablePointer && css`
    cursor: auto !important;
  `}

  ${props => props.noBorder && css`
    border: none
  `}
`;

class Table extends React.Component {
  render() {
    const {
      columns,
      data,
      position,
      onRowClick,
      updateOrder,
      order,
      hasFixedElements = false,
      enableCheckbox = false,
      fluidTable = false,
      rowActionType = 'VIEW',
      rowActionElement,
      rowHeight,
      disableRowClick = false,
      rowActionElementWidth,
      selectedOutletRows = [],
      isCursor = true,
      isPosition = true,
      isBorder = true,
      useStatusColor = false 
    } = this.props;
    console.log('data898',data);

    return columns.length > 0 ? (
      <StyledTable position={position} length={columns.length}>
        <THEAD hasFixedElements={hasFixedElements} fluidTable={fluidTable} isBorder={isBorder}>
          {columns.map(column => (
            <THD
              key={column.key}
              width={column.width}
              fluidTable={fluidTable}
              noPadding={column.noPadding}
              sortable={column.sortable}
              onClick={column.sortable ? () => updateOrder(column.key, order[column.key] === "ASC" ? "DSC" : "ASC") : null}
              isBorder={isBorder}
            >
              {column.name}{' '}
              {column.sortable ? (
                order[column.key] === "ASC" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
              ) : null}
            </THD>
          ))}
        </THEAD>
        <TBODY>
          {data.map((item, inx) => (
            
            <TR
              key={inx}
              fluidTable={fluidTable}
              height={rowHeight}
              clickable={onRowClick}
              onClick={e => {
                onRowClick && !disableRowClick && onRowClick(item, inx);
              }}
              disablePointer={disableRowClick}
              isSelected={item?.related_workflow_object && selectedOutletRows.includes(item?.related_workflow_object?.id)}
              isCursor={isCursor}
              isPosition={isPosition}
              isBorder={isBorder}
              status={item?.related_workflow_object?.current_status}
              useStatusColor={useStatusColor} 
            >
              {console.log('item898',item)}
              {columns.map(column => {
                const value = column.key && column.key.split('.').reduce((o, i) => o && o[i], item);
                return (
                  <TD
                    key={column.key}
                    isSelected={selectedOutletRows.includes(item?.related_workflow_object?.id)}
                    whitespace={column.whitespace || 'nowrap'}
                    lastRecord={inx === data.length - 1}
                    fluidTable={fluidTable}
                    onClick={e => {
                      column.stopPropagation && e.stopPropagation();
                      column.onClick && column.onClick(value, item, e);
                    }}
                    height={column.height}
                    width={column.width}
                    hoverable={typeof column.onClick === 'function'}
                    wordBreak={column.wordBreak}
                    noPadding={column.noPadding}
                    isBorder={isBorder}
                  >
                    {column.render ? (
                      column.render(value, item, inx)
                    ) : (
                      <div clickable={typeof column.onClick === 'function'}>
                        {typeof value === 'string' ? capitializeFirstChar(value) : value}
                      </div>
                    )}
                  </TD>
                );
              })}

              {(onRowClick || isCursor) && !disableRowClick && (
                <RowAction
                  rowActionElementWidth={rowActionElementWidth}
                  onClick={e => {
                    e.stopPropagation();
                    onRowClick && onRowClick(item);
                  }}
                >
                  {rowActionType === 'VIEW' ? '' : rowActionType === 'CUSTOM' ? rowActionElement : <EditIcon style={{ color: cinnabar300 }} />}
                </RowAction>
              )}
              {onRowClick && disableRowClick && (
                <RowAction rowActionElementWidth={rowActionElementWidth}>
                  {rowActionType === 'VIEW' ? (
                    <EyeIcon
                      style={{ color: blue300 }}
                      onClick={e => {
                        e.stopPropagation();
                        onRowClick && onRowClick(item);
                      }}
                    />
                  ) : rowActionType === 'CUSTOM' ? (
                    rowActionElement
                  ) : (
                    <EditIcon
                      style={{ color: cinnabar300 }}
                      onClick={e => {
                        e.stopPropagation();
                        onRowClick && onRowClick(item);
                      }}
                    />
                  )}
                </RowAction>
              )}
            </TR>
          ))}
        </TBODY>
      </StyledTable>
    ) : null;
  }
}

export default Table;

