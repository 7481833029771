import React, {Component} from "react";
import Loader from "./Loader"

export default class LoadingWrapper extends Component{
  render(){
    const { isLoading, children } = this.props;
    if(isLoading){
      return <Loader />
    }
    return children
  }
}
