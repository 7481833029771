import React, { Fragment } from 'react';
import styled,{ css } from 'styled-components';
import { white, black200, black700, black600,black400, cinnabar300, blue300 } from '../../../theme/colors';
import themeSizes from '../../../theme/themeSizes';
import { baseCaption } from '../../../theme/typography';

const textboxStyle = `
    min-height:42px;
    border-radius: 2px;
    box-sizing: border-box;
    color: ${black700}; 
    display:flex;   
    flex-wrap: wrap;
    position: relative;
`;

const TextBoxStyle = styled.div`
    ${baseCaption};
    width: ${({boxWidth})=>boxWidth || 'auto'};
    >div{
        ${textboxStyle};
        padding: ${({boxPadding}) => boxPadding || '12px 16px'}; 
        margin: ${({boxMargin}) => boxMargin}; 
        width: ${({boxWidth})=>boxWidth || `540px`};
        max-width: ${({isSearchBox})=>isSearchBox && `240px`};
        background: ${({isSearchBox,background})=> isSearchBox ? `transparent` : background || `${white}`};
        border-bottom: ${({isSearchBox})=> isSearchBox && `1px solid #3E3E3E;`};
        border-radius: ${({isSearchBox})=> isSearchBox && `0`};
        border: ${({isSearchBox})=> !isSearchBox && `1px solid ${black200}`}; 
        ${props => props.noBorder && css`
            border: none
        `}
        ${props => props.errorLine && css`
            border: 1px solid ${cinnabar300};
        `};
        align-items: center;
        min-height: ${({boxHeight})=>boxHeight || `42px`};
        input{
            border:none;
            outline:none;

            flex:1;
            min-width: 1px; 
            width:0;
            background: ${({isSearchBox,background})=> isSearchBox ? `transparent` : background || `${white}`};
            ${baseCaption}
            ::placeholder {
                color: #909191;
            }
        }
        span {
            svg{
                width:18px;
                height:18px;
                vertical-align: -6px;
            };
        } 
        :after{
            ${props => props.disabled && css`
                cursor: not-allowed;
                content:' ';
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
            `};
        };
        @media ${themeSizes.tablet} {
            width:100% !important;
          }
    } 
    /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        };
        
`;

const FileBoxStyle = styled.div`
     ${baseCaption}
    &>div{
        ${textboxStyle}
        cursor: pointer;
        padding-left:16px;
        background:${(props)=> props.check === "ekyc" && "#EAEAEA"};
        width: ${({boxWidth})=>boxWidth || `540px`};
        margin: ${({boxMargin})=>boxMargin};
        border: ${({isSearchBox})=> !isSearchBox && `1px solid ${black200}`}; 
        ${props => props.errorLine && css`
            border: 1px solid ${cinnabar300};
        `};
        &>span{
            flex:1;
            padding:12px 0;
            overflow-x:auto;
            display:inline-block;
            white-space: nowrap;
            margin-right:4px;
            color:${props=>props.isPlaceholder?black400:black700};
        }
        input{
            display:none;
        }
        :after{
            ${props => props.disableEntireRow && css`
                cursor: not-allowed;
                content:' ';
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
            `};
        }
        label{
            background: ${(props)=> props.check === "ekyc" ? props.btbgColor:black600};
            position: relative;
            padding: 12px 17px;
            border-radius: 2px;
            color: ${white};
            ${baseCaption};
            cursor: pointer;
            display:flex;
            align-items:center;
            @media ${themeSizes.mobile} {
                padding:5px;
              };
            :after{
            ${props => props.disabled && css`
                cursor: not-allowed;
                content:' ';
                position: absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                z-index:3;
            `};
        }
        }
        svg{
            ${props => props.isOutSide && css`
                position: absolute;
                right: -36px;
                top: 4px;
            `};
            @media ${themeSizes.mobile} {
                display:none;
              }
        };
        @media ${themeSizes.tablet} {
            width:100% !important;
          }
       
    }
`;

const OutSideIcon = styled.span`
        position: absolute;
        right: -36px;
        top: 10px;
`;

const MessageBelowBoxStyle = styled.p`
    ${baseCaption}
    margin:5px 0 0;
    display:flex;
    justify-content:space-between;
    width: ${({boxWidth})=>boxWidth || `540px`};
    &>span:first-child{
        color: ${cinnabar300};
        order: ${props=> props.errorTextAlign === 'right' || props.subTextAlign === 'left' ? 2 : 1};
        ${props => props.cursorPointer && css`
            cursor:pointer;
        `}
        ${props => props.subTextDisabled && css`
            cursor:not-allowed;
        `}
    }
    &>span:last-child{
        color: ${blue300};
        order: ${props=>props.errorTextAlign === 'right' || props.subTextAlign === 'left' ? 1 : 2};
        ${props => props.cursorPointer && css`
            cursor:pointer;
        `}
        ${props => props.subTextDisabled && css`
            cursor:not-allowed;
        `}
    };
    
`;

const TextAreaStyle = styled.div`
    ${baseCaption};
    padding: ${props => `${props.isSKU && 0} !important` };
    overflow: ${props => props.isSKU && 'hidden' };
    &>div:first-child{
        ${textboxStyle};
        padding: 12px 0 12px 16px; 
        width: ${({boxWidth})=>boxWidth || `540px`};
        height: ${({boxHeight})=>boxHeight || `160px`};
        border: 1px solid ${black200};
        ${props => props.errorLine && css`
            border: 1px solid ${cinnabar300};
        `};
        overflow-y: auto;
        
        textarea{
            border:none;
            outline:none;
            resize: none;
            width:0;
            flex:1;
            background: ${({isSearchBox,background})=> isSearchBox ? `transparent` : background || `${white}`};
            ${baseCaption}
            ::placeholder {
                color: #909191;
            }
        };
        @media ${themeSizes.tablet} {
            width:100% !important;
          }
    }
`;

export const DashboardTextBox = ({
    type = 'text',
    placeholder = 'Enter text',
    value = '',
    rightIcon = '',
    outsideIcon,
    warnMessage={
        align : 'right',
        errorLine:true
    },
    subText={},
    customStyles,
    textareaStyles,
    onChange=()=>{},
    onClick=()=>{},
    onEnter=()=>{},
    handleRightIconClick,
    handleOutsideIconClick,
    isSearchBox = false,
    fieldName,
    boxWidth,
    disabled,
    background,
    boxHeight,
    boxPadding,
    boxMargin,
    fileAcceptType='',
    disableEntireRow=false,
    readOnly = false,
    noBorder = false,
    isSKU = false,
    check,
    btbgColor,
    max
}) => {
    const errorMessage = warnMessage.text;
    const showMessage =  errorMessage ||  Object.keys(subText).length;
    const acceptprop = fileAcceptType? { accept:fileAcceptType } : {};
    switch(type){
        case 'file':
            return (
                    <Fragment>
                        <FileBoxStyle
                            isOutSide={outsideIcon}
                            isPlaceholder = {!value}
                            check={check}
                            btbgColor={btbgColor}
                            errorLine = { warnMessage.text && warnMessage.errorLine }
                            boxWidth={boxWidth}
                            boxMargin={boxMargin}
                            disabled = {disabled}
                            disableEntireRow={disableEntireRow}
                        >
                            <div>
                                <span>
                                    {value?value:placeholder}
                                </span>
                                <label>
                                    {check === "ekyc" ? "Upload" : "Choose File"}

                                    {!disabled
                                    ?<input
                                        type = 'file'
                                        placeholder={'Choose File'}
                                        onChange = { onChange}
                                        name={fieldName}
                                        {...acceptprop}
                                    />:null}
                                </label>
                                {outsideIcon}
                            </div>
                            {showMessage ?
                                <MessageBelowBoxStyle
                                    errorTextAlign={errorMessage && warnMessage.align}
                                    subTextAlign={subText.align}
                                    boxWidth={boxWidth}
                                >
                                    <span>{warnMessage.text}</span>
                                    <span> {subText.text}</span>
                                </MessageBelowBoxStyle>
                            :null}
                        </FileBoxStyle>
                    </Fragment>
            )
        case 'textarea':
            return(
                <TextAreaStyle
                    errorLine = { warnMessage.text}
                    boxWidth = {boxWidth}
                    disabled = {disabled}
                    background={background}
                    boxHeight = {boxHeight}
                    isSKU={isSKU}
                    
                >
                    <div style={{...customStyles}} >
                        <textarea
                            value = {value}
                            placeholder={placeholder}
                            onChange={onChange}
                            name={fieldName}
                            max={max}
                            style={{...textareaStyles}}
                        />
                    </div>
                    {showMessage ?
                        <MessageBelowBoxStyle
                            errorTextAlign={errorMessage && warnMessage.align}
                            subTextAlign={subText.align}
                            boxWidth={boxWidth}
                        >
                            <span>{warnMessage.text}</span>
                            <span>{subText.text}</span>
                        </MessageBelowBoxStyle>
                    :null}
                </TextAreaStyle>
            )
        default :
            return(
                <TextBoxStyle
                    isOutSide={outsideIcon}
                    isSearchBox={isSearchBox}
                    errorLine = { warnMessage.onlyTextError ? false : warnMessage.text}
                    onChange={onChange}
                    boxWidth = {boxWidth}
                    disabled = {disabled}
                    background={background}
                    boxHeight = {boxHeight}
                    boxPadding = {boxPadding}
                    boxMargin={boxMargin}
                    noBorder = {noBorder}
                
                >
                    <div>
                        <input
                            type={type}
                            value = {value}
                            placeholder={placeholder}
                            onChange={onChange}
                            name={fieldName}
                            readOnly = {readOnly}
                            accept={fileAcceptType}
                            max={max}
                            onKeyDown={onEnter}
                        />
                        <span onClick={onClick}>{rightIcon}</span>
                        {outsideIcon && <OutSideIcon onClick={handleOutsideIconClick}>{outsideIcon}</OutSideIcon>}
                    </div>
                    {showMessage ?
                        <MessageBelowBoxStyle
                            errorTextAlign={errorMessage && warnMessage.align}
                            subTextAlign={subText.align}
                            boxWidth={boxWidth}
                            cursorPointer = {Boolean(subText.onClick)}
                            subTextDisabled =  {subText.disabled}
                        >
                            <span style={warnMessage.position?{position:warnMessage.position}:{}}>{warnMessage.text}</span>
                            <span onClick={()=> !subText.disabled ? subText.onClick() : ()=>{}}>{subText.text}</span>
                        </MessageBelowBoxStyle>
                    :null}
                </TextBoxStyle>
            )
    }
}
