import { USER_DETAILS } from '../helpers/constants/actions';

const INITIAL_STATE = {
  loggedIn: false,
  isFetched: false
};

const user = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case USER_DETAILS:
      return {
        ...state,
        ...payload,
        isFetched: true
      }
    default:
      return state
  }
}

export default user