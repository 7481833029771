import { triggerSimpleAjax } from "../../helpers/httpHelper";
import { CREATE_COLLECTED_BILLS_ADD_SKU_EXCEL } from "../../helpers/constants/apiUrl";

const createaddskuexcel = (id, payload) => {
  return new Promise((res, rej) => {
    const url = `${CREATE_COLLECTED_BILLS_ADD_SKU_EXCEL}`; // Append the id to the URL
    triggerSimpleAjax(
      url,
      'POST',
      payload,  // Pass the payload as the request body
      (response) => {
        res(response);
      },
      (err) => {
        rej(err);
      }
    );
  });
};

export default createaddskuexcel;
