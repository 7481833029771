import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import {
  microHeading,
  baseCaption,
  font,
  lineHeight,
} from "../theme/typography";
import { semiBold } from "../theme/typeface";
import { black400, black600, cinnabar300 } from "../theme/colors";
import themeSizes from "../theme/themeSizes";

const TextBoxStyle = styled.div`
  position: relative;
  width: ${(props)=> props.width ?props.width : "418px"};

  @media ${themeSizes.mobile} {
    width: 100%;
  }

  > div {
    display: flex;

    margin-top: ${(props) => (props.check === "ekycInput"  ? "0px" : "32px")};
    padding: 6px 6px 6px 0;
    border-bottom: 1px solid ${(props) => (props.error ? "#E23A3A" : "#909191")};
    > p {
      padding-top: 4px;
      margin: 0;
      font-weight: ${semiBold};
      font-size: ${font.base};
      line-height: ${lineHeight.medium};
      color: ${(props) => (props.color ? props.color : "#909191")};
    }
    svg {
      position: relative;
      right: 2px;
      &:first-child {
        animation: blowUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
      &:last-child {
        left: 6px;
        top: 2px;
      }
    }
    @keyframes blowUp {
      0% {
        opacity: 0;
        transform: translateY(8px);
      }
      100% {
        opacity: 1;
        transform: translateY(1px);
      }
    }
  }
  label {
    ${microHeading.regular}
    color: ${black400};
    display: inline-block;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    transition: all 0.2s ease-in;
    ${(props) =>
      props.fieldActive &&
      css`
        margin-top: 6px;
        transform: translateY(-25px);
      `};
  }
  input {
    ${(props) =>
      props.check === "ekycInput" ? microHeading.regular : microHeading.bold}

    flex:1;
    position: relative;
    color: ${black600};
    border: none;
    outline: none;
    z-index: 1;
    background: transparent;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      transition: background-color 5000s ease-in-out 0s;
    }
    &::-webkit-input-placeholder {
      color: ${black600};
    }
  }
  input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
`;
const ErrorMessage = styled.span`
  ${baseCaption}
  color: ${cinnabar300};
  margin: 4px 0 0 0;
  display: inline-block;
`;

const HighLightBorderStyle = styled.span`
  border: 1px solid ${(props) => (props.color ? props.color : "black")};
  border-radius: 2px;
  width: ${(props) => (props.width ? props.width : 0)};
  display: block;
  position: absolute;
  z-index: 1;
  margin-top: -1px;
`;
const OutSideTextBox = styled.span`
  position: absolute;
  right: -29px;
  top: 11px;
`;

const Label = styled.label`
  ${microHeading.regular}
  color: ${black400};
`;

export const TextBox = ({
  labelName,
  type,
  name = "",
  onChange = () => {},
  rightIcon = {},
  leftIcon = {},
  error = false,
  errorMessage = "",
  passwordRecommend = {},
  outsideRightIcon,
  subElement,
  value,
  isReadOnly = false,
  check,
  placeholder,
  width,
  max,
}) => {
  const [fieldActive, setFieldActive] = useState(false || isReadOnly);
  const { passwordType = "", color: passwordColor } = passwordRecommend || {};

  useEffect(() => {
    if (value && check !== "ekycInput" ) {
      setFieldActive(true);
    }
  }, [value]);

  const activateFocus = () => {
    if (check !== "ekycInput") {
      setFieldActive(true);
    }
  };
  const disableFocus = (event) => {
    if (event.target.value === "" && check !== "ekycInput" ) {
      setFieldActive(false);
    }
  };

  return (
    <>
      {check === "ekycInput"&& <Label>{labelName}</Label>}
      
      <TextBoxStyle
        error={error}
        color={passwordColor}
        fieldActive={fieldActive}
        check={check}
        width={width}
        
      >
        {check !== "ekycInput"  && <label>{labelName}</label>}
        

        <div>
          {leftIcon.appearOnFocus
            ? fieldActive
              ? leftIcon.icon
              : null
            : leftIcon.icon}
          {isReadOnly ? (
            <input type={type} value={value} name={name} readonly/>
          ) : (
            <input
              type={type}
              name={name}
              onChange={onChange}
              onFocus={activateFocus}
              onBlur={disableFocus}
              placeholder={check === "ekycInput" ? placeholder : ""}
              value={value}
              max={10}
            />
          )}
          {passwordType ? <p>{passwordType}</p> : null}
          {rightIcon.appearOnFocus
            ? fieldActive
              ? rightIcon.icon
              : null
            : rightIcon.icon}
        </div>
        <OutSideTextBox>{outsideRightIcon}</OutSideTextBox>
        {passwordRecommend.width ? (
          <HighLightBorderStyle
            color={passwordColor}
            width={passwordRecommend.width}
          />
        ) : null}
        {error && errorMessage.length ? (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        ) : null}
        {subElement}
      </TextBoxStyle>
    </>
  );
};
