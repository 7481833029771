import { triggerSimpleAjax } from '../../helpers/httpHelper';
import { GET_VIEW_MOBILE_EARNINGS } from '../../helpers/constants/apiUrl';



export default function getMobileEarnings(req = '') {
    return new Promise((res, rej) => {
        const url = `${GET_VIEW_MOBILE_EARNINGS}${req}?no-pagination`;
        triggerSimpleAjax(
            url,
            'GET',
            {},
            (response) => {
                res(response);
            },
            error => {
                rej(error);
            }
        );
    });
}



