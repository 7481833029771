import React from "react";
import styled, { css } from "styled-components";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  white,
  black200,
  blue300,
  black600,
  black400,
  curiousBlue300,
  cinnabar300,
} from "../../../theme/colors";
import { ToolTip } from "../../../components/ToolTip";
import { UserIcon } from "../../../components/UserIcon";
import { DropDown } from "../../../components/DropDown";
import {
  baseCaption,
  regularHeading,
  miniCaption,
} from "../../../theme/typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { bold } from "../../../theme/typeface";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import EditIcon from "@material-ui/icons/Edit";
import { getNotificationOption } from "./getNotificationOption";
import dateUtils from "../../../helpers/utils/dateUtils";
import wordWrap from "../../../helpers/utils/wordWrap";
import isDiffer from "../../../helpers/utils/isDiffer";
// import { ToolTip } from "../../../components/ToolTip";

const ProjectCardStyle = styled.div`
  width: calc(${props => props.showFilterList ? '33.33%' : '25%'} - 24px);
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  height: 232px;
  margin: 0 12px 24px 12px;
  background: ${white};
  padding: 20px;
  box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.16);
  display: flex;
  flex-direction: column;
  box-sizing:border-box;
  transition: all 0.4s ease-in-out;
  position: relative;

  &:hover{
    box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.26);
    transform: translate(-2px,-2px);
    z-index:3;
  }
  & > :nth-child(1) {
    img {
      height: 40px;
      object-fit: contain;
    }
  }
  & >:nth-child(2) {
    cursor: pointer;
    div:nth-child(1) {
    min-height: 90px;

    border-bottom: 1px dashed ${black200};
    }
  }
`;
const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => props.alignItem || "center"};
  padding-bottom: ${(props) => props.paddingBottom || "0"};
  & > :nth-child(1) {
    //width: 50%;
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const TitleText = styled.p`
    ${regularHeading.mini}
    font-weight: ${bold};
    margin:0;
`;

const RightCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const AnchorText = styled.a`
  color: ${curiousBlue300};
  cursor: pointer;
  ${baseCaption}
`;

const Text = styled.span`
  ${miniCaption.regular}
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};
`;

const DateText = styled.p`
  display: flex;
  color: ${black600};
  svg {
    position: relative;
    left: -4px;
  }
`;

export const ProjectCard = ({ onNotificationClickAction, onClickItem, onEditAction, setDeckFile, deckPath, slug, logo, notificationList = [], type, outlets_count, name, notificationCount, createdDate = new Date(), endDate = new Date(), pro, showFilterList = false, projectId }) => {
  const createdDateText = createdDate ? dateUtils().getFullDate(createdDate) : '';
  const endDateText = endDate ? dateUtils().getFullDate(endDate) : '';

  const projectName = wordWrap(name, 15);
  return (
    <ProjectCardStyle showFilterList={showFilterList}>
      <CardRow>
        <img src={logo} />
        <div>
          <DropDown
            heading={`Notification (${notificationCount})`}
            menuAlign="right"
            menuWidth="250px"
            listBorderLine="dashed"
            listPadding="12px 0"
            menuStartGap="2px"
            options={getNotificationOption(notificationList, onNotificationClickAction)}
          >
            {notificationCount ? <UserIcon
              letter={notificationCount + ''}
              parentMargin="0 10px 0 0"
              letterIconStyle={{
                width: "22px",
                height: "22px",
                ...baseCaption,
                margin: "0",
                verticalAlign: "sub",
                background: cinnabar300,
              }}
              dropDownIconStyle={{ width: "4px", top: "18%" }}
            /> : null}
          </DropDown>
          {onEditAction ? <DropDown
            menuAlign="right"
            options={[{ label: "Edit", icon: <EditIcon />, key: "" }]}
            onClickAction={() => {
              onEditAction(slug)
            }}
            triggerAction='click'
          >
            <MoreVertIcon style={{ position: "relative", top: "8px" }} />
          </DropDown> : null}
          {deckPath ?
            <NotificationsIcon onClick={() => {
              setDeckFile(deckPath)
            }} style={{ position: "relative", top: "8px", cursor: 'pointer' }} /> : null
          }
        </div>
      </CardRow>
      <div onClick={(e) => {
        e && e.preventDefault();
        onClickItem(pro)
      }}>
        <CardRow paddingBottom="12px" alignItem="flex-end" >
          {isDiffer(projectName, name) ? <ToolTip
            icon={<TitleText>{projectName}</TitleText>}
            content={name}
            align={"bottom center"}
            toolDisplay=""
          /> : <TitleText>{projectName}</TitleText>}
          <RightCard>
            <span>ID: {projectId}</span>
            <AnchorText>{type}</AnchorText>
          </RightCard>
        </CardRow>
        <CardRow>
          {
            createdDateText ? <DateText>
              <CalendarTodayIcon style={{ color: blue300 }} />
              <Text>{endDateText ? `${createdDateText} to ${endDateText}` : `${createdDateText}`}</Text>
            </DateText> : ''
          }
          <p>
            <Text color={black400}>No.of Outlets</Text>
            <TitleText>{outlets_count}</TitleText>
          </p>
        </CardRow>
      </div>
    </ProjectCardStyle>
  )
}
