import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../components/Loader';

// Login page
export const LoginLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'login-page' */ '../pages/login'),
    loading: () => <Loader />
});

//Retailers Login page
export const RetailersLoginLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'login-page' */ '../pages/retailers-login'),
    loading: () => <Loader />
});

// Home page
export const HomeLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'home-page' */ '../pages/home'),
    loading: () => <Loader />
});

// Dashboard Page
export const DashBoardLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'dashboard-page' */ '../pages/dashboard'),
    loading: () => <Loader />
});

// Retailers Dashboard 
export const RetailersDashboardLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'retailers-dashboard-page' */ '../pages/retailers-dashboard'),
    loading: () => <Loader />
});