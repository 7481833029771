import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

export const Status = ({ code, children }) => (
    <Route
        render={({ staticContext }) => {
            if (staticContext) staticContext.status = code;

            return children;
        }}
    />
);

Status.propTypes = {
    code: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ])
}

export const RedirectWithStatus = ({ to, status }) => (
    <Route
        render={({ staticContext }) => {
            // there is no `staticContext` on the client, so
            // we need to guard against that here
            if (staticContext) staticContext.status = status;

            return <Redirect to={to} />;
        }}
    />
);

RedirectWithStatus.propTypes = {
    to: PropTypes.string,
    status: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
}