export const PROJECT_TYPES_CONFIG = {
    "retail_audit": "retail_audit_cycle",
    "3p_payout": "payout_cycle",
    "off_take": "off_take_cycle",
    "census": "census",
    "mi": "mi_cycle",
}

export const SUB_PROJECT_TYPES = {
    ENPANELMENT :'empanelment',
    PAYOUT_CYCLE:'payout_cycle',
    OFF_TAKE: "off_take_cycle",
    MI_CYCLE: "mi_cycle",
    RETAIL_AUDIT_CYCLE:'retail_audit_cycle'
}

export const PROJECT_TYPES = {
    '3P_PAYOUT':'3p_payout',
    'RETAIL_AUDIT':'retail_audit'
}