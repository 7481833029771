import React from "react";
import { Title } from "./outlet.styles";
import styled from "styled-components";
import {
    microHeading,
    defaultFont,
    regularHeading,
    font,
} from "../../../theme/typography";
import { blue300, black600, curiousBlue300 } from "../../../theme/colors";
import { xsBold, bold, semiBold } from "../../../theme/typeface";
import DateToDisplay from "../../../helpers/utils/DateToDisplay";
const Wrapper = styled.div`
  width: 50%;
`;

const TitleWrapper = styled(Title)`
    ${microHeading.regular}
    color:${blue300};
    height:auto;
    margin: 48px 0 24px;
    font-weight:${xsBold};
`;
const Description = styled.p`
    ${defaultFont}
    color:${black600};
    margin-bottom:20px;
    
`;

const DetailsTitle = styled(Title)`
    ${regularHeading.mini}
    font-weight:${bold};
    height:auto;
    color:${black600};
`;

const LisWrapper = styled.ul`
    list-style: none;
    position:relative;
    padding-left:0;
    margin:20px 10px 43px;
    li{
        ${defaultFont} 
        font-weight:${semiBold};
        margin-bottom:12px;
        padding-left:30px;
        position:relative;
    }
    li::before {
        content: "•";
        color: ${curiousBlue300}; 
        position: absolute;
        left: 0;
        top:-3px;
        font-size:${font.medium};
    }
`;

const DateRangeContainer = styled.div`
    box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.16);
    border:1px solid ${curiousBlue300};
    border-radius:4px;
    padding: 12px;
    ${defaultFont}
    font-weight:${semiBold};
    color:${blue300};
    text-align:center;
`;

export const IntimationCalling = ({outletDetails={}}) => {
    const { route_plan_end_date, route_plan_start_date} = outletDetails.related_workflow_object || {}
    return (
    <Wrapper>
        <TitleWrapper>General Information</TitleWrapper>
        <Description>
           Please inform the outlet owner to keep the following information ready when the field auditor visits in the given route plan date range.
        </Description>
        <DetailsTitle>Details</DetailsTitle>
        <LisWrapper>
            {["PAN Card", "Bank Passbook", "Address Proof", "Cheque"].map((list) => (
                <li>{list}</li>
            ))}
        </LisWrapper>
        <DateRangeContainer>
        Route Plan Date: <DateToDisplay date={route_plan_start_date} daysuffix/> -  <DateToDisplay date={route_plan_end_date} daysuffix/>
        </DateRangeContainer>
    </Wrapper>)
};
