import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';
import { white, grey100, black700, black600, green300, black200, curiousBlue300 } from '../theme/colors';
import { xsBold, regular } from '../theme/typeface';
import { FilterWrapper } from '../pages/dashboard/components/FilterWrapper';
import { Button } from './Button';
import Loader from './Loader';

const FilterListStyle = styled.aside`
    position: fixed;
    z-index: 4;
    
    width: 309px;
    top: 52px;
    height: calc(100vh - 52px);
    
    background-color: ${white};
    right: ${({ open }) => open ? '0' : '-309px'};
    opacity:${({ open }) => open ? '1' : '0'};
    min-width: ${({ open }) => open ? '20%' : '0%'};
    
    transition: all 0.4s ease-in-out;
    box-shadow: 0px 4px 16px rgba(91, 127, 220, 0.2);
`;

const TitleStyle = styled.div`
   background: rgba(234, 234, 234, 0.4);
   padding:16px 24px;
   opacity:${({ open }) => open ? '1' : '0'};
   transition: opacity 0.2s;
   
`;

const RightIcons = styled.div`
    position: absolute;
    right: 18px;
    top: 16px;
    svg{
        width:20px;
        height:20px;
        cursor: pointer;
    }
`;

const ContentStyle = styled.div`
    padding:10px 20px;
    height: calc(100vh - 140px);
    overflow: auto;
`;

const FooterStyle = styled.div`
    display: flex;

    bottom: 0;
    position: absolute;
    top: auto;
    width: 100%;
    z-index: 1;

    button {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0;
    }
`
const FilterNameWrapper = styled.div`
    box-shadow: 0px 12px 16px rgba(71, 112, 193, 0.2);
    border-radius: 2px;
    background:${white};
    padding:16px;
    position: absolute;
    z-index:2;  
    p{
        font-size: 12px;
        line-height: 14px;
        font-weight:${xsBold};
        color:${black600};
        margin:0 0 8px;
        span{
            font-weight:${regular};
            float:right;
            color:${green300};
        }
    }
    div{
        display:flex;
        height: 32px;
        input{
        position:relative;
        border: 1px solid ${black200};
        border-radius: 2px;
        padding:8px;
        width:100%;
        outline:none;
    }
    span{
        display:inline-block;
       background:${curiousBlue300};
       width: 32px;
       svg{
           color:${white};
           
           margin:3px
       }
        }
    }
    
`;

// const FilterName = () => {
//     return(
//         <FilterNameWrapper>
//             <p>
//                 Filter Name
//                 <span>Saved</span>
//             </p>
//             <div>
//             <input type='text' />
//             <span>
//                 <CheckIcon />
//             </span>
//             </div>
//         </FilterNameWrapper>
//     )
// }
const Filters = ({
    open,
    closeAction = () => {},
    onClearAllAction = () => {},
    onApplyFilterAction = () => {},
    filterList=[],
    showLoading = false,
    additionalFilters
}) => {
    return(
        <FilterListStyle open = {open}>
            <TitleStyle open = {open}>
                FilterList
                <RightIcons>
                        {/* <SaveIcon style={{marginRight:'12px'}}/> */}
                        <CloseIcon onClick={closeAction}/>
                </RightIcons>
                {/* filter name edit option Ui
                *<FilterName />
                */}
            </TitleStyle>
            <ContentStyle>
                {
                    showLoading ? <div>
                        <Loader position="absolute" top="calc(50% - 60px)" />
                    </div> : (
                        <>
                            {filterList.map(props => <FilterWrapper width ={'100%'} {...props} />)}
                            {additionalFilters}
                        </>
                    )

                }

            </ContentStyle>
            {showLoading ? null : <FooterStyle>
                <Button
                    size='medium'
                    width="100%"
                    noBorder
                    borderRadius='0'
                    bgColor={grey100}
                    onClick={onClearAllAction}
                    color={black700}>
                        Clear All
                </Button>
                <Button size='medium' width="100%" noBorder borderRadius='0'
                    onClick={onApplyFilterAction}
                    bgColor={black700}>Apply Filter</Button>
            </FooterStyle>}
        </FilterListStyle>
    )
}

export default Filters;
