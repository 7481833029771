import { triggerSimpleAjax } from '../../helpers/httpHelper';
import { GET_OUTLET_GROUP_DETAILS } from '../../helpers/constants/apiUrl';

export default function getOutletGroupDetails(req) {
    const params = `?filter_answers=true&related_answers__workflow_object=${req.workflow_object}&question_group=${req.question_group}&no-pagination`
    return new Promise((res, rej) => {
        triggerSimpleAjax(
            GET_OUTLET_GROUP_DETAILS + params,
            'GET',
            {},
            (response) => {
                res(response);
            },
            error => {
                rej(error);
            }
        );
    });
}