import styled from "styled-components";
import {defaultFont, font} from "../../theme/typography";
import {Modal} from "../../components/Modal";
import {black300} from "../../theme/colors";
import {ActionButton} from "../dashboard/organization-roles-users/roles/role-styles";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import React, {useEffect, useState} from "react";
import {triggerSimpleAjax} from "../../helpers/httpHelper";
import {GET_ICICI_INSTANCE_DATA} from "../../helpers/constants/apiUrl";

const ContentStyle = styled.div`
  margin-top: 30px;
  b {
    font-size: ${font.smallmedium};
    line-height: 125%;
  }
  p {
    width: 300px;
    margin: 8px auto;
  }
  ${defaultFont}
  text-align:center;
`;

const IciciPaymentConfirmationModal = ({
    open,
    noAction,
    yesAction,
    confirmationText = `You have unsaved changes. 
Are you sure you want to leave this page?`,
    proceedText = '',
    closeAction=()=>{},
    actionText = 'Yes',
    cancelText =  'No',
    headerText = 'Confirmation',
    customElement = null,
    instance_id,
    payment_success,
    triggeredCancel
  }) => {

  const [instanceValues, setInstanceValues] = useState({})

  const getInstanceData = () => {
    triggerSimpleAjax(GET_ICICI_INSTANCE_DATA(instance_id), "GET", {}, (res) => setInstanceValues(res))
  }
  useEffect(() => {
    console.log('instance', instance_id)
    if(instance_id) getInstanceData()

  }, [instance_id])

  if (!open) {
    return null;
  }
  if(triggeredCancel){
    return (
      <Modal withHeader modalWidth="30%" closeIconColor={black300} closeAction={closeAction}>
        <ContentStyle>
          <b>Confirmation</b>
          <p>Are you Sure</p>
          <p>You want to cancel</p>
          {noAction ? <ActionButton onClick={noAction}>
            <CloseIcon />
            {cancelText}
          </ActionButton> : null}
          {yesAction ? <ActionButton active={true} onClick={yesAction}>
            <DoneIcon />
            {actionText}
          </ActionButton> : null}
        </ContentStyle>
      </Modal>
    )
  }
  if(payment_success){
    return (
      <Modal withHeader modalWidth="30%" closeIconColor={black300} closeAction={closeAction}>
        <ContentStyle>
          <b>Confirmation</b>
          <p>{instanceValues?.amount_in_words} Will  Be Debited From Your Linked Bank Account </p>
          {payment_success ? <ActionButton active={true} onClick={closeAction}>
            <DoneIcon />
            {actionText}
          </ActionButton> : null}
        </ContentStyle>
      </Modal>
    )
  }

  return (
    <Modal withHeader modalWidth="30%" closeIconColor={black300} closeAction={closeAction}>
      <ContentStyle>
        <b>Confirmation</b>
        <p>Are you Sure</p>
        <p>Total UPI ID: {instanceValues?.count}</p>
        <p>Total Amount To Be Paid: {instanceValues?.amount_in_words}</p>
        <p style={{ marginTop: 20, marginBottom: 20 }}>Do you want to proceed</p>
        {customElement}
        {noAction ? <ActionButton onClick={noAction}>
          <CloseIcon />
          {cancelText}
        </ActionButton> : null}
        {yesAction ? <ActionButton active={true} onClick={yesAction}>
          <DoneIcon />
          {actionText}
        </ActionButton> : null}
      </ContentStyle>
    </Modal>
  );
};

export default IciciPaymentConfirmationModal;
