import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { black200, white, blue300, black600 } from '../theme/colors';
import { defaultFont, miniCaption } from '../theme/typography';
import useOutsideClick from '../helpers/utils/useOutsideClick';

const DropDownStyle = styled.div`
    display:inline-block;
    position: relative;   
    cursor: pointer;
    .menu{
        position:absolute;
        top:100%;
        width:100%;
        height: ${props => props.menuStartGap || '12px'}; 
    }
`;

const MenuStyle = styled.div`
    width:${props => props.menuWidth || 'auto'};
    background: ${white};
    position: absolute;
    z-index:5;
    top:100%;
    ${props => props.menuAlign === 'right' && css`
        right:0;
    `};
    ${props => props.menuAlign === 'left' && css`
        left:0;
    `};
    box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.16);
    ul{
        padding:0 24px;
        margin:0;
        position: relative;
        li{
            width:100%;
            list-style:none;
            padding:${props => props.listPadding || '12px 24px 12px 0'};
            ${defaultFont}
            color: ${black600};
            border-bottom: 1px ${({listBorderLine}) => listBorderLine || 'solid'} ${black200};
            cursor: pointer;
            display:inline-flex;
            :last-child{
                border-bottom:none;
            }
            &>svg{
                width:20px;
                height:20px;
                vertical-align: top;
                margin-right:12px;
            }
            &.active{
                color: ${props => props.activeColor || blue300};
            }
        }
    }
`;

const HeadingStyle = styled.p`
    margin:0;
    padding:12px 16px 18px 16px;
    ${miniCaption.bold}
`;

export const DropDown = ({
    children,
    options = [],
    onClickAction=()=>{},
    menuWidth,
    activeColor,
    menuAlign = 'left',
    listBorderLine,
    heading,
    listPadding,
    menuStartGap,
    triggerAction = 'hover',
    disabled = false,
}) => {
    const [value, setValue] = useState({});
    const [isMenuOpen , setMenuOpen ] = useState(false);
    const onSelect = ({selectedValue}) => {
        setValue(selectedValue)

        if(selectedValue.onClick) {
            selectedValue.onClick();
        } else {
            onClickAction && onClickAction(selectedValue);
        }

        setTimeout(() => {
            triggerAction === 'click' && setMenuOpen(false);
        }, 400);
    };

    const dropDownRef = useOutsideClick(()=>setMenuOpen(false))
    const actionEvents = disabled ? null :
        triggerAction === 'click' ? 
        {
            onClick: () => setMenuOpen(!isMenuOpen),
            ref : dropDownRef
        }   
        :
        {
            onMouseEnter : ()=>setMenuOpen(true),
            onMouseLeave : ()=>setMenuOpen(false)
        }
    return (
        <DropDownStyle 
            menuStartGap = {menuStartGap}
            {...actionEvents}
        >
            {children}
            {isMenuOpen?
                <div className='menu'>
                    <MenuStyle 
                        menuWidth = {menuWidth} 
                        activeColor = {activeColor}
                        menuAlign = {menuAlign}
                        listPadding = {listPadding}
                        listBorderLine = {listBorderLine}
                    >
                        {heading && <HeadingStyle>{heading}</HeadingStyle>}
                        <ul>
                            {
                                options.map((option,index)=>(
                                        <li
                                            key={index}
                                            onClick={()=>onSelect({selectedValue:option})}
                                            className = {option.key === value.key ? 'active':''}
                                        >
                                            {option.icon}{option.label}
                                        </li>
                                    )
                                )
                            }
                        </ul>
                    </MenuStyle>
                </div>
            :null}
        </DropDownStyle>
    )
}
