import _isEmpty from "./lodashUtils/isEmpty";
import fetch from "node-fetch";
const timeoutDuration = 90000;

export function triggerSimpleAjax(
  requestURL,
  method = "POST",
  body = {},
  onSuccess,
  onFailure,
  customHeader = {},
  isFormData = false
) {
  
  let headerObject = {};
  if (!isFormData) {
    headerObject = {
      "Content-Type": "application/json",
    };
  }

  if (customHeader && !_isEmpty(customHeader)) {
    headerObject = {
      ...headerObject,
      ...customHeader,
    };
  }

  let headers = headerObject;
  
    const sessionId = localStorage.getItem('sessionid');
  
      if (sessionId ) {
        headers = {
          ...headers,
          Authorization: `sessionid ${sessionId}`
        }
      }
    
  

  const requestDetails = {
    method,
    mode: "cors",
    credentials: "include",
    headers,
    
  };
  if (method !== "GET" && !isFormData)
    requestDetails.body = JSON.stringify(body);
  if (isFormData) {
    requestDetails.body = body;
  }

  /**
   * This promise will start the network request and will return the data from the server
   * or throw errors in case the network request fails
   */
  const request = new Promise((resolve, reject) => {
    fetch(requestURL, requestDetails)
      .then((data) => {
        if (data.status === 200) {
          return data.text().then((text) => {
            return text ? JSON.parse(text) : {}
          })
        } else if (data.status === 401 && data.statusText === 'Unauthorized') {
          if (window.location.pathname !== "/login") {
            window.history.go('/');
            return reject(data);
          }
          return reject(data);
        } else if (data.status === 451) {
          return data
            .json()
            .then((result) => {
              localStorage.setItem('451', JSON.stringify(result));
              window.history.go('/login');
              return reject(result);
            })
            .catch((err) => {
              window.history.go('/login');
              return reject(err);
            });

        } else if (data.status === 403) {
          return data
            .json()
            .then((result) => reject(result))
            .catch((err) => reject(err));
        } else {
          return reject(data);
        }
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });

  /**
   * Will execute a reject action after the `timeoutDuration`
   * If it executes this will mark the network request as timed out
   */
  const networkTimeOut = (reject) => {
    return setTimeout(() => {
      let errorObject = {};
      try {
        errorObject = {
          status: `Request timed out!`,
          url: requestURL,
        };
      } catch (err) {
        errorObject = {};
      }
      reject(errorObject);
    }, timeoutDuration);
  };

  /**
   * Starts both the timeout and the network request
   * and resolves whichever executes first.
   */
  new Promise((resolve, reject) => {
    const timeoutId = networkTimeOut(reject);
    request
      .then((result) => {
        clearTimeout(timeoutId);
        onSuccess(result);
        resolve(result);
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        const { status } = error;
        if (error.text) {
          error.text().then((errorMessage) => {
            let errorValue = {
              ...error,
            };
            try {
              const errors = JSON.parse(errorMessage);
              const { detail } = errors;
              errorValue = {
                status,
                ...errorValue,
                ...errors,
                detail: (detail && detail.error) || detail || "",
              };
            } catch (err) {
              errorValue = {
                status,
                ...error,
              };
            }
            if (status === 201 || status === 204) {
              onSuccess && onSuccess(errorValue);
              resolve(errorValue);
            } else if (status === 403) {
              window.location.href = "/login";

              onFailure && onFailure(errorValue);
              reject(errorValue);
            } else {
              onFailure && onFailure(errorValue);
              reject(errorValue);
            }
          });
        } else {
          onFailure && onFailure(error);

          reject(error);
        }
      });
  });
}
