import React, { Component } from "react";
import ImageGallery from "./image_gallery/ImageGallery";
import { triggerSimpleAjax } from "../helpers/httpHelper";
import LoadingWrapper from "./LoadingWrapper";

class AppModalImages extends Component {
 state = {
   images_data: []
 }
  componentDidMount() {
    const pathName = window.location.pathname.split("/");
    const id = pathName[pathName.length - 2]
    this.setState({
      images_data: JSON.parse(localStorage.getItem(`images_data_${id}`))
    })

  }

  render() {
    const { images_data } = this.state;
    return (
      <LoadingWrapper>
        <ImageGallery
          images={images_data}
          noExit
        />
      </LoadingWrapper>
    )
  }
}

export default AppModalImages
