import React, { Fragment, Component } from 'react';
import { withLastLocation } from "react-router-last-location";
import BulkUploadWrapper from './dashboard/organization-roles-users/BulkUploadWrapper';

class IFSCVerification extends Component {

  triggerUploadAction = () => {}

  render() {
    return (
      <Fragment>
        <BulkUploadWrapper
          headerText={"Ifsc Verification"}
          // {...this.props}
          downloadParam={`&sub_project=${'id'}`}
          processAction={this.triggerUploadAction}
          enableSuccessAlert={false}
          uploadSuccessText={"Upload Process Initiated"}
          customElement={null}
          bulkUploadType={'ifsc_codes'}
        />
      </Fragment>
    )
  }
}

export default withLastLocation(IFSCVerification);
