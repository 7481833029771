import React from 'react';
import styled from 'styled-components';
import {defaultFont} from '../../../theme/typography';
import {black400, curiousBlue300, white} from '../../../theme/colors';
import {Button} from '../../../components/Button';
import {TitleStyle} from '../dashboardLayoutStyle';

export const FormBlockStyle = styled.section`
  margin: ${props => props.margin || '38px 0 0'};

  section {
    max-width: 100%;
    box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.16);
    min-height: 185px;
    padding: 35px;
  }
`
const HeadingStyle = styled(TitleStyle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;

  & > p {
    align-content: center;
    margin: 0;
  }
`;

const ActionButtonsStyle = styled.div`
  left: ${props => props.left};
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-top: 40px;

  > span {
    ${defaultFont}
    color: ${black400};
    margin-right: 32px;
  }
`;

export const FormContainer = ({
                                title = '',
                                children,
                                footerLocation = '46%',
                                actionButtons = true,
                                margin,
                                padding,
                                titleCornerElement,
                                rightSideTitleElement,
                                onClickAction = () => {
                                },
                                onCancelAction = () => {
                                },
                                subElement,
                                isClickDisabled = false,
                                isLoading = false,
                                actionButtonText = 'save'
                              }) => {
  return (
    <FormBlockStyle margin={margin} padding={padding}>
      {(title || rightSideTitleElement) ?
        <HeadingStyle>
          <p>{title}</p><p>{rightSideTitleElement}</p>
        </HeadingStyle>
        : null}
      {subElement}
      <section>
        {children}
        {actionButtons ?
          <ActionButtonsStyle left={footerLocation}>
            {onCancelAction && (
              <Button
                width='92px'
                borderRadius='2px'
                fontStyle={defaultFont}
                changeOnlyBgColor
                color={curiousBlue300}
                bgColor={white}
                onClick={onCancelAction}>Cancel</Button>
            )}

            <Button
              width='92px'
              borderRadius='2px'
              fontStyle={defaultFont}
              onClick={(e) => {
                if (!isClickDisabled) {
                  onClickAction(e)
                }
              }}
              isDisabled={isClickDisabled}
              isLoading={isLoading}
            >
              {actionButtonText}
            </Button>
          </ActionButtonsStyle>
          : null}
      </section>
    </FormBlockStyle>
  )
}
