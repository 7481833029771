import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { baseCaption, miniCaption, defaultFont } from '../../../theme/typography';
import { black600, black100, blue300} from '../../../theme/colors';
import CheckBoxList from '../../../components/CheckBoxList';
import DatePicker from '../../../components/DatePicker';
import { SwitchToggle } from '../../../components/SwitchToggle';
import { SwitchToogleWithLine } from '../question-management/components/SwitchToogleWithLine';

const FilterStyle = styled.div`
    width: ${props => props.width ? props.width : '60%'};
    ${baseCaption}
    font-weight:600;
    color: ${black600};
    position: relative;
`;

const Row = styled.div`
    display:flex;
    margin:16px 0;
    justify-content:space-between;
`;

const Header = styled.div`
    min-height:38px;
    padding:8px 0 4px;
    border-bottom: 1px solid #A5ADBB;
    display:flex;
    justify-content:space-between;
    position: relative;
    label{
        position:${props => props.showSearchBox ? 'absolute' : 'relative'};
        opacity:${props => props.showSearchBox ? '0' : '1'};
        z-index:0;
        transition: opacity 0.3s;
    }
    input{
        border: 1px solid #A5ADBB;
        border-radius: 62px;
        padding:0 10px 0 8px;
        height:24px;
        width: 0;
        flex:1;
        margin-right:6px;
        opacity:${props => props.showSearchBox ? '1' : '0'};
        z-index:1;
        transition: opacity 0.3s;
        outline:none;
    }
    span{
        svg{
            vertical-align: middle;
            margin-right: 6px;
            display:${props => props.showSearchBox ? 'none' : 'inline-block'};
        }
        strong{
            margin-right: 10px;
            background: ${black100};
            border-radius: 100%;
            padding: 4px 8px;
            ${miniCaption.semiBold};
            font-weight: 600;
        }
        span{
            ${defaultFont};
            font-weight: 600;
            cursor: pointer;
            width: 10px;
            height:20px;
            display: inline-block;
        }
    }
`

const FilterOption = styled.div`
    #filter-overflow::-webkit-scrollbar
    {
        width: 10px;
        background-color: #F5F5F5;
    }

    #filter-overflow::-webkit-scrollbar-thumb
    {
        background: #C4C4C4;
    }

    max-height: ${({ showOption, maxHeight = '200px' }) => showOption ? maxHeight : '0'};
    border-bottom: 1px solid #A5ADBB;
    overflow-y:hidden;
    opacity: ${({ showOption }) => showOption ? '1' : '0'};
    transition:opacity 0.4s, max-height 0.4s;    
`;

const ClearAllButton = styled.p`
    text-align:right;
    margin:3px 0;
    color: ${blue300};
    ${miniCaption.regular};
    cursor: pointer;
`;

export class FilterWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showOption: false,
            showSearchBox: false,
            filterOption: this.props.checkboxes || [],
            options: this.props.checkedList  || []
        }

        this.searchRef = React.createRef('');
    }

    componentDidUpdate = prevProps => {
        if(this.props.checkboxes !== prevProps.checkboxes) {
            this.setState({
                filterOption: this.props.checkboxes
            });
        }

        if(this.props.checkedList !== prevProps.checkedList) {
            this.setState({
                options: this.props.checkedList
            });
        }
    }

    onChangeFilterValue = ({ event, target }) => {
        let {options = []} = this.state;
        const isChecked = event.target.checked;

        if(isChecked) {
            options.push(target);
        } else {
            options.splice(options.indexOf(target),1);
        }

        this.setState({
            options
        }, () => {
            const { onChangeFilterValue, filterKey } = this.props;
            onChangeFilterValue && onChangeFilterValue(this.state.options, filterKey);
        })
    }

    searchFilter = (e) => {
        const { labelKey = 'name' } = this.props;

        const value = e.target.value.toLowerCase()
        let currentFilterOptions = []
        if ((value).trim() !== '') {
            currentFilterOptions = (this.props.checkboxes||[]).filter((option) => option[labelKey].toLowerCase().includes(value))
        } else {
            currentFilterOptions = this.props.checkboxes
        }
        this.setState({
            filterOption: currentFilterOptions
        })
    }

    closeSearchBox = (e) => {
        const { showSearchBox } = this.state;
        if (showSearchBox && e.target.value.trim() ==='') {
            this.setState({ showSearchBox: false })
        }
    }

    clearFilter = () => {
        this.setState({ options: [] },() => {
            const { onChangeFilterValue, filterKey } = this.props;
            onChangeFilterValue && onChangeFilterValue(this.state.options, filterKey);
        })
    }

    render() {
        const { showOption, showSearchBox, options = [], filterOption = []} = this.state;
        const { name, width, labelKey = 'name', valueKey = 'id', type, hideSearch = false, hideCount = false, filterKey, ...rest } = this.props;
        const selectedValueCount = Object.values(options).filter(value => Boolean(value)).length;
        const ShowOptionIcon = ({ children }) => (
          <span onClick={() => this.setState({ showOption: !showOption })}>
            {children}
          </span>
        );
        return (
            <FilterStyle
                ref={this.filterRef}
                width = {width}
            >
               {!rest.hideHeadingBar?
                <Header showSearchBox={showSearchBox} >
                    <label>{name}</label>
                    {
                        hideSearch ? null
                        :
                        <input
                            type='text'
                            placeholder=''
                            onChange={this.searchFilter}
                            ref={this.searchRef}
                            onBlur={this.closeSearchBox}
                        />
                    }
                    <span>
                        {
                            hideSearch ? null :
                            <SearchRoundedIcon
                                onClick={() => {
                                    this.searchRef.current.focus();
                                    this.setState({ showSearchBox: true })
                                }}
                            />
                        }
                        { hideCount ? null : <strong>{selectedValueCount}</strong> }
                        {showOption ?
                            <ShowOptionIcon>
                                &#8722;
                            </ShowOptionIcon>
                            :
                            <ShowOptionIcon>
                                +
                            </ShowOptionIcon>
                        }
                    </span>
                </Header>:null}
                {
                    type === 'daterange' ?
                        <FilterOption showOption={showOption} maxHeight={'300px'}>
                            <ClearAllButton onClick={this.clearFilter}>Clear</ClearAllButton>
                            <DatePicker showCalendar name={filterKey} {...rest} />
                        </FilterOption>
                    :
                    type === 'toggle' ?
                    <FilterOption showOption={showOption} maxHeight={'300px'}>
                          <label>{name}</label>
                          <SwitchToggle onToggle = {this.onChangeFilterValue} labelText={name} active={rest.value} margin= {'10px'} lineWidth={'2px'} {...rest} />
                    </FilterOption>
                        :
                    <FilterOption showOption={showOption}>
                        <ClearAllButton onClick={this.clearFilter}>Clear</ClearAllButton>
                        <CheckBoxList
                            filterName={name}
                            labelKey={labelKey}
                            valueKey={filterKey === "workflow_object__outlet__rsid" ? "rsid" : valueKey}
                            options={filterOption || []}
                            onChangeFilterValue = {this.onChangeFilterValue}
                            checkedList = {options}
                        />
                    </FilterOption>
                }
            </FilterStyle>
        )
    }
}
