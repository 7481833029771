import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GET_OUTLETS_LOGIC_SCORE } from "../../../helpers/constants/apiUrl";
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import capitializeFirstChar from "../../../helpers/utils/capitializeFirstChar";
import { black100, blue300, borderLight } from "../../../theme/colors";
import { xsBold } from "../../../theme/typeface";
import {
  baseCaption,
  defaultFont,
  microHeading,
} from "../../../theme/typography";

const Title = styled.p`
  ${microHeading.bold}
  color:${blue300};
  margin: 28px 0;
`;

const GroupContainer = styled.div`
  border: 1px solid ${borderLight};
  width: calc(100% - 24px);
`;

const Row = styled.div`
  background: ${({ bgColor }) => (bgColor ? black100 : "none")};
  border-bottom: 1px solid ${borderLight};
  padding: 16px 0;
  :last-of-type {
    border: none;
  }
  > span {
    ${({ bgColor }) => (bgColor ? defaultFont : baseCaption)};
    font-weight: ${({ header }) => (header ? xsBold : "normal")};
    display: inline-flex;

    :first-of-type {
      width: ${({ bgColor }) =>
        bgColor ? "calc(70% - 35px)" : "calc(70% - 1px)"};
      margin-left: ${({ bgColor }) => (bgColor ? "35px" : "10px")};

      > span {
        :first-of-type {
          width: 40%;
        }
        :last-of-type {
          width: 60%;
        }
      }
    }
    :last-of-type {
      width: 30%;
      width: ${({ bgColor }) => (bgColor ? "30%" : "calc(30% - 24px)")};
    }
  }
`;

const Content = styled.div`
  padding: 0 24px;
`;
//
export const ScoreDetail = ({ slug }) => {
  const [logicScoreData, setLogicScoreData] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (slug) {
      triggerSimpleAjax(
        GET_OUTLETS_LOGIC_SCORE(slug),
        "GET",
        {},
        (response) => {
          setLogicScoreData(response);
        },
        (err) => {
          if (err.status === 406) {
            delete err["status"];
            setError(err);
          }
        }
      );
    }
  }, [slug]);

  return (
    <>
      <Title>Score Detail</Title>
      {error
        ? Object.keys(error || {}).map((errorKey) => <p>{error[errorKey]}</p>)
        : logicScoreData.map((logicScore) => {
            const { group_name, score, row_wise_data } = logicScore;
            return (
              <GroupContainer>
                <Row bgColor={true} header={true}>
                  <span>{capitializeFirstChar(group_name)}</span>
                  <span>Score: {score}</span>
                </Row>
                <Content>
                  <Row header={true}>
                    <span>
                      {" "}
                      <span>{capitializeFirstChar(group_name)}</span>
                      <span>Rule Id</span>
                    </span>
                    <span>Score</span>
                  </Row>
                  {(row_wise_data || []).map((rowData) => {
                    const { sub_group_name, score, rule_id } = rowData;
                    return (
                      <Row>
                        <span>
                          <span>{capitializeFirstChar(sub_group_name)}</span>
                          <span>{rule_id}</span>
                        </span>
                        <span>{score}</span>
                      </Row>
                    );
                  })}
                </Content>
              </GroupContainer>
            );
          })}
    </>
  );
};
