import React from "react";
import styled from "styled-components";
import { black600, cinnabar300, green300 } from "../../../theme/colors";
import { SelectFilter } from "../components/SelectFilter";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CloseIcon from '@material-ui/icons/Close';
import { defaultFont } from "../../../theme/typography";
import { semiBold } from "../../../theme/typeface";
import { splitPhoneNumberFormat } from "../../../helpers/utils/splitPhoneNumberFormat";

const Wrapper = styled.section`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 4px;
    color: ${props => props.isSucess ? green300 : cinnabar300};
  }
  > span:first-child {
    ${defaultFont}
    font-weight:${semiBold};
  }
`;

const Block = styled.div`
  margin-bottom: 16px;
`;

export const VerifyPhoneNumberBlock = ({
  callingTrackerList,
  callingTrackerReasonsConfig = {},
  selectedCallingTrackerReasonDetails = {},
  onReasonSelect = () => {},
}) => {
  const reasonList = Object.keys(
    callingTrackerReasonsConfig
  ).map((reasonKey) => ({
    label: callingTrackerReasonsConfig[reasonKey],
    value: reasonKey,
  }));
  return (
    <Wrapper>
      {callingTrackerList.map((callingTracker) => (
        <Block>
          <Row isSucess={callingTracker.is_verified}>
            <span>{splitPhoneNumberFormat(callingTracker.phone_number)}</span>
            {callingTracker.is_verified ? (
              <span>
                <VerifiedUserIcon />
                Verified
              </span>
            ) : <span>
            <CloseIcon />
            Not Verified
          </span>}
          </Row>
          <SelectFilter
            props={{
              placeholder: "Enter the reason",
              onChange: (data) => {
                onReasonSelect({
                  [`${callingTracker.id}`]: {
                    phone_number: callingTracker.phone_number,
                    reason: data.value,
                    linked_outlets: callingTracker.linked_outlets
                  },
                });
              },
              options: reasonList,
              value: selectedCallingTrackerReasonDetails[`${callingTracker.id}`]
                ? reasonList.filter(
                    (option) =>
                      selectedCallingTrackerReasonDetails[`${callingTracker.id}`]
                        .reason === option.value
                  )
                : "",
            }}
            noOptionMessage="No data available"
            customStyles={{
              height: "48px",
              border: "1px solid #DEDEDE",
              padding: "0 8px",
              borderRadius: "2px",
            }}
            containerStyles={{
              width: "100%",
              margin: "8px auto",
            }}
            dropdownColor={black600}
          />
        </Block>
      ))}
    </Wrapper>
  );
};
