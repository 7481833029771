import React, { useEffect, useState } from "react";
import {
  PageDescriptionStyle,
  FormStyle,
  FormSubElementStyle,
} from "../../login/components/RightSideContainer";
import { SectionTitle } from "../../login/components/SectionTitle";
import { TextBox } from "../../../components/TextBox";
import { Button } from "../../../components/Button";
import { EKYC_VERIFY_OTP_TEXT } from "./constant";
import OtpVerifyAction from "../../../actions/ekyc/login/verifyOtp";
import OtpSentAction from "../../../actions/ekyc/login/otpSent";
import useStore from "../../../appZustand/store";
import styled from "styled-components";
import {useHistory} from 'react-router-dom'
import ROUTES, { EKYC } from "../../../helpers/constants/routes";
import getUserInfo from "../../../actions/getUserInfo";
import JSEncrypt from "node-jsencrypt";
import {is_encrypt, PUBLIC_RSA_KEY} from "../../../app-config";
import { MobileTextBox } from "../../../components/MobileTextBox";


const LinkText = styled.span`
font-weight: 500;
font-size: 16px;
line-height: 140%;
text-decoration-line: underline;
color: #3E96C8;
cursor:pointer;
`;

const Text = styled.span`
font-weight: 400;
font-size: 16px;
line-height: 140%;
color: #3E96C8;
cursor:pointer;
`;

const VerifyOtp = () => {
  const {mobileNo}=useStore((state)=>state)
  const history = useHistory()
  const mobileView=history.location.state.mobileView;

  const [data, setData] = useState({
    otp: null,
    isError: false,
    errorObj: {},
    isLoading: false,
    resendCountdown:"",
  });

  const onChangeAction = (e) => {
    const { name, value } = e.target;

    if(value.length > 6){
      return false
    }
    setData({ ...data, [name]: value });
  };

  useEffect(()=>{

      let { resendCountdown } = data;

       const timer = resendCountdown &&  setInterval(() => setData({...data,resendCountdown:resendCountdown - 1}), 1000);

      return () => clearInterval(timer);
  },[data])

  const triggerOTP = () => {
    OtpSentAction({login_username:mobileNo,method_of_login:"phone_otp",purpose:"user_login"}).then((res)=>{
      setData({...data,isLoading:false,resendCountdown:30})
      console.log(res)
    })
  };

  const encrypt_password = (string_to_encrypt) => {
    if (is_encrypt) {
      let encrypt = new JSEncrypt();
      encrypt.setPublicKey(PUBLIC_RSA_KEY);
      return encrypt.encrypt(string_to_encrypt);
    } else {
      return string_to_encrypt
    }
  };

  const verifyAction = () => {
    setData({...data,isLoading:true})
    OtpVerifyAction(
      {
        login_username:mobileNo,
        method_of_login:"phone_otp",
        password: encrypt_password(data.otp),
        platform:"my_track"
      })
      .then((res)=>{
        setData({...data,isLoading:false})
        localStorage.setItem('sessionid', res.sessionid);
        localStorage.setItem('userName', res.username);
        localStorage.setItem('reload', "reload");
        history.push(EKYC.DASHBOARD)
      })
      .catch((err)=>{
        setData({...data,isLoading:false,isError:true,errorObj:{...err}})
      })
  };

  const backHandler=()=>{
      history.push(EKYC.LOGIN)
  }

  return (
    mobileView?( <PageDescriptionStyle>
      <SectionTitle {...EKYC_VERIFY_OTP_TEXT} subText={mobileNo} />
      <FormStyle>
        <MobileTextBox
          labelName="OTP"
          name={"otp"}
          type="number"
          // check="ekycInput"
          value={data.otp || ""}
          onChange={onChangeAction}
          error={data.isError}
          errorMessage={data.errorObj?.code || data.errorObj?.detail}
          subElement={
            <FormSubElementStyle onClick={triggerOTP}>
              <LinkText style={{cursor: data.resendCountdown ? 'not-allowed' : 'pointer'}}
              >

              {`Resend ${data.resendCountdown ? `${data.resendCountdown}'s` : ''}`}

              </LinkText>
            </FormSubElementStyle>
          }
        />
        <Button type="submit" margin="25px 12px 16px 0px !important" onClick={verifyAction} isLoading={data.isLoading} width='100%' fontStyle={{fontSize: "14px",marginTop:"35px"}} boxHeight="35px" boxRadius="5px">
          Verify OTP
        </Button>
          <Text onClick={backHandler}>
                Go back to login screen
          </Text>
      </FormStyle>
    </PageDescriptionStyle>):( <PageDescriptionStyle>
      <SectionTitle {...EKYC_VERIFY_OTP_TEXT} subText={mobileNo} />
      <FormStyle>
        <TextBox
          labelName="OTP"
          name={"otp"}
          type="number"
          check="ekycInput"
          value={data.otp || ""}
          onChange={onChangeAction}
          error={data.isError}
          errorMessage={data.errorObj?.code || data.errorObj?.detail}
          subElement={
            <FormSubElementStyle onClick={triggerOTP}>
              <LinkText style={{cursor: data.resendCountdown ? 'not-allowed' : 'pointer'}}
              >

              {`Resend ${data.resendCountdown ? `${data.resendCountdown}'s` : ''}`}

              </LinkText>
            </FormSubElementStyle>
          }
        />
        <Button type="submit" margin="25px 12px 16px 0px !important" onClick={verifyAction} isLoading={data.isLoading} >
          Verify OTP
        </Button>
          <Text onClick={backHandler}>
                Go back to login screen
          </Text>
      </FormStyle>
    </PageDescriptionStyle>)
   
  );
};

export default VerifyOtp;
