import styled, { css } from "styled-components";

const ImageBlockWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-right: 4rem;
`

const ImageWrapper = styled.img`
  width: 350px;
  height: 300px;
  object-fit: contain;
  transform: rotate(${props => props.degree}deg);
`

const OverallBlockWrapper = styled.div`
  display: flex;
  margin: 30px 0;
  flex-wrap: wrap;
`

const FigureImageWrapper = styled.figure`
  & img:hover {
    opacity: 0;
  }
  img {
    transition: opacity .5s;
    display: block;
    width: 100%;
  }
  background-position: 50% 50%;
  position: relative;
  width: 500px;
  overflow: hidden;
  cursor: zoom-in;
}
`

export { ImageWrapper, ImageBlockWrapper, OverallBlockWrapper, FigureImageWrapper }
