import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { white } from '../../theme/colors';
import { SelectFilter } from '../../pages/dashboard/components/SelectFilter';
import themeSizes from "../../theme/themeSizes";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  height: 38px;

  z-index: 10;

  box-shadow: 0px 10px 24px rgba(91,127,220,0.16);
  background-color: ${white};
  margin-left: 12px;
  
  & + & {
    margin-left: 12px;
  }
`

const NavIconWrapper = styled.span`
  display: inherit;
  align-items: inherit;
  cursor: pointer;

  ${props => props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `
  }
`

const Pagination = ({ count, current, totalPages, onNextPageClick, onPrevPageClick, pageSize = 15, onPageSizeChange }) => {
  const paginationOptions = [
    { value: 15, label: 15 },
    { value: 30, label: 30 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 500, label: 500 },
    { value: 1000, label: 1000},

  ];

  let isCheckRow = true;
  if (count < 15) {
    isCheckRow = true;
  } else {
    isCheckRow = false;
  }

  return (
    <Fragment>
      Show{' '}
      <Wrapper>
        <SelectFilter
          props={{
            menuPlacement: 'top',
            width: '70px',
            options: paginationOptions,
            name: 'page-size',
            placeholder: "- -",
            onChange: onPageSizeChange,
            value: paginationOptions.filter(({ value }) => value === pageSize),
            isSearchable: false,
            isDisabled: isCheckRow,
            noBorder: true
          }}
          height={'38px'}
        />
      </Wrapper>
      <Wrapper>
        <NavIconWrapper
          disabled={!onPrevPageClick}
          onClick={onPrevPageClick ? () => onPrevPageClick() : null}>
          <NavigateBeforeIcon />
        </NavIconWrapper>
        Page {current || 1} of {totalPages}
        <NavIconWrapper
          disabled={!onNextPageClick}
          onClick={onNextPageClick ? () => onNextPageClick() : null}>
          <NavigateNextIcon />
        </NavIconWrapper>
      </Wrapper>
    </Fragment>
  )
}

export default Pagination
