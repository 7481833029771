import { triggerSimpleAjax } from '../../helpers/httpHelper';
import { GET_VIEW_MOBILE_TARGET } from '../../helpers/constants/apiUrl';

export default function getMobileViewTarget(req = '') {
    return new Promise((res, rej) => {
        const url = `${GET_VIEW_MOBILE_TARGET}${req}?no-pagination`;

        triggerSimpleAjax(
            url ,
            'GET',
            {},
            (response) => {
                res(response);
            },
            error => {
                rej(error);
            }
        );
    });
}

