import { triggerSimpleAjax } from '../../helpers/httpHelper';
import { GET_RETAILER_EDIT } from '../../helpers/constants/apiUrl';

export function getRetailerRequests(req = '') {
    return new Promise((res, rej) => {
        const url = `${GET_RETAILER_EDIT}?workflow_object=${req}`;
        triggerSimpleAjax(
            url,
            'GET',
            {},
            (response) => {
                res(response);
            },
            (error) => {
                rej(error);
            }
        );
    });
}

export function getRetailerRequestData(req_id = '', req = '') {
    return new Promise((res, rej) => {
        const url = `${GET_RETAILER_EDIT}?workflow_object=${req}&req_id=${req_id}`;
        triggerSimpleAjax(
            url,
            'GET',
            {},
            (response) => {
                res(response);
            },
            (error) => {
                rej(error);
            }
        );
    });
}
