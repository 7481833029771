import React, { Component, Fragment } from "react";
import queryString from "query-string";
import ReactTable from "../../../components/table";
import styled from "styled-components";
import { microHeading } from "../../../theme/typography";
import getOutlets from "../../../actions/outlet/getOutlets";
import { GET_COMMON_DATA_CACHEED } from "../../../helpers/constants/apiUrl";
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import appendToSearch from "../../../helpers/utils/appendToSearch";
import {
  blue300,
  green300,
  curiousBlue300,
  white,
  cinnabar300,
} from "../../../theme/colors";
import { Button } from "../../../components/Button";
import { StatusTagStyle } from "../../../components/StatusTagStyle";
import DateToDisplay from "../../../helpers/utils/DateToDisplay";
import isEmpty from "../../../helpers/lodashUtils/isEmpty";
import { ACTION_STATE_KEY } from "../../../helpers/constants/workflowKeys";
import getOutletActions from "../../../actions/outlet/getOutletActions";
import { getActionList } from "./helper/getActionList";
import { UpdateStatusModal } from "./modals/UpdateStatusModal";
import { withLastLocation } from "react-router-last-location";
import { connect } from "react-redux";

const Title = styled.p`
  ${microHeading.regular}
  color:${blue300};
  margin:0;
  padding-left:34px;
`;

class PayoutDetailsScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payoutDetails: [],
      totalPage: 0,
      previousPage: 0,
      nextPage: 0,
      count: 0,
      isLoading: true,
      searchValue: "",
      order: {
        id_sortKey: "ASC",
      },
      filters: {},
      filtersApplied: false,
      actions: {},
      actionList: [],
      openUpdateModal:false,
      fetchingFilters:false,
      selectedOutletDetails:[]
    };
  }

  componentDidMount() {
    const {
      location,
      history,
      subProjectQueryData,
      workflow_permission,
    } = this.props;

    if (location.search) {
      const state = { ...this.state };
      const qs = queryString.parse(location.search);

      if (qs.search) {
        state.searchValue = qs.search;
      }
      const filterKeys = [
        "program",
        "related_workflow_object__client_payout_month",
        "related_workflow_object__rs_payout_month"
      ];
      filterKeys.map((item) => {
        if (qs[item]) {
          let tempState = [];
          if (typeof qs[item] === "string") {
            if (item == "related_workflow_object__rs_payout_month" || item == "related_workflow_object__client_payout_month") {
              tempState = [qs[item]];
            } else {
              tempState = [Number(qs[item])];
            }
          } else {
            if (item == "related_workflow_object__rs_payout_month" || item == "related_workflow_object__client_payout_month") {
              tempState = qs[item];
            } else {
              tempState = (qs[item] || []).map((a) => Number(a));
            }
          }

          state.filters[item] = tempState;
          state.filtersApplied = true;
        }
      });

      this.setState(state);
    }

    getOutletActions()
      .then((res) => {
        const {
          allowed_user_actions_config,
          allowed_user_special_actions_config,
        } = res;

        const actions = {
          allowed_user_actions_config: Array.isArray(
            allowed_user_actions_config
          )
            ? allowed_user_actions_config[0]
            : allowed_user_actions_config,
          allowed_user_special_actions_config:
            allowed_user_special_actions_config || {},
        };
        this.setState({ actions });
      })
      .catch((error) => console.log(error));

    this.getPlotData(location.search);
  }

  componentDidUpdate(prevProps) {
    const { location, isFilterOpen } = this.props;
    if (location.search !== prevProps.location.search) {
      this.getPlotData(location.search);
    }

    if(isFilterOpen !== prevProps.isFilterOpen && isFilterOpen) {
      this.getFilterData()
    }
  }

  getSelectedOutletActions = (selectedOutletDetails) => {
    const { actions } = this.state;
    const optionList = getActionList({
      actions,
      selectedOutletDetails,
      checkSpecialAction: false,
    });
    this.setState({ actionList: optionList,openUpdateModal:true,selectedOutletDetails });
  };

  // For Filter data
  getFilterData = () => {
    this.setState({
      fetchingFilters: true
    }, () => {
      triggerSimpleAjax(
        GET_COMMON_DATA_CACHEED,
        "POST",
        {
          objects: ["programs", "workflow_object_client_payout_months","workflow_object_rs_payout_months"],
        },
        (res) => {
          this.setState({
            ...res,
            fetchingFilters: false
          });
        },
        (err) => {
          console.log(err);

          this.setState({
            fetchingFilters: false
          });
        }
      );
    })
  };

  getPlotData = (requestParams = "") => {
    const { related_sibling_instances, outletDetails } = this.props;

     let params = `?${appendToSearch(requestParams, {
        id: [...(related_sibling_instances || []), outletDetails.id],
        or_filter_keys: "id",
       show_invoice_details: "true"
      })}`;

      params = params.length ? `${params}&no-pagination` : params;

      getOutlets(params)
        .then((response) => {
          const {
            count,
            next,
            previous,
            results,
            total_number_of_page,
          } = response;
          this.setState({
            payoutDetails: results,
            nextPage: next,
            previousPage: previous ? previous : 0,
            count,
            isLoading: false,
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            isLoading: false,
          });
        });
  };

  setOrdering = (name, type) => {
    this.setState(
      {
        order: {
          [name]: type,
        },
      },
      () => {
        const { history, location } = this.props;

        history.push({
          route: "/",
          state: { ...history.location.state },
          search: appendToSearch(location.search, {
            ordering: type === "ASC" ? name : `-${name}`,
          }),
        });
      }
    );
  };

  onChangeFilterValue = (e, target) => {
    const { filters } = this.state;

    filters[target] = e;

    this.setState({ filters });
  };

  onApplyFitler = () => {
    const { filters } = this.state;
    const { history, location } = this.props;

    this.setState({ isLoading: true, filtersApplied: true });

    history.push({
      route: "/",
      state: { ...history.location.state },
      search: appendToSearch(location.search, {
        ...filters,
      }),
    });
  };

  onClearAllFilter = () => {
    if (!isEmpty(this.state.filters)) {
      this.setState({
        filters: {},
        isLoading: true,
        filtersApplied: false,
      });
    }
    const { history } = this.props;
    history.push({
      route: "/",
      state: { ...history.location.state },
      search: "",
    });
  };

  setUpdateModalOpen = () => {
    this.setState({openUpdateModal:!this.state.openUpdateModal})
  }


  render() {
    const {
      payoutDetails,
      nextPage,
      previousPage,
      count,
      isLoading = false,
      searchValue,
      order,
      filters,
      filtersApplied,
      programs,
      workflow_object_client_payout_months,
      openUpdateModal,
      actionList,
      workflow_permission,
      selectedOutletDetails,
      workflow_object_rs_payout_months,
      fetchingFilters
    } = this.state;
    const { history } = this.props;

    return (
      <Fragment>
      <ReactTable
        current={previousPage ? previousPage + 1 : 1}
        perPage={15}
        total={count}
        previousPage={previousPage}
        nextPage={nextPage}
        leftHeaderElements={<Title>Payout Details</Title>}
        rightHeaderElementsBeforeIcons={true}
        searchValue={searchValue}
        onOrderChange={this.setOrdering}
        searchOnChange={this.searchOnChange}
        onPageChange={this.onPageChange}
        showSearch={true}
        data={payoutDetails}
        loading={isLoading}
        showFilterLoading={fetchingFilters}
        showTotalRecordText={true}
        columns={[
          {
            name: "RS Payout Cycle",
            key: "related_workflow_object.rs_payout_month",
            width: "140px",
          },
          {
            name: "Program Name",
            key: "program.name",
            width: "140px",
          },
          {
            name: "Client Payout Cycle",
            key: "related_workflow_object.client_payout_month",
            width: "160px",
          },
          {
            name: "Amount",
            key: "related_workflow_object.amount_to_pay",
            width: "140px",
          },
          // {
          //   name: "Status",
          //   key: "status",
          //   width: "140px",
          //   render: (value) => (
          //     <StatusTagStyle
          //       bordered
          //       borderColor={
          //         (value || "").toLowerCase() === "active"
          //           ? green300
          //           : cinnabar300
          //       }
          //       size="small"
          //     >
          //       {value}
          //     </StatusTagStyle>
          //   ),
          // },
          {
            name: "Payment Remarks",
            key: "related_workflow_object.payment_remarks"
          },
          {
            name: "Payment Status",
            key: "related_workflow_object.payment_status"
          },
          {
            name: "Payment Date",
            key: "related_workflow_object.payment_date",
            width: "140px",
            render: (date) => {
              return date ? (
                <Fragment>
                  <DateToDisplay date={date} />
                </Fragment>
              ) : (
                ""
              );
            },
          },
          {
            name: "UTR",
            key: "related_workflow_object.utr_number",
            width: "140px",
          },
          {
            name: "",
            width: "240px",
            sortable: false,
            render: (data, item) => {
              const { workflow_state, invoice_data } = item
              let file;
              if(invoice_data){
               file = invoice_data.file
              }
              if(workflow_state === ACTION_STATE_KEY.PAYMENT_SUCCESSFUL && invoice_data){
                return (
                  <Fragment>
                    <Button
                      size="medium"
                      color={curiousBlue300}
                      changeOnlyBgColor
                      bgColor={white}
                      onClick={() => window.open(file, "_blank") }
                    >
                      Download Invoice
                    </Button>
                  </Fragment>
                )
              }
            }
          },
          {
            name: "",
            key: "related_workflow_object",
            width: "200px",
            sortable: false,
            render: (data,item) => {
              const { workflow_state, id } = data
              if (workflow_state.includes(ACTION_STATE_KEY.PAYOUT_PLOTTED)) {
                return (
                  <Fragment>
                    <Button
                      size="medium"
                      color={curiousBlue300}
                      changeOnlyBgColor
                      bgColor={white}
                      onClick={() =>
                        this.getSelectedOutletActions([ {
                          subProject: (item.sub_project || {}).type,
                          workflow_state: workflow_state,
                          workflowOutletId: id,
                        }])
                      }
                    >
                      Update Status
                    </Button>
                  </Fragment>
                );
              }
            },
          },

        ]}
        order={order}
        showFilter={true}
        filterList={[
          {
            name: "Program Name",
            key: "program",
            filterKey: "program",
            checkboxes: programs,
            checkedList: filters.program || [],
            onChangeFilterValue: this.onChangeFilterValue,
          },
          {
            name: "Client payout cycle",
            key: "related_workflow_object__client_payout_month",
            filterKey: "related_workflow_object__client_payout_month",
            checkboxes: (workflow_object_client_payout_months || []).reduce(
              (acc, type) => {
                if (type) {
                  acc.push({ name: type, id: type });
                }
                return acc;
              },
              []
            ),
            checkedList:
              filters.related_workflow_object__client_payout_month || [],
            onChangeFilterValue: this.onChangeFilterValue,
          },
          {
            name: "RS Payout cycle",
            key: "related_workflow_object__rs_payout_month",
            filterKey: "related_workflow_object__rs_payout_month",
            checkboxes: (workflow_object_rs_payout_months || []).reduce(
              (acc, type) => {
                if (type) {
                  acc.push({ name: type, id: type });
                }
                return acc;
              },
              []
            ),
            checkedList: filters.related_workflow_object__rs_payout_month|| [],
            onChangeFilterValue: this.onChangeFilterValue,
          },
        ]}
        filtersApplied={filtersApplied}
        onApplyFitler={this.onApplyFitler}
        onClearAllFilter={this.onClearAllFilter}
      />
       {openUpdateModal ? (
        <UpdateStatusModal
           modalHeading='Payment Confirmation'
           title='Add transaction details below'
          setModalOpen={openUpdateModal}
          modalAction={this.setUpdateModalOpen}
          getOutletDetails={this.getPlotData}
          history={history}
          actionList={actionList}
          workflow_permission={workflow_permission}
          selectedOutletRows={[
            (selectedOutletDetails[0] || {}).workflowOutletId,
          ]}
        />
      ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return {
    isFilterOpen: app.isFilterOpen
  };
};

export default withLastLocation(connect(mapStateToProps, null)(PayoutDetailsScreen));
