import React, { useState, useEffect, Fragment } from "react";
import {
  black300,
  green300,
  black500,
  black600,
  black700,
  cinnabar300,
  white,
  cinnabar100,
  cinnabar400,
  curiousBlue300,
} from "../../../../theme/colors";
import { Modal } from "../../../../components/Modal";
import styled, { keyframes, css } from "styled-components";
import { font, baseCaption } from "../../../../theme/typography";
import { Button } from "../../../../components/Button";
import { FormRow } from "../../components/FormRow";
import DatePicker from "../../../../components/DatePicker";
import { DashboardTextBox } from "../../components/DashboardTexBox";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { SelectFilter } from "../../components/SelectFilter";
import {
  GET_OUTLETS_ACTIONS_SAVE,
} from "../../../../helpers/constants/apiUrl";
import { triggerSimpleAjax } from "../../../../helpers/httpHelper";
import CheckBoxSingle from "../../../../components/CheckBoxSingle";

const ContentStyle = styled.div`
  margin: 12px 20px;
  ${baseCaption}
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  ${(props) =>
    props.flexCenter &&
    css`
      align-items: center;
    `}

  b {
    font-size: ${font.smallmedium};
    line-height: 125%;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: ${black500};
    margin: 8px 0 30px;
  }

  button {
    align-self: ${(props) => (props.buttonAlignCenter ? "center" : "flex-end")};
  }
`;

const AgreeStatementWrapper = styled.b`
  width: 263px;
  margin: auto;
  text-align: center;
`;

const STATUS_STYLE = `
display:flex;
flex-direction:column;
align-items: center;
b{
    margin-top:16px;
    color:${black700};
}
`;
const CheckMark=`
position: absolute;
top: 0;
left: 0;
height: 25px;
width: 25px;
background-color: #eee;
`
const SuccessWrapper = styled.div`
  ${STATUS_STYLE}
  button {
    margin-top: 32px;
  }
`;
const ErrorWrapper = styled.div`
  ${STATUS_STYLE}
  button {
    margin-top: 20px;
  }
`;

const ErrorTextWrapper = styled.div`
  background: ${cinnabar100};
  border-radius: 2px;
  padding: 12px 16px;
  color: ${cinnabar400};
  margin: 20px 0;

  p {
    margin: 2px 0;
    ${baseCaption}
    color:${cinnabar400};
  }
`;

const Spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const ProgressLoader = styled.div`
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 50px;
  height: 50px;
  position: relative;
  /* top: 20%; */
  animation: ${Spin} 2s linear infinite;
`;

const RowWrapper = styled.div`
  display: flex;
  > div,
  svg {
    align-self: center;
  }
  svg {
    margin-left: 16px;
  }
`;

// const getReportingManagers = ({ setManagerList, workflow_permission }) => {
//   triggerSimpleAjax(
//     `${GET_LITE_USERS}?no-pagination&role__permissions__slug=${workflow_permission}`,
//     "GET",
//     {},
//     ({ results }) => {
//       const options = results.map(({ id, name, type }) => ({
//         value: id,
//         label: name,
//       }));
//       setManagerList(options);
//     },
//     (err) => {
//       console.log(err);
//     }
//   );
// };

const selectFieldProps = {
  noOptionMessage: "No data available",
  customStyles: {
    // height: "48px",
    border: "1px solid #DEDEDE",
    padding: "0 8px",
    borderRadius: "2px",
  },
  containerStyles: {
    width: "424px",
    margin: "8px 16px 22px 0",
  },
  dropdownColor: black600,
};

// const getStatusOptionList = ({ actions }) => {
//   const actionList = Object.keys(actions);

//   const actionNextWorkflows = actionList.map(
//     (action) => actions[action]["next_workflow_config"]
//   );
//   const actionOptions = actionNextWorkflows.reduce(
//     (listOfWorkflows, workflow) => {
//       const workflowKeys = Object.keys(workflow);
//       listOfWorkflows.push(
//         ...workflowKeys.reduce((worflowAction, key) => {
//           const isWorkflowExist = listOfWorkflows.some(
//             (option) => option.name === key
//           );
//           if (!isWorkflowExist) {
//             worflowAction.push({
//               label: workflow[key]["display_name"],
//               name: key,
//               value: workflow[key]["extra_data"],
//             });
//           }
//           return worflowAction;
//         }, [])
//       );
//       return listOfWorkflows;
//     },
//     []
//   );

//   return actionOptions;
// };

// const getReasonListOptions = ({ actions, selectedStatus }) => {
//   const actionList = Object.keys(actions);
//   return actionList.reduce((listArray, workflowName) => {
//     const actionFieldData = actions[workflowName];
//     const isStatusPresent = actionFieldData["next_workflow_statuses"].includes(
//       selectedStatus
//     );
//     if (isStatusPresent) {
//       listArray.push({
//         label: "Name to get",
//         value: workflowName,
//       });
//     }
//     return listArray;
//   }, []);
// };

export const UpdateStatusModal = ({
  title,
  modalAction,
  selectedAction,
  getOutletDetails,
  history,
  workflow_permission,
  subProjectType,
  workflowOutletId,
  related_workflow_reasons_description,
  setSaveClickedData = () => { },
  actionList = [],
  selectedOutletRows = [],
  selectedCallingTrackerReasonDetails,
  setWorkflowStateChanged = () => { },
  modalHeading = "",
  callback,
  rsidList
}) => {
  const [isLoading, setLoading] = useState(null);
  const [processCompleted, setProcessCompleted] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedReason, setSelectedReason] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [selectedDateFields, setSelectedDateField] = useState({});
  const [textData, setTextData] = useState({});
  const [showRSID, setShowRSID] = useState(false);
  const [rejected, setRejected] = useState({
    status: false,
    reason: ''
  })
  const [RSID, setRSID] = useState([]);

  const handleChange = e => {
    const { value, checked } = e.target;
    if (checked) {
      // push selected value in list
      setRSID(prev => [...prev, value]);
    } else {
      // remove unchecked value from the list
      setRSID(prev => prev.filter(x => x !== value));
    }
  }
  const rejectedList = ['not_workable_client_rejected', 'client_confirmation_rejected', 'wip_client_rejected']
  const getExtraData = () => {
  

    if (selectedStatus.name === "wip_with_reasons") {
      const related_workflow_reasons = selectedReason.related_workflow_reasons.map(ele => ele.value + ";").join("")
      return {
        related_workflow_reasons,
        ...selectedDateFields,
        ...textData
      }
    }
    return {
      ...selectedReason,
      ...selectedDateFields,
      ...textData,
    };
  };

  const onSaveAction = () => {
    setLoading(true);
   
const selectedCallingTrackerReasonDetailsvalues = Object.values(
  selectedCallingTrackerReasonDetails
);
console.log('selectedCallingTrackerReasonDetailsvalues',selectedCallingTrackerReasonDetailsvalues);
var phoneArray = selectedCallingTrackerReasonDetailsvalues.map(({phone_number, reason}) => {
  return {phone_number, reason};
})
var mobArray = phoneArray.map(item => {
  return {
    mobile_number: item.phone_number,
    reason: item.reason
  };
});
    // const reqData = {
    //   action_type: selectedStatus.name,
    //   object_ids: selectedOutletRows,
    //   extra_data: {
    //     ...getExtraData(),
    //     // related_workflow_reasons: selectedReason,
    //   },
    //   related_workflow_reasons_description: related_workflow_reasons_description,
    //   select_all: false
    // };
    const reqData = {
      action_type: selectedStatus.name,
      object_ids: selectedOutletRows,
      extra_data: {
        ...getExtraData(),
        "phones": mobArray,
        // related_workflow_reasons: selectedReason,
        related_workflow_reasons_description: textData["related_workflow_reasons_description"]
      },
      // related_workflow_reasons_description: rejected.reason,
      select_all: false,
      chain_rsids:RSID
    };

    triggerSimpleAjax(
      `${GET_OUTLETS_ACTIONS_SAVE}`,
      "POST",
      reqData,
      (response) => {
        setLoading(false);
        setProcessCompleted(true);
        setSaveClickedData && setSaveClickedData({});
        setWorkflowStateChanged && setWorkflowStateChanged(true);
        if (callback) {
          callback()
        }

      },
      (error) => {
        if (error.status === 400) {
          setErrorMessages(error);
          setLoading(null);
        } else {
          setProcessCompleted(false);
        }
      }
    );
  };

  const onChangeSetRejectedStatus = (name) => {
    if (rejectedList.includes(name)) {
      setRejected({
        ...rejected,
        status: true
      })
    }
  }

  const getDateText = (data) => {
    if (data) {
      const startDateArray = new Date(data).toString().split(" ");

      return `${startDateArray[2]} ${startDateArray[1]} ${startDateArray[3]} `;
    }
    return "";
  };

  const getSelectedDates = ({ name, dates }) => {
    setSelectedDateField({
      ...selectedDateFields,
      [name]: dates,
    });
    setErrorMessages({});
  };

  const isError = Object.keys(errorMessages).length;
  const statusOptions = actionList;
  const extra_data = Object.keys(selectedStatus.value || {});

  let reasonoptions = [];
  if (extra_data.length && selectedStatus.value[extra_data[0]]) {
    const data = selectedStatus.value[extra_data[0]];
    reasonoptions = Object.keys(data || {}).map((reason) => ({
      label: data[reason],
      value: reason,
      name: extra_data[0],
    }));
  }

  const isExtraDataAvailable = true;

  // const flexView =  isLoading || processCompleted !== null || processCompleted || selectedStatus.noActionsAvailable
  return (
    <Modal
      displayFlex={modalHeading}
      top={modalHeading ? "0" : '16%'}
      withHeader
      modalWidth="520px"
      modalHeight="353px"
      closeIconColor={black300}
      closeAction={() => {
        if (processCompleted) {
          const { location } = history;
          getOutletDetails();
          modalAction();
        } else {
          modalAction(selectedAction);
        }
      }}
    >
      <ContentStyle
        // buttonAlignCenter={!isExtraDataAvailable}
        flexCenter={isLoading || processCompleted}
      >
        {selectedStatus.noActionsAvailable || !statusOptions.length ? (
          <AgreeStatementWrapper>No Actions Available</AgreeStatementWrapper>
        ) : (
          <Fragment>
            {isLoading === null ? (
              <Fragment>
                <b>{modalHeading || "Update Status"}</b>
                <p>{title}</p>
                <SelectFilter
                  props={{
                    placeholder: " - -  Select Status - - ",
                    onChange: (data) => {
                      onChangeSetRejectedStatus(data.name)
                      setSelectedStatus(data);
                      setErrorMessages({});
                    },
                    options: statusOptions,
                    value: selectedStatus
                      ? statusOptions.filter(
                        (manager) => selectedStatus.name === manager.name
                      )
                      : "",
                  }}
                  {...selectFieldProps}
                />
              
                {Object.keys(selectedStatus.value || {}).length
                  ? Object.keys(selectedStatus.value).map((fieldName) => {
                    if (
                      fieldName.includes("name") ||
                      fieldName.includes("number") ||
                      fieldName.includes("remarks") ||
                      fieldName.includes("amount")
                    ) {
                      return (
                        <FormRow
                          name=""
                          leftSideWidth={"0"}
                          margin={"0 0 22px"}
                          rightElement={
                            <DashboardTextBox
                              type={
                                // Special Condition to enable utr_number as alphanumeric
                                fieldName.toLowerCase().includes("utr_number") ? "text" :
                                fieldName.toLowerCase().includes("number") ||
                                  fieldName.includes("amount")
                                  ? "number"
                                  : "text"
                              }
                              value={textData[fieldName]}
                              placeholder={` - - Enter ${fieldName
                                .split("_")
                                .join(" ")} - -  `}
                              boxWidth="424px"
                              boxHeight="48px"
                              onChange={(e) => {
                                textData[fieldName] = e.target.value;
                                setTextData({ ...textData });
                              }}
                            />
                          }
                        />
                      );
                    } else if (fieldName.includes("date")) {
                      return (
                        <FormRow
                          name=""
                          leftSideWidth={"0"}
                          margin={"0 0 22px"}
                          rightElement={
                            <DatePicker
                              getSelectedDates={getSelectedDates}
                              name={fieldName}
                              singleDateSelection={true}
                            >
                              <DashboardTextBox
                                type="text"
                                value={getDateText(
                                  selectedDateFields[fieldName]
                                )}
                                placeholder={` - - Select ${fieldName
                                  .split("_")
                                  .join(" ")} - -  `}
                                rightIcon={<CalendarTodayIcon />}
                                boxWidth="424px"
                                boxHeight="48px"
                              />
                            </DatePicker>
                          }
                        />
                      );
                    }
                    else {
                      return (
                        <Fragment>
                          {selectedStatus.name === "wip_with_reasons" ? (
                            <SelectFilter
                              props={{
                                placeholder: "- -  Select Reason - -",
                                onChange: (data) => {
                                  setSelectedReason({
                                    [fieldName]: data
                                  });
                                  if(data!==null){
                                    (data[0].value)==='duplicate_outlet_chain_confirmation_required'?setShowRSID(true):setShowRSID(false)
                                  }else{
                                    setShowRSID(false)
                                  }
                                 
                                  setErrorMessages({});
                                },
                                isMulti: true,
                                options: reasonoptions,
                                // value: selectedReason[fieldName]
                                //   ? reasonoptions.filter(
                                //     (manager) =>
                                //       selectedReason[fieldName] ===
                                //       manager.value
                                //   )
                                //   : "",
                              }}
                              {...selectFieldProps}
                            />) : (
                            <SelectFilter
                              props={{
                                placeholder: "- -  Select Reason - -",
                                onChange: (data) => {

                                  setSelectedReason({
                                    ...selectedReason,
                                    [fieldName]: data.value,
                                  });
                                  setErrorMessages({});
                                },
                                options: reasonoptions,
                                value: selectedReason[fieldName]
                                  ? reasonoptions.filter(
                                    (manager) =>
                                      selectedReason[fieldName] ===
                                      manager.value
                                  )
                                  : "",
                              }}
                              {...selectFieldProps}
                            />
                          )}

                          <FormRow
                            name=""
                            leftSideWidth={"0"}
                            margin={"0 0 22px"}
                            rightElement={
                              <DashboardTextBox
                                type={"textarea"

                                }
                                value={textData['related_workflow_reasons_description']}
                                placeholder={` - - Enter Reason Description - - `}
                                boxWidth="424px"
                                boxHeight="100px"
                                onChange={(e) => {
                                  textData['related_workflow_reasons_description'] = e.target.value;
                                  setTextData({ ...textData });
                                }}
                              />
                            }
                          />
                        </Fragment>

                      );
                    }
                  })
                  : null}
                {rejected.status && (
                  <DashboardTextBox
                    type="text"
                    boxWidth="424px"
                    boxHeight="100px"
                    label="Reject Reason"
                    placeholder="Enter Reject Reason"
                    fieldName={"related_workflow_reasons_description"}
                    value={textData['related_workflow_reasons_description']}
                    onChange={(e) => {
                      // setRejected({
                      //   ...rejected,
                      //   reason: e.target.value
                      // })
                      textData['related_workflow_reasons_description'] = e.target.value;
                      setTextData({ ...textData });
                    }}
                  />
                )}
                {isError ? (
                  <ErrorTextWrapper>
                    {Object.keys(errorMessages).map((errorKey) =>
                      errorKey !== "status" && errorMessages[errorKey] ? (
                        <p>
                          {Array.isArray(errorMessages[errorKey])
                            ? `${errorKey} - ${errorMessages[errorKey][0]}`
                            : errorKey === "extra_data"
                              ? Object.keys(errorMessages[errorKey]).map(
                                (key) =>
                                  `${key}:${errorMessages[errorKey][key]}`
                              )
                              : `${errorKey} - ${errorMessages[errorKey]}`}
                        </p>
                      ) : null
                    )}
                  </ErrorTextWrapper>
                ) : null}
                 {showRSID &&rsidList.map((x, i) =>
                <CheckBoxSingle key={i}  value={x.rsid} label={x.rsid} name="RSID"
                    onChange={handleChange}/>
                )}
                <Button
                  active={true}
                  onClick={() =>
                    isError && !isExtraDataAvailable
                      ? modalAction(selectedAction)
                      : onSaveAction()
                  }
                  size="medium"
                  bgColor={green300}
                  noBorder
                  margin={isError ? "20px 0 0" : "40px 0 0"}
                // isDisabled={
                //   isExtraDataAvailable
                //     ? !(
                //         Object.values(getExtraData()).filter((data) =>
                //           Boolean(data)
                //         ).length === Object.keys(selectedStatus.value || {}).length
                //       )
                //     : false
                // }
                >
                  {isExtraDataAvailable ? "Save" : "Ok"}
                </Button>
              </Fragment>
            ) : null}

            {isLoading && processCompleted === null ? (
              <SuccessWrapper>
                <ProgressLoader />
                <b>Processing...</b>
                <p>Updating Outlet Details.</p>
              </SuccessWrapper>
            ) : null}
            {processCompleted ? (
              <SuccessWrapper>
                <CheckCircleOutlineIcon
                  style={{ width: "60px", height: "60px", color: green300 }}
                />
                <b>Done</b>
                <Button
                  active={true}
                  onClick={() => {
                    const { location } = history;
                    getOutletDetails();
                    modalAction();
                  }}
                  size="medium"
                  bgColor={green300}
                  noBorder
                  margin="40px 0 0"
                >
                  Okay
                </Button>
              </SuccessWrapper>
            ) : processCompleted !== null ? (
              <ErrorWrapper>
                <CancelOutlinedIcon
                  style={{ width: "60px", height: "60px", color: cinnabar300 }}
                />
                <b>Error</b>
                <ErrorTextWrapper>Something went wrong.</ErrorTextWrapper>
                <Button
                  active={true}
                  onClick={() => {
                    setLoading(null);
                    setProcessCompleted(null);
                  }}
                  size="large"
                  bgColor={cinnabar300}
                  color={white}
                  noBorder
                  margin="40px 0 0"
                >
                  Try Again
                </Button>
              </ErrorWrapper>
            ) : null}
          </Fragment>
        )}
      </ContentStyle>
    </Modal>
  );
};
