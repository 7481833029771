import React, { Component } from 'react';
import styled from 'styled-components';
import { black500, black400, black700, white } from '../../theme/colors';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { miniCaption, regularHeading, font } from '../../theme/typography';
import { semiBold } from '../../theme/typeface';
import CircleLoader from '../CircleLoader';

export const Root = styled.div`
    margin: 16px;
    border: 1px dashed ${black500};
    padding: 40px 0px;
    text-align: center;
    position: relative;
`;

const LightText = styled.p`
    ${miniCaption.semiBold};
    color: ${black400};
    margin: 0px;
`;

const UploadText = styled.span`
    ${regularHeading.small};
    color: ${black700};
    vertical-align: super;
    margin-left: 16px;
`;

const RootSection = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

const UploadButton = styled.div`
    width: 144px;
    height: 47px;
    background: ${black700};
    border-radius: 52px;
    color: ${white};
    position: relative;
    overflow: hidden;
    cursor: pointer;

    input[type=file] {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }

    button {
        border-color: ${black700};
        color: ${white};
        background-color: ${black700};
        width: 100%;
        height: 100%;
        font-size: ${font.default};
        font-weight: ${semiBold};
        cursor: pointer;
    }
`

const LoaderRoot = styled.div`
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: whitesmoke;
    padding: 6% 0%;
`;

export  class Upload extends Component {
    commonAction = (e) => {
        e && e.preventDefault();
    }
    
    dropAction = (e, action) => {
        e && e.preventDefault();
        this.fileUpload(e.dataTransfer.files)
    }
    
    fileUpload = (files) => {
        let file = files[0];
        if (file) {
            let imageData = new FormData();
            imageData.append("file", file);
            this.props.onGetImageData(imageData);
        }
    }

    render() {
        const { isLoading, fileAllowedText } = this.props;
        return (
            <Root onDragOver={this.commonAction} onDragEnter={this.commonAction} onDrop={this.dropAction}>
                <RootSection>
                    <LightText>Upload your Excel Sheet {fileAllowedText}</LightText>
                    <CloudUploadIcon style={{ fontSize: 30 }} color={'primary'} />
                    <UploadText>{`Drag & Drop File`}</UploadText>
                </RootSection>
                <RootSection style={{margin: '0px 48px'}}>
                    <LightText style={{margin: '0px'}}>-- OR --</LightText>
                </RootSection>
                <RootSection>
                    <UploadButton>
                        <button class="btn">Browse Files</button>
                        <input type="file" 
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls"
                            onChange={(e) => {
                                //  e && e.preventDefault();
                                this.fileUpload(e.target.files);
                            }}
                            onClick={(event)=> { 
                                event.currentTarget.value = null
                           }}
                             />
                    </UploadButton>
                </RootSection>
                {isLoading ? <LoaderRoot>
                    <CircleLoader />
                </LoaderRoot> : null}
            </Root>
        )
    }
}
