import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../components/Loader';

// Game Dashboard Page
export const GameDashboardLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'login-page' */ '../pages/game/dashboard'),
    loading: () => <Loader />
});

// Game Listing Page
export const GameListingLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'dashboard-page' */ '../pages/game/listing'),
    loading: () => <Loader />
});

// Game Listing All Page
export const GameListingAllLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'dashboard-page' */ '../pages/game/listing/listingAll'),
    loading: () => <Loader />
});

// Game Report Page
export const GameReportLoadable = Loadable({
    loader: () => import(/* webpackChunkName: 'dashboard-page' */ '../pages/game/report'),
    loading: () => <Loader />
});