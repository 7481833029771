import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";
import { SwitchToggle } from "../../../../components/SwitchToggle";
import { black400, cinnabar300, blue300 } from "../../../../theme/colors";
import { baseCaption } from "../../../../theme/typography";

const ToggleWrapper = styled.div`
  width: ${(props) => props.width || "540px"};
  display: flex;
  align-items: center;
  max-height: 42px;
  p {
    width: ${(props) => props.lineWidth || "90%"};
    height: 1px;
    margin: 0 8px 0 0;
    display: inline-block;
    position: relative;
    top: 1px;
    background-image: linear-gradient(
      90deg,
      rgba(144, 145, 145, 0.5),
      rgba(144, 145, 145, 0.5) 75%,
      transparent 75%,
      transparent 100%
    );
    background-size: 8px 1px;
    ${(props) =>
      props.labelText &&
      css`
        width: calc(78% - 42px);
      `}
  }
  span {
    color: ${black400};
    margin-right: 12px;
  }
`;
const Wrapper = styled.div`
  width: ${(props) => props.width || "540px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const MessageBelowBoxStyle = styled.p`
  ${baseCaption}
  margin:5px 0 0;
  display: flex;
  justify-content: space-between;
  width: ${(props) => props.width || "540px"};
  & > span:first-child {
    color: ${cinnabar300};
    order: ${(props) =>
      props.errorTextAlign === "right" || props.subTextAlign === "left"
        ? 2
        : 1};
    ${(props) =>
      props.cursorPointer &&
      css`
        cursor: pointer;
      `}
  }
`;
export const SwitchToogleWithLine = ({
  width,
  active,
  onToggle,
  margin,
  labelText,
  name,
  warnMessage = {},
  lineWidth,
  disabled=false
}) => {
  return (
    <Wrapper width={width}>
      <ToggleWrapper width={width} labelText={labelText} lineWidth={lineWidth}>
        <p></p>
        <span>{labelText}</span>
        <SwitchToggle
          active={active}
          onToggle={onToggle}
          margin={margin}
          name={name}
          disabled={disabled}
        />
      </ToggleWrapper>
      {Object.keys(warnMessage).length ? (
        <MessageBelowBoxStyle errorTextAlign={warnMessage.align}>
          <span>{warnMessage.text}</span>
        </MessageBelowBoxStyle>
      ) : null}
    </Wrapper>
  );
};
