import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Status } from '../helpers/httpStatusHelper';

class ErrorPage extends Component {
    render() {
        return (
            <Status code={500}>
                <div style={{ height: 'calc(100vh - 72px)', position: 'relative', top: '80px' }}>
                    <div className='container xs-full-width notfound'>
                        <section className='row'>
                            <div className='col-sm-7 col-sm-push-5 astronaut-icon'>
                                <i />
                            </div>
                            <div className='col-sm-5 col-sm-pull-7'>
                                <p className='color-grey dim'>
                                    Error code {this.props.status ? this.props.status : '4XX'}{' '}
                                </p>
                                <h1 className='vmargin-medium bold'>
                                    Oops,our bad! There was a problem with your request
                                </h1>
                                <p className='fade vmargin-large'>
                                    Thanks for your patience while our techies get a fix to this
                                </p>
                                <a href='/' className='btn btn-primary'>
                                    Go back home
                                </a>
                            </div>
                        </section>
                    </div>
                </div>
            </Status>
        );
    }
}

ErrorPage.propTypes = {
    status: PropTypes.string
}

export default ErrorPage;