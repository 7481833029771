import { GET_OUTLET_GROUP_SAVE } from "../../../../helpers/constants/apiUrl";
import { triggerSimpleAjax } from "../../../../helpers/httpHelper";
import _cloneDeep from "lodash/cloneDeep";

export const outletQuestionGroupSaveAction = ({
  outletGroupDetails = [],
  setRequestInProcess = () => { },
  setSaveClickedData = () => { },
  activeGroupIndex,
  saveClickedData,
  question_groups,
  setOutletGroupDetails = () => { },
  setOutletGroupDetailsCopy = () => { },
  setActiveGroupIndex = () => { },
  setErrorDetails = () => { },
  sku_request_body = false,
  isBackgroundRequest = false,
}) => {
  const data = outletGroupDetails.map((question) => {
    return {
      [question["related_answers"][0]["id"]]: {
        ...question["related_answers"][0],
      },
    };
  });
  let has_edited = false;
  let reqData = {};
  if (sku_request_body) {
    reqData = sku_request_body
    const update_data = Object.keys(sku_request_body.data_to_update).reduce((answerObject, val) => {
      if(sku_request_body.data_to_update[val]?.is_edited){
        answerObject[val] = sku_request_body.data_to_update[val]
        has_edited = true
      }
      return answerObject
    }, {})
    sku_request_body.data_to_update = update_data
  } else {
    reqData = {
      object_type: "answers",
      data_to_create: null,
      data_to_update: outletGroupDetails.reduce((answerObject, question) => {
        if (!question.type.includes('image') && question["related_answers"][0]?.is_edited ) {
          answerObject[question["related_answers"][0]["id"]] = {
            ...question["related_answers"][0],
          };
          has_edited = true
        }

        return answerObject;
      }, {}),
    }
  }

  console.log("reqData",reqData)
  setRequestInProcess(true);
  if (has_edited) {
    triggerSimpleAjax(
      `${GET_OUTLET_GROUP_SAVE}`,
      "POST",
      reqData,
      (response) => {
        response.data_to_update.forEach((answer = {}) => {
          outletGroupDetails.forEach((outlet, index) => {
            if (outlet["id"] === answer["question"]) {
              const updatedAnswersArray = (outletGroupDetails[index]["related_answers"] || []).filter(outletData => outletData.id !== answer.id) || []
              // outletGroupDetails[index]["related_answers"] = [...updatedAnswersArray, answer];
              if(answer.answer_type!=="multiple_choice"){
                outletGroupDetails[index]["related_answers"] = [...updatedAnswersArray, answer];
              }
            
            
            }
          });
        });

        // setOutletGroupDetails(outletGroupDetails);
        // setOutletGroupDetailsCopy(_cloneDeep(outletGroupDetails));
        setRequestInProcess(false);
        if (!isBackgroundRequest) {

          setSaveClickedData({
            ...saveClickedData,
            [activeGroupIndex]: true,
          });

          if (activeGroupIndex < question_groups.length - 1) {
            setActiveGroupIndex(activeGroupIndex + 1);
          }
        }
      },
      (err) => {
        setRequestInProcess(false);
        setErrorDetails(err.data_to_update);
      }
    );
  } else {
    setRequestInProcess(false);
    setSaveClickedData({
      ...saveClickedData,
      [activeGroupIndex]: true,
    });
    if (activeGroupIndex < question_groups.length - 1) {
      setActiveGroupIndex(activeGroupIndex + 1);
    }
  }

};
